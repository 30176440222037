export enum Source {
  all = 'all',
  sdk = 'sdk',
  server = 'server',
}

export const SourceMappings: { [key: string]: string } = {
  all: 'ALL',
  sdk: 'SDK',
  server: 'Server',
}

export enum Platform {
  android = 'android',
  ios = 'ios',
  webgl = 'webgl',
  windows = 'windows',
}

export const PlatformMappings: { [key: string]: string } = {
  android: 'Android',
  ios: 'iOS',
  webgl: 'WebGL',
  windows: 'Windows',
}

export enum AccessLevel {
  read_write = 'read_write',
  read_only = 'read_only',
  write_only = 'write_only',
}

export const AccessLevelMappings: { [key: string]: string } = {
  read_write: 'Read / Write',
  read_only: 'Read Only',
  write_only: 'Write Only',
}

export enum Environment {
  prod = 'prod',
  staging = 'staging',
}

export const EnvironmentMappings: { [key: string]: string } = {
  prod: 'Prod',
  staging: 'Dev',
}

export interface ApiKey {
  organization_id: number
  key: string
  name: string
  id: number
  max_requests_per_second: number
  is_active: boolean
  access_level: string
  source: string
  env: string | null
  platform: string
  created_at: string,
  secured: boolean,
  secret_key: string | null
}
