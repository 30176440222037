import { Pagination, Stack } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { Button, FilterButton, SortButton } from 'helika-ui-sdk'
import { ReactComponent as InboxEmpty } from 'src/assets/inbox_empty.svg'
import { StyledSvg } from "src/components/agGrid/SupportDataGridHeaderComponent";
import { lightPalette, newColor } from "src/consts/colors";
import React from "react";
import { MOTDCampaignMessageHeaders } from "src/utils/agGrid";
import {
    DataGridPro,
} from '@mui/x-data-grid-pro'
import {
    styled as muiStyled,
} from '@mui/material'
import HelikaSearchBar from "src/components/HelikaSearchBar";
import LoadingComponent from "src/components/LoadingComponent";
import { color_base, colors_semantic } from "helika-ui-sdk/dist/src/values/Colors";
import ReusableSnackbar from "../RedemptionCode/components/ReusableSnackbar";
import CreateMOTDCampaignModal from "src/components/popups/CreateMOTDCampaignModal";
import EditMOTDCampaignModal from "src/components/popups/EditMOTDCampaignModal";
import { useLocation } from "react-router-dom";
import { spacing } from "helika-ui-sdk/dist/src/values/Spacing";
import Breadcrumb from "./Breadcrumb";
import { getMOTDCampaign } from "src/utils/api/queries";
import { Auth0Context } from "src/contexts/Auth0Context";
import { toast } from "react-toastify";

export default function CampaignPage() {

    const theme = useTheme()
    const location = useLocation()
    const { getWithAccessToken } = useContext(Auth0Context)
    const path = location.pathname?.split('/')
    const campaign_id = path[4]
    const [campaign, setCampaign] = useState<any>(undefined)
    const [messages, setMessages] = useState<any[]>([])
    const [filteredCampains, setFilteredCampaigns] = useState<any[]>([])
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [openCreateCampaignModal, setOpenCreateCampaignModal] = useState<boolean>(false)
    const [openEditCampaignModal, setOpenEditCampaignModal] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [messageName, setMessageName] = useState<string>('')
    const [snackbarMessage, setSnackbarMessage] = useState<string>('')
    const [activeFilter, setActiveFilter] = useState<string>('')
    const [sortField, setSortField] = useState<string>('created_at')
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc')
    const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>(
        'success',
    )
    // const [sort, setSort] = useState<string>('Created At Desc')
    // pagination
    const [pageSize, setPageSize] = useState<string>('10')
    const [pageNumber, setPageNumber] = useState<number>(1)
    const handlePaginationChange = (
        event: React.ChangeEvent<unknown>,
        page: number,
    ) => {
        setPageNumber(page)
    }

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMessageName(event.target.value)
    }

    const handlePageSizeChange = (
        event: React.ChangeEvent<HTMLSelectElement>,
    ) => {
        setPageSize(event.target.value)
    }

    const [selectedMessage, setSelectedMessage] = useState<any>({})

    const [columnDefs, setColumnDefs] = useState<any>(
        MOTDCampaignMessageHeaders(
            messages,
            setCampaign,
            campaign,
            theme,
            setOpenEditCampaignModal
        ),
    )

    // show select column
    useEffect(() => {
        setColumnDefs(
            MOTDCampaignMessageHeaders(
                messages,
                setCampaign,
                campaign,
                theme,
                setOpenEditCampaignModal,
            ),
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messages, campaign, setCampaign, theme])

    function CustomPagination(hasPageSizeSelector: boolean = false) {

        const SelectCustom = muiStyled('select')(({ theme }) => ({
            width: '60px',
            borderRadius: '3px',
            backgroundColor: theme.palette.mode === 'dark' ? colors_semantic.background_primary : 'white',
            border: '1px solid ',
            borderColor: colors_semantic.border_primary,
            color: colors_semantic.text_primary,
            padding: '0 5px',
            '&:focus, &:hover': {
                outline: newColor.indigo,
                borderWidth: '1px',
                BorderStyle: 'solid',
                borderColor: newColor.gunmetalGray
            },
        }))

        return (
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    margin: '15px 25px 0 0',
                    gap: '8px',
                    fontFamily: 'Open Sans, sans-serif'
                }}
            >
                <Pagination
                    count={
                        isNaN(messages?.length / Number(pageSize))
                            ? 1
                            : Math.ceil(messages?.length / Number(pageSize))
                    }
                    page={pageNumber}
                    onChange={handlePaginationChange}
                    shape="rounded"
                    sx={{
                        ul: {
                            '& .MuiPaginationItem-root': {
                                backgroundColor:
                                    theme.palette.mode === 'dark' ? newColor.tertiary : 'white',
                                fontFamily: 'Open Sans, sans-serif'
                            },
                            '& .MuiPaginationItem-root.Mui-selected': {
                                background: theme.palette.mode === 'dark' ? newColor.gunmetalGray : color_base.grey200,
                            },
                            '& .MuiPaginationItem-root.MuiPaginationItem-ellipsis': {
                                height: '32px!important',
                                display: 'flex',
                                justifyContent: 'center',
                                alignContent: 'end',
                                alignItems: 'center',
                                borderRadius: '5px!important',
                            },
                        },
                    }}
                />
                {
                    hasPageSizeSelector &&
                    <SelectCustom value={pageSize} onChange={handlePageSizeChange}>
                        <option defaultChecked value={5}>
                            5
                        </option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                    </SelectCustom>
                }
            </Box>
        );
    }

    //get campaign
    useEffect(() => {
        async function getCampaignMessages() {
            setLoading(true)

            const data = await getMOTDCampaign(getWithAccessToken, campaign_id).catch((e: any) => {
                toast.error(e)
                setLoading(false)
            })
            if (!data?.results?.id) {
                setSnackbarMessage('Could not find campaign')
                setSnackbarVariant('error')
                setSnackbarOpen(true)
                return
            }
            let campaign_data = data.results
            setCampaign(campaign_data)

            //todo: add back
            // const data = await getCampaignMOTDMessages(getWithAccessToken, campaign_data.id).catch((e: any) => {
            //     toast.error(e)
            //     setLoading(false)
            // })
            // if (data?.results?.messages) {
            //     setMessages(data?.results?.messages)
            // }

            //todo: remove
            setMessages([
                {
                    id: 1,
                    title: 'Message1'
                },
                {
                    id: 2,
                    title: 'Message2'
                }
            ])
            setLoading(false)
        }
        if (!campaign_id) return
        getCampaignMessages()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setLoading(true)
        let updatedList = messages
        if (!_.isEmpty(searchTerm?.trim())) {
            updatedList = messages?.filter(message => message?.title?.toLocaleLowerCase()?.trim()?.includes(searchTerm?.toLocaleLowerCase()?.trim()))
        }
        // if (!_.isEmpty(updatedList)) {
        //     switch (sort) {
        //         case 'Created At Asc':
        //             updatedList = [...updatedList]?.sort((a: any, b: any) =>
        //                 a.created_at > b.created_at ? 1 : -1,
        //             )
        //             break;
        //         case 'Created At Desc':
        //         default:
        //             updatedList = [...updatedList]?.sort((a: any, b: any) =>
        //                 a.created_at < b.created_at ? 1 : -1,
        //             )

        //     }
        // }

        //sort by updated_ay date
        let newUpdatedList = [...updatedList]
        if (!_.isEmpty(updatedList)) {
            newUpdatedList = [...updatedList]?.sort((a: any, b: any) =>
                a?.updated_at?.toString() < b?.updated_at?.toString() ? 1 : -1,
            )
        }

        setFilteredCampaigns(newUpdatedList)
        setLoading(false)
    }, [messages, pageNumber, pageSize, searchTerm])

    async function onCreateMessage(newMessage: any) {
        setMessages((prevState: any[]) => {
            if (_.isEmpty(prevState)) return [newMessage]
            return prevState.concat(newMessage)
        })
        setSnackbarMessage('Message created')
        setSnackbarVariant('success')
        setSnackbarOpen(true)
    }


    async function onEditCampaign(editedCampaign: any) {
        setCampaign(editedCampaign)
        setSnackbarMessage('Campaign edited')
        setSnackbarVariant('success')
        setSnackbarOpen(true)
    }

    async function onEditMessage(editedMessage: any) {
        setMessages((prevState: any[]) => {
            if (_.isEmpty(prevState)) return [editedMessage]
            return prevState.map(message => {
                if (message.id === editedMessage.id) return editedMessage
                return message
            })
        })
        setSnackbarMessage('Message edited')
        setSnackbarVariant('success')
        setSnackbarOpen(true)
    }

    const NoMessagesCTA =
        <Box
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'center'
            }}
        >
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '10em auto auto auto',
                    textAlign: 'center',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <StyledSvg
                    sx={{
                        '& svg, & path': {
                            fill: (theme.palette.mode === 'dark' ? newColor.white : newColor.black),
                        },
                        width: 'fit-content',
                        cursor: 'pointer',
                        margin: 'auto'
                    }}
                >
                    <InboxEmpty
                        style={{
                            cursor: 'pointer',
                        }}
                    />
                </StyledSvg>
                <Box
                    sx={{
                        fontFamily: "Open Sans",
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: '150%', /* 24px */
                    }}
                >
                    Create and manage messages of the day
                </Box>
                <div
                    style={{
                        height: '8px'
                    }}
                />
                <Box
                    sx={{
                        fontFamily: "Open Sans",
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '140%', /* 16.8px */
                    }}
                >
                    Let's start creating a message of the day!
                </Box>
                <div
                    style={{
                        height: '16px'
                    }}
                />
                <Button
                    type="Primary"
                    handleClick={() => setOpenCreateCampaignModal(true)}
                    icon="Plus"
                    iconPosition="Left"
                >
                    Create New Message
                </Button>
            </Box>
        </Box >

    // if (!campaign?.id && !loading) return null

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: 'fit-content',
                height: 'fit-content',
                maxHeight: 'calc(100vh - 11em)',
                padding: '2em',
                gap: '1em',
                background: theme.palette.mode === 'dark' ? colors_semantic.background_primary : lightPalette.bgPage,
            }}
            className="open-sans-fontFam400"
        >
            <Stack
                direction={'column'}
                sx={{
                    flex: 1,
                    gap: spacing.Spacing_lg
                }}
            >
                <Breadcrumb
                    items={[
                        { string: 'Message of the Day', isLink: true, link: `${process.env.REACT_APP_AUTH0_BASE_URL}/game_management/motd/campaigns` },
                        { string: 'Campaigns', isLink: true, link: `${process.env.REACT_APP_AUTH0_BASE_URL}/game_management/motd/campaigns` },
                        { string: campaign?.title }
                    ]}
                />
                <Box
                    id='OfferWallManagementHeader'
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        borderBottom: 'solid',
                        borderWidth: '1px',
                        borderColor: colors_semantic.border_primary,
                        paddingBottom: spacing.Spacing_sm
                    }}
                >
                    <ReusableSnackbar
                        open={snackbarOpen}
                        message={snackbarMessage}
                        variant={snackbarVariant}
                        handleClose={() => setSnackbarOpen(false)}
                    />
                    <CreateMOTDCampaignModal
                        open={openCreateCampaignModal}
                        setOpen={setOpenCreateCampaignModal}
                        onCreateCampaign={onCreateMessage}
                    />
                    <EditMOTDCampaignModal
                        open={openEditCampaignModal}
                        setOpen={setOpenEditCampaignModal}
                        onEditCampaign={onEditCampaign}
                        selectedCampaign={campaign}
                    />
                    <Box
                        sx={{
                            fontSize: '24px !important',
                            lineHeight: '130%',
                            fontWeight: 600,
                            margin: 'auto 0 auto 0'
                        }}
                        className="open-sans-fontFam400"
                    >
                        <Box>{campaign?.title}</Box>
                    </Box>
                    <Button
                        type={'Secondary'}
                        handleClick={() => setOpenEditCampaignModal(true)}
                    >
                        Edit Campaign
                    </Button>
                </Box>
                <Box
                    id='OfferWallManagementHeader'
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box
                        sx={{
                            fontSize: '24px !important',
                            lineHeight: '130%',
                            fontWeight: 600,
                            margin: 'auto 0 auto 0'
                        }}
                        className="open-sans-fontFam400"
                    >
                        Messages
                    </Box>
                    {(loading) && (
                        <Stack
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                zIndex: 10000,
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <LoadingComponent />
                        </Stack>
                    )}
                    <Button
                        type="Primary"
                        handleClick={() => setOpenCreateCampaignModal(true)}
                        icon="Plus"
                        iconPosition="Left"
                    >
                        Create New
                    </Button>
                </Box>
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                    }}
                >
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%'
                        }}
                    >

                        {
                            //search and filters
                        }
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <Box
                                sx={{
                                    width: '20em',
                                    maxWidth: '40%',
                                    margin: 'auto 0 auto 0'
                                }}
                            >
                                <HelikaSearchBar
                                    value={searchTerm}
                                    setValue={setSearchTerm}
                                    placeholder={'Search'}
                                />
                            </Box>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                sx={{ mt: '24px', mb: '24px' }}
                            >
                                <Stack direction="row" gap={2} alignItems="center">
                                    <FilterButton
                                        darkMode={theme.palette.mode === 'dark'}
                                        filterFields={[
                                            { name: 'Category', options: ['', 'Status'] },
                                            { name: 'Is', options: ['Is'] },
                                            { name: 'FilterValue', options: ['', 'Active', 'Not Active'] }
                                        ]}
                                        onFilterChange={(updatedFilters: any) => {
                                            switch (updatedFilters?.Category) {
                                                case 'Status': {
                                                    setActiveFilter(updatedFilters?.FilterValue)
                                                    break;
                                                }
                                                default:
                                                    setActiveFilter('')
                                                    break;
                                            }
                                        }}
                                        onClearAll={() => {
                                            setActiveFilter('')
                                        }}
                                    />
                                    <SortButton
                                        sortFields={[
                                            { value: 'created_at', label: 'Created At' },
                                            { value: 'updated_at', label: 'Updated At' },
                                        ]}
                                        sortField={sortField}
                                        sortOrder={sortOrder}
                                        onSortChange={(field, order) => {
                                            setSortField(field);
                                            setSortOrder(order);
                                        }}
                                        darkMode={theme.palette.mode === 'dark'}
                                    />
                                </Stack>
                            </Stack>
                        </Box>
                        {
                            //messages list
                            loading
                                ? <Box sx={{ height: 'fit-content', width: 'fit-content', margin: 'auto' }}><LoadingComponent /></Box>
                                : _.isEmpty(filteredCampains)
                                    ? NoMessagesCTA
                                    :
                                    <Box
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            paddingTop: '2em',
                                            height: '100%',
                                            maxHeight: '100%'
                                        }}
                                    >
                                        <DataGridPro
                                            autoHeight={false}
                                            rows={filteredCampains}
                                            columns={columnDefs}
                                            getRowId={(row: any) => {
                                                return row.id
                                            }}
                                            onCellClick={(item) => { setSelectedMessage(item.row) }}
                                            sx={{
                                                border: 'none',
                                                "& .MuiDataGrid-columnHeaders": {
                                                    minHeight: 'fit-content !important',
                                                },
                                                // Neutralize the hover colour (causing a flash)
                                                "& .MuiDataGrid-row.Mui-hovered": {
                                                    backgroundColor: "transparent",
                                                },
                                                // Take out the hover colour
                                                "& .MuiDataGrid-row:hover": {
                                                    backgroundColor: "transparent",
                                                },
                                                "& .MuiDataGrid-cell": {
                                                    borderTop: 1,
                                                    borderColor: colors_semantic.border_tertiary,
                                                    borderBottom: 'none',
                                                    paddingLeft: '12px !important',
                                                    paddingRight: '12px !important',
                                                    display: 'flex !important',
                                                    flexDirection: 'row !important',
                                                    justifyContent: 'center !important',
                                                },
                                                "& .MuiDataGrid-cellContent": {
                                                    width: '100% !important',
                                                    textAlign: 'left'
                                                },
                                                "& .MuiDataGrid-virtualScrollerContent": {
                                                    border: 'none'
                                                },
                                                "& .MuiDataGrid-virtualScroller": {
                                                    border: 'none'
                                                },
                                                "& .MuiDataGrid-main": {
                                                    border: 'none'
                                                },
                                                "& .MuiDataGrid-footerContainer": {
                                                    borderColor: colors_semantic.border_primary,
                                                },
                                            }}
                                            pageSizeOptions={[Number(pageSize)]}
                                            paginationModel={{
                                                pageSize: 10,
                                                page: pageNumber - 1
                                            }}
                                            paginationMode='client'
                                            pagination
                                            slots={{
                                                pagination: () => CustomPagination()
                                            }}
                                            disableRowSelectionOnClick
                                        />
                                    </Box>
                        }
                    </Box>

                </Box>
            </Stack>
        </Box >
    )
}