import React, { useContext, useEffect, useState } from 'react'

import {
  Box,
  useTheme,
} from '@mui/material'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import {
  Dialog,
} from '@mui/material'
import { Button, Colors, Textfield, ErrorBanner, Spacing } from 'helika-ui-sdk'
import { editMOTDCampaign } from 'src/utils/api/queries'
import { Auth0Context } from 'src/contexts/Auth0Context'
import _, { debounce } from 'lodash'
import { isStringEmpty } from 'src/utils/string'
import CloseIcon from '@mui/icons-material/Close';
import { spacing } from 'helika-ui-sdk/dist/src/values/Spacing'
import ReusableSnackbar from 'src/pages/Modules/GameManagement/RedemptionCode/components/ReusableSnackbar'
import { lightPalette } from 'src/consts/colors'

export interface SimpleDialogProps {
  open: boolean
  setOpen: any
  onEditCampaign: any
  selectedCampaign: any
}

export interface Dashboard_mapping {
  name: string
  iframe_link: string
}

const EditMOTDCampaignModal = (props: SimpleDialogProps) => {
  const { open, setOpen, onEditCampaign, selectedCampaign } = props
  const theme = useTheme()
  const { patchWithAccessToken } = useContext(Auth0Context);

  const [name, setName] = useState<string>('');
  const [missingDetails, setMissingDetails] = useState<boolean>(false)

  const [loading, setLoading] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState<string>('')
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>(
    'success',
  )

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }
  const handleClose = () => {
    setOpen(false)
  }

  function resetValues(selectedCampaign: any) {
    if (_.isEmpty(selectedCampaign)) return
    setName(selectedCampaign.title)
  }

  useEffect(() => {
    resetValues(selectedCampaign)
  }, [selectedCampaign])

  const debouncedClickHandler = debounce(editCampaignApi, 200, { maxWait: 500 })

  async function editCampaignApi() {

    try {
      if (loading) return
      setLoading(true)

      try {
        isStringEmpty(name, 'Name required')
      } catch (e: any) {
        setMissingDetails(true)
        throw new Error(e)
      }

      let campaign: any = {
        ...selectedCampaign,
        title: name,
      }

      await editMOTDCampaign(patchWithAccessToken, campaign).then((resp: any) => {
        if (!_.isEmpty(resp?.results)) {
          onEditCampaign(resp?.results)
          setOpen(false)
          setLoading(false)
          setMissingDetails(false)
        } else {
          throw new Error('Could not edit campaign')
        }
      })
    } catch (e: any) {
      setSnackbarMessage(e?.message)
      setSnackbarVariant('error')
      setSnackbarOpen(true)
      setLoading(false)
    }

  }

  return (
    <Dialog
      disableRestoreFocus
      fullScreen={true}
      className="defaultModalStyle open-sans-fontFam400"
      sx={{
        margin: 'auto',
        borderRadius: '1px !important',
        border: 'none',
        background: '#1A000000',
        overflow: 'auto',
      }}
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          background: theme.palette.mode === 'dark' ? Colors.background.primary : lightPalette.bgPage,
          borderStyle: 'none',
          borderColor: '#404040',
          borderWidth: '1px',
          display: 'flex',
          borderRadius: '1px !important',
          width: 'fit-content',
          boxShadow: '0px 20px 20px 0px #4D000000 !important',
          overflow: 'auto',
          maxWidth: 'min(400px, 90vw)'
        },
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: `${Spacing.Spacing_sm} ${Spacing.Spacing_sm} ${Spacing.Spacing_sm} ${Spacing.Spacing_lg}`,
          minWidth: '350px'
        }}
      >
        <Box
          sx={{
            fontSize: '20px',
            lineHeight: '140%',
            fontWeight: 600,
            flex: 1,
            whiteSpace: 'nowrap',
          }}
        >
          Edit Campaign
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: 'auto 0 auto 0',
            cursor: 'pointer',
            height: '32px',
            width: '32px'

          }}
          onClick={handleClose}
        >
          <CloseIcon
            sx={{
              fontSize: '24px',
              height: '24px',
              width: '24px',
              margin: 'auto'
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: spacing.Spacing_md,
          padding: `${Spacing.Spacing_lg}`
        }}
      >
        <ErrorBanner
          show={missingDetails && (_.isEmpty(name))}
          errorMessage='Fill out missing campaign details.'
        />
        <Textfield
          darkMode={theme.palette.mode === 'dark'}
          label={'Name'}
          value={name}
          onChange={handleNameChange}
          hasError={(missingDetails && _.isEmpty(name?.trim()))}
          placeHolder='Campaign name'
        />
        {/* Status dropdown */}
        {/* <Grid item xs={12} sx={{ paddingTop: '0px !important' }}>
          <Typography
            sx={{
              color: theme.palette.mode === 'dark' ? newColor.white : newColor.black,
              fontFamily: 'Open Sans, sans-serif',
            }}
          >
            Status
          </Typography>
          <Select
            value={status}
            onChange={(e) => setStatus(e.target.value as string)}
            fullWidth
            sx={{
              padding: '0px',
              height: '36px',
              backgroundColor:
                theme.palette.mode === 'dark'
                  ? newColor.gunmetalGray
                  : newColor.lightGray,
              border: `1px solid ${theme.palette.mode === 'dark'
                ? color_base.grey600
                : color_base.grey300
                }`,
              borderRadius: '4px',
              color: theme.palette.mode === 'dark' ? newColor.white : newColor.black,
            }}
          // disabled={isSaving}
          >
            {Object.keys(statusColors).map((statusKey) => (
              <MenuItem key={statusKey} value={statusKey}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <StatusDot color={statusColors[statusKey]} />
                  <Typography sx={{ fontFamily: 'Open Sans, sans-serif' }}>
                    {statusLabels[statusKey]}
                  </Typography>
                </Stack>
              </MenuItem>
            ))}
          </Select>
        </Grid> */}
      </Box >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          gap: Spacing.Spacing_xxs,
          padding: `${Spacing.Spacing_sm} ${spacing.Spacing_lg}`,
          width: '100%',
          minWidth: '350px'
        }}
      >
        <Button
          type={theme.palette.mode === 'dark' ? 'Tertiary' : 'Primary'}
          handleClick={() => {
            resetValues(selectedCampaign)
            setOpen(false)
          }}
        >
          Cancel
        </Button>
        <Button
          type='Primary'
          disabled={loading}
          handleClick={debouncedClickHandler}
        >
          Save
        </Button>
      </Box>
      <ReusableSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        variant={snackbarVariant}
        handleClose={() => setSnackbarOpen(false)}
      />
    </Dialog>
  )
}

export default EditMOTDCampaignModal
