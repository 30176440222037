import React, { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, MenuItem, Menu, Stack, TableCell } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Edit as EditIcon, Visibility as ViewIcon } from '@mui/icons-material'
import { debounce } from 'lodash'
// components
import {
  NumericInput,
  Chips,
  ReusableSnackbar,
  ToggleSwitch,
} from '../components'
import { useRequests } from 'src/utils/lib'
// types
import { KeyedObject } from 'src/utils/lib'
import { IEngageCampaign } from 'src/types/engage'
// assets
import { newColor } from 'src/consts/colors'
import { ReactComponent as ActionSvg } from 'src/assets/action_icon.svg'

const SaveButton = styled(Button)(({ theme }) => ({
  backgroundColor: newColor.jazzberryJam,
  color: 'white',
  fontSize: '12px',
  fontWeight: '400',
  borderRadius: '5px',
  padding: '4px 8px',
  textTransform: 'capitalize',
  '&:hover': {
    backgroundColor: '#E91E63',
  },
}))

export type EngageCampaignTableRowProps = {
  engageCampaign: IEngageCampaign
  onClickEditCampaign: (campaign: IEngageCampaign) => void
  refetch: () => Promise<void>
}

const EngageCampaignTableRow: FC<EngageCampaignTableRowProps> = ({
  engageCampaign,
  onClickEditCampaign,
  refetch,
}) => {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [priority, setPriority] = useState<number>(0)
  const [isShowSaveBtn, setShowSaveBtn] = useState<boolean>(false)
  const [snackBar, setSnackBar] = useState<{
    open: boolean
    message?: string
    variant?: 'success' | 'error'
  }>({ open: false, message: '', variant: 'success' })
  const { update } = useRequests()

  useEffect(() => {
    setPriority(engageCampaign.priority)
  }, [JSON.stringify(engageCampaign)])

  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClosePopover = () => {
    setAnchorEl(null)
  }

  const handleEditCampaign = () => {
    onClickEditCampaign(engageCampaign)
    handleClosePopover()
  }

  const handleViewCampaign = () => {
    navigate(`/game_management/engage/campaigns/${engageCampaign.id}`)
    handleClosePopover()
  }

  const handleChangePriority = (_priority: number) => {
    setPriority(_priority)
    debounce(() => {
      if (_priority != engageCampaign.priority) {
        setShowSaveBtn(true)
      } else {
        setShowSaveBtn(false)
      }
    }, 500)()
  }

  const handleSavePriority = async () => {
    const resource = `engage/internal/projects/${engageCampaign.id}`
    const payload: KeyedObject = {
      priority: priority,
    }

    try {
      await update({ resource, payload })
      refetch()
      setSnackBar({
        open: true,
        message: 'Successfully updated priority of engage campaign.',
        variant: 'success',
      })
      setShowSaveBtn(false)
    } catch (err) {
      let errMsg = ''
      if (err instanceof Error) {
        errMsg = `${err.message}`
      } else {
        errMsg = `${err}`
      }
      setSnackBar({
        open: true,
        message: errMsg,
        variant: 'error',
      })
    }
  }

  const handleChangeStatus = async () => {
    const resource = `engage/internal/projects/${engageCampaign.id}/status`
    const payload: KeyedObject = {
      active: !engageCampaign.active,
    }

    try {
      await update({ resource, payload })
      refetch()
      setSnackBar({
        open: true,
        message: 'Successfully updated status of engage campaign.',
        variant: 'success',
      })
    } catch (err) {
      let errMsg = ''
      if (err instanceof Error) {
        errMsg = `${err.message}`
      } else {
        errMsg = `${err}`
      }
      setSnackBar({
        open: true,
        message: errMsg,
        variant: 'error',
      })
    }
  }

  const open = Boolean(anchorEl)
  const id = open ? 'engageCampaign-item-popover' : undefined

  return (
    <>
      <TableCell sx={{ width: '86px' }}>
        <Stack direction="row" alignItems="center" gap={1}>
          <NumericInput value={priority} onChange={handleChangePriority} />
          {isShowSaveBtn && (
            <SaveButton onClick={handleSavePriority}>Save</SaveButton>
          )}
        </Stack>
      </TableCell>
      <TableCell>{engageCampaign.name}</TableCell>
      <TableCell align="right">
        {engageCampaign?.labels && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            flexWrap="wrap"
            gap={1}
          >
            {engageCampaign.labels.map((label: string) => (
              <Chips key={label} label={label} />
            ))}
          </Stack>
        )}
      </TableCell>
      <TableCell align="right" sx={{ width: '64px' }}>
        <ToggleSwitch
          checked={engageCampaign.active}
          onChange={handleChangeStatus}
        />
      </TableCell>
      <TableCell sx={{ width: '34px' }}>
        <Button sx={{ p: 0, minWidth: '34px' }} onClick={handleOpenPopover}>
          <ActionSvg />
        </Button>
      </TableCell>
      <Menu
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          elevation: 2,
          sx: {
            minWidth: '120px',
            backgroundColor: newColor.charcoal,
            borderRadius: '8px',
            '& .MuiMenuItem-root': {
              fontSize: '14px',
              fontWeight: 400,
              padding: '4px 16px',
              '&:hover': {
                backgroundColor: newColor.jet,
              },
            },
          },
        }}
      >
        <MenuItem onClick={handleEditCampaign}>
          <EditIcon sx={{ fontSize: 14 }} />
          &nbsp;&nbsp;Edit Campaign
        </MenuItem>
        <MenuItem onClick={handleViewCampaign}>
          <ViewIcon sx={{ fontSize: 14 }} />
          &nbsp;&nbsp;Hooks List
        </MenuItem>
      </Menu>

      {/* ReusableSnackbar for notifications */}
      <ReusableSnackbar
        open={snackBar.open}
        message={snackBar.message as string}
        variant={snackBar.variant as 'success' | 'error'}
        handleClose={() => setSnackBar({ open: false })}
      />
    </>
  )
}

export default EngageCampaignTableRow
