import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from 'src/state';
import moment from 'moment';
import { HELIKA_LOGO_URL } from 'src/utils/string';
import { newColor } from 'src/consts/colors';

export function ConversationList(): React.ReactElement {

  const dispatch = useDispatch()
  const { setLlmState } = bindActionCreators(actionCreators, dispatch)
  const chatController = useSelector((state: any) => state.llmChat.chatController)
  const isLlmExpanded = useSelector((state: any) => state.llmChat.isExpanded)
  const isHistoryExpanded = useSelector((state: any) => state.llmChat.isHistoryExpanded)
  const [messages, setMessages] = useState(chatController?.getMessages());

  //update convo list when necessary
  chatController?.addOnMessagesChanged(() => {
    setMessages([...chatController?.getMessages()])
  });

  async function startNewConversation() {
    setLlmState({
      type: 'SET_HISTORY_MINIMIZED',
    })
    chatController?.clearMessages()
    await chatController?.addMessage({
      type: 'text',
      content: [
        {
          content: `Welcome to Helika AI! Ask me anything!`,
          type: 'text',
        }
      ],
      self: false,
      avatar: HELIKA_LOGO_URL,
    });
  }

  return (
    <div
      style={{
        width: '100%',
        height: isHistoryExpanded ? 'fit-content' : '0em',
        minHeight: isHistoryExpanded ? 'fit-content' : '0em',
        maxHeight: '50%',
        display: 'flex',
        flexDirection: 'column',
        //todo: add back overflow once greater than 3 conversations, turn to auto
        overflow: isHistoryExpanded ? (true ? 'visible' : 'auto') : 'hidden',
        borderBottom: 'solid',
        borderColor: isHistoryExpanded ? newColor.darkGunmetal : newColor.transparent,
        marginBottom: isHistoryExpanded ? '1em' : 0,
        paddingBottom: isHistoryExpanded ? '1em' : 0,
        transition: 'all 0s ease-in-out !important'
        //todo: add slow reveal css
      }}
    >
      <div style={{
        width: '100%',
        textAlign: 'left',
        padding: '0.2em 0em 0.2em 1em',
        marginBottom: '0.2em',
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'row'
      }}>
        <div>Conversations:</div>
        <div
          style={{
            background: 'linear-gradient(90deg, #8BE1C9 17.2%, #9747FF 87.63%)',
            color: 'black',
            border: 'solid',
            borderRadius: '100vmax',
            padding: '0 1em 0 1em',
            cursor: 'pointer'
          }}
          onClick={startNewConversation}
        >
          Start New
        </div>
      </div>
      {
        //todo: add lazy loading
        messages.length > 0 &&
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            padding: '0.2em 1em 0.2em 1em',
            borderRadius: '100vmax',
            border: 'solid',
            borderColor: newColor.darkGunmetal,
            cursor: 'pointer'
          }}
          onDoubleClick={() => {
            //todo: remove this
            setLlmState({
              type: 'SET_HISTORY_MINIMIZED',
            })
          }}
        >
          <div
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {`${messages[messages.length - 1].self ? 'You' : 'AI'}: ${messages[messages.length - 1].content[0].type === 'text' ? messages[messages.length - 1]?.content[0].content : '*Embedded Content*'}`}
          </div>
          <div
            style={{
              color: 'gray',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '100%',
              margin: 'auto 0 auto 1em',
              minWidth: isLlmExpanded ? '6.5em' : '5.5em',
              width: 'fit-content',
              borderLeft: 'solid',
              paddingLeft: '1em',
              borderColor: newColor.darkGunmetal,
              fontSize: '0.8em',
              height: 'fit-content',
            }}>
            {moment(messages[messages.length - 1].createdAt).format(isLlmExpanded ? 'h:mm:ss A YYYY-MM-DD' : 'YY-MM-DD')}
          </div>
        </div>
      }
    </div>
  );
}