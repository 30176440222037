import React, { useContext, useEffect, useState } from 'react';
import { newColor } from '../../consts/colors'
import { Box, useTheme } from '@mui/material';
import CircularPageHeader from '../atoms/v2/circularPageHeader';
import { CodeEditor } from '../codeMirror/CodeEditor';
import { PostgreSQL, sql } from '@codemirror/lang-sql';
import { coolGlow, tomorrow } from 'thememirror';
import { useGetARPU } from 'src/utils/ai/useGetARPU';
import { Auth0Context } from 'src/contexts/Auth0Context';

export function AudienceEmbed(): React.ReactElement {

  const theme: any = useTheme();

  const { getTokenIfNecessary, isAuthenticated, isLoading } = useContext(Auth0Context)
  const [token, setToken] = useState<any>(undefined);
  const { recentArpu, loadingRecentArpu } = useGetARPU(token, process.env.REACT_APP_EVENTS_API_KEY);


  //get token
  useEffect(() => {
    async function getToken() {
      let newToken = await getTokenIfNecessary();
      setToken(newToken)
    }
    if (!isAuthenticated || isLoading) return
    getToken()
  }, [getTokenIfNecessary, isAuthenticated, isLoading])

  const audiences = [
    {
      name: 'Shrimp',
      users: loadingRecentArpu ? '...' : recentArpu?.results?.segments['0']?.total?.total_users,
      text: `SELECT user_id
FROM ltv_table
WHERE ltv_value < 10
      `
    },
    {
      name: 'Dolphin',
      users: loadingRecentArpu ? '...' : recentArpu?.results?.segments['1']?.total?.total_users,
      text: `SELECT user_id
      FROM ltv_table
      WHERE ltv_value < 10
      `
    },
    {
      name: 'Whale',
      users: loadingRecentArpu ? '...' : recentArpu?.results?.segments['2']?.total?.total_users,
      text: `SELECT user_id
      FROM ltv_table
      WHERE ltv_value < 10
      `
    }
  ]

  let extensions: any = [
    sql({
      dialect: PostgreSQL,
    }),
    theme.palette.mode === 'dark' ? coolGlow : tomorrow
  ]

  let { CodeMirrorInstance } = CodeEditor({
    value: audiences[0].text,
    onChange: () => { },
    extensions: extensions
  })

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'left',
        margin: 'auto',
        flex: 1,
        padding: '1em',
        minWidth: '60em',
        gap: '1em'
      }}
    >

      <CircularPageHeader
        text="AUDIENCES"
        sx={{ padding: 0, paddingLeft: '1em' }}
      />
      {
        audiences.map(audience => {
          return (
            <Box
              key={`${audience.name}_mapping`}
              sx={{
                borderRadius: '0.5em',
                display: 'flex',
                flexDirection: 'column',
                background: theme.palette.mode === 'dark' ? newColor.midnight : 'white',
                borderStyle: 'solid',
                borderColor: newColor.outerSpace,
                borderWidth: '1px',
                padding: '1em'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <div style={{
                    width: '5em', padding: '0 1em 0 1em', color: '#8BE1C9',
                    fontSize: '1.5em'
                  }}>
                    {audience.name}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                      justifyContent: 'center'
                    }}
                  >
                    <button style={{ minWidth: '10em', margin: '0 2em 0 2em', padding: '0 1em 0 1em !important' }} className='llm-button-gradient llm-button-gradient-small'>EDIT</button>
                  </div>
                </div>
                <div style={{ padding: '0 1em 0 1em', color: '#8BE1C9', fontSize: '1.5em' }}>{audience.users} USERS</div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  paddingLeft: '1.5em'
                }}
              >
                Details
              </div>
              <div
                id='audience-codemirror'
                style={{
                  marginTop: '1em'
                }}
              >
                {
                  audience.name === 'Shrimp' &&
                  CodeMirrorInstance
                }
              </div>
            </Box>
          )
        })
      }
    </div >
  )
}