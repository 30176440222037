import React, { FC, ReactNode } from 'react'
// mui
import { Stack, Typography } from '@mui/material'
// project import
import CreateButton from '../components/CreateButton'
// Helika UI SDK
import { blackAndWhite } from 'src/consts/colors'
import { HelikaSpacings } from 'src/consts/spacings'
import { HelikaTypographies } from 'src/consts/fonts'

export interface IEngageCampaignsLayoutProps {
  onCreate: () => void
  children: ReactNode
}

const EngageCampaignsLayout: FC<IEngageCampaignsLayoutProps> = ({
  onCreate,
  children,
}) => {
  return (
    <Stack
      direction="column"
      sx={{ p: HelikaSpacings.xl, backgroundColor: blackAndWhite.grey900 }}
      gap={HelikaSpacings.lg}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap={HelikaSpacings.sm}
      >
        <Typography sx={HelikaTypographies.heading2}>Campaigns</Typography>
        <CreateButton showIcon={true} text="Create New" onClick={onCreate} />
      </Stack>

      {children}
    </Stack>
  )
}

export default EngageCampaignsLayout
