import { Button } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import { actionCreators } from 'src/state';
import { createChartDashboard } from 'src/utils/helikaAi';

export function ExperimentsDashboardRenderer(props: any) {

  const dispatch = useDispatch()
  const chatController = useSelector((state: any) => state.llmChat.chatController)
  const { setLlmState } = bindActionCreators(actionCreators, dispatch)

  return (
    <Button
      sx={{
        color: 'black',
        fontSize: '0.6em !important'
      }}
      className='llm-button-gradient'
      onClick={() => {
        createChartDashboard(chatController, setLlmState, props.setSgs)
      }}
    >
      DASHBOARD
    </Button>
  );
};