import { Avatar, Box, Typography, useTheme } from '@mui/material';
import { Message, MessageContent } from 'src/utils/chat/src';
import _ from 'lodash';
import React, { useState } from 'react';
import { MessageSection } from './MessageSection';
import { perLetterDelay } from 'src/utils/number';


export function ChatMessage({
  id,
  message,
  showDate,
  showTime,
  scroll
}: {
  id: string;
  message: Message<MessageContent>;
  showDate: boolean;
  showTime: boolean;
  scroll: any;
}): React.ReactElement {

  const theme: any = useTheme()
  const [isLoading, setIsLoading] = useState<boolean[]>(
    (message.content && _.isArray(message.content) && message.content?.length > 1)
      ? message.content.map((content: any, index: number) => index === 1 ? true : false)
      : [false]
  )

  if (message.deletedAt) {
    return <div id={id} />;
  }

  const dispDate = message.updatedAt ? message.updatedAt : message.createdAt;

  const ChatAvator = (
    <Box
      minWidth={0}
      flexShrink={0}
      ml={message.self ? 1 : 0}
      mr={message.self ? 0 : 1}
    >
      <Avatar alt={message.username} src={message.avatar} />
    </Box>
  );

  const ChatUsername = (
    <Box maxWidth="100%" mx={1}>
      <Typography variant="body2" align={message.self ? 'right' : 'left'}>
        {message.username}
      </Typography>
    </Box>
  );

  const ChatDate = (
    <Box maxWidth="100%" mx={1}>
      <Typography
        variant="body2"
        align={message.self ? 'right' : 'left'}
        color="textSecondary"
      >
        {dispDate?.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        })}
      </Typography>
    </Box>
  );

  return (
    // <Grow in>
    <Box maxWidth="100%" display="flex" flexDirection="column" width='100%'>
      {showDate && (
        <Typography align="center">
          {dispDate?.toLocaleDateString()}
        </Typography>
      )}
      <Box
        id={id}
        maxWidth="100%"
        my={1}
        pl={message.self ? '20%' : 0}
        pr={message.self ? '1em' : (['chart', 'jsx', 'widget'].includes(message.type) ? '0' : '20%')}
        display="flex"
        justifyContent={message.self ? 'flex-end' : 'flex-start'}
        style={{ overflowWrap: 'break-word' }}
      >
        {message.avatar && !message.self && ChatAvator}
        <Box minWidth={0} display="flex" flexDirection="column">
          {message.username && ChatUsername}
          <Box
            maxWidth="100%"
            py={1}
            px={2}
            color={message.self ? 'primary.contrastText' : 'text.primary'}
            borderRadius={4}
            boxShadow={2}
            sx={{
              background: message.self ? 'linear-gradient(#EA335F, #612EA6)' : theme.palette.background.default,
              color: message.self ? 'white' : 'text.primary'
            }}
          >
            {
              message.content.map((item: any, index: number) => {
                return <MessageSection
                  key={index}
                  content={item.content}
                  type={item.type}
                  isSelf={message.self}
                  scroll={scroll}
                  color={item.color}
                  delay={
                    _.sum(
                      message.content.slice(0, index)
                        .map((item: any) =>
                          ((item.type === 'text' ? item.content.length : 0) * perLetterDelay) +
                          (item.delay ? item.delay : 1000)
                        )
                    )
                  }
                  messageIndex={index}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              })
            }
          </Box>
          {showTime && ChatDate}
        </Box>
        {message.avatar && message.self && ChatAvator}
      </Box>
    </Box>
    // </Grow>
  );
}