import React from 'react'
import { Button, ButtonProps } from '@mui/material'
import { Add as AddIcon } from '@mui/icons-material'
import { styled } from '@mui/material/styles'
// project-import
import { HelikaRadiis, HelikaSpacings, HelikaSizes } from 'src/consts/spacings'
import { newColor } from 'src/consts/colors'

// Define the styled button with proper typing
const StyledButton = styled(Button)<ButtonProps>(({ theme }) => {
  const themeMode = theme.palette.mode

  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    width: 'auto',
    height: HelikaSizes.xl,
    padding: `${HelikaSpacings.xxs} ${HelikaSpacings.sm} ${HelikaSpacings.xxs} ${HelikaSpacings.xxs}`,
    gap: HelikaSpacings.xxs,
    borderRadius: HelikaRadiis.md,
    backgroundColor:
      themeMode === 'dark' ? newColor.jazzberryJam : newColor.jazzberryJam,

    color: newColor.white,
    fontSize: '14px',
    lineHeight: '140%',
    textTransform: 'none',
    whiteSpace: 'nowrap',
    fontFamily: 'Open Sans, sans-serif',

    '&:hover': {
      backgroundColor: themeMode === 'dark' ? '#FF005C' : '#FF005C',
    },

    '& .MuiButton-startIcon': {
      margin: 0,
    },
  }
})

// Define props interface extending ButtonProps
interface CreateButtonProps extends Omit<ButtonProps, 'startIcon'> {
  text: string
  showIcon?: boolean
}

const CreateButton: React.FC<CreateButtonProps> = ({
  text,
  showIcon = true,
  ...props
}) => {
  return (
    <StyledButton
      variant="contained"
      startIcon={showIcon ? <AddIcon /> : null}
      {...props}
    >
      {text}
    </StyledButton>
  )
}

export default CreateButton
