import { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
// mui
import {
  Box,
  Button,
  styled as muiStyled,
  IconButton,
  Skeleton,
  useMediaQuery,
  useTheme,
  TextField
} from '@mui/material'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button as HelikaButton } from 'helika-ui-sdk'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import AddIcon from '@mui/icons-material/Add'
import { toast } from 'react-toastify'
import { Auth0Context } from 'src/contexts/Auth0Context'
// components
import CreateNewApiKeyModal from '../popups/CreateNewApiKeyModal'
import EditApiKeyModal from '../popups/EditApiKeyModal'
import MoreVertIcon from '@mui/icons-material/MoreVert';
// utils
import {
  isHelikaAdmin,
  isHelikaSuperAdmin,
  isSuperAdmin,
  isOrgAdmin,
} from 'src/utils/user'
import {
  updateAPIKeyStatus as updateAPIKeyStatusAPI,
  getOrgAPIKeys as getOrgAPIKeysAPI,
} from 'src/utils/api/queries'
import copytoclipboard from 'src/utils/copyToClipboard'
// types
import {
  AccessLevelMappings,
  ApiKey,
  Environment,
  EnvironmentMappings,
  PlatformMappings,
  SourceMappings,
} from 'src/types/apiKey'
// assets
import { newColor } from 'src/consts/colors'
import { ReactComponent as CopyIcon } from 'src/assets/copy_button.svg'
import { ReactComponent as DateIcon } from 'src/assets/date_icon.svg'
import { ReactComponent as ApiKeyIcon } from 'src/assets/api_key_icon_2.svg'
import { ReactComponent as CreatedAtIcon } from 'src/assets/last_updated_icon.svg'
import { Menu } from 'helika-ui-sdk'
import { color_base } from 'helika-ui-sdk/dist/src/values/Colors'
import { spacing } from 'helika-ui-sdk/dist/src/values/Spacing'

const StyledSvg = muiStyled('span')(({ theme }) => ({
  fontSize: 0,

  '& svg, & path': {
    fill: theme.palette.text.primary,
  },
}))

const ListHeader = muiStyled(Box)(() => ({
  display: 'flex',
  gap: '0.5rem',
  borderRadius: '38px',
  background: `linear-gradient(255deg, ${newColor.indigo} 6.6%, ${newColor.jazzberryJam} 103.9%)`,
  height: '33.019px',
  width: '97.5%',
  margin: 'auto',
  alignItems: 'center',
}))

const ListHeaderCell = muiStyled(Box)(() => ({
  color: newColor.white,
  display: 'flex',
  gap: '5px',
  alignItems: 'center',
  fontSize: '14px',
}))

const ListRow = muiStyled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '0.5rem',
  borderRadius: '38px',
  backgroundColor:
    theme.palette.mode === 'dark' ? newColor.charcoal : newColor.cultured,
  height: '33.019px',
  width: '97.5%',
  margin: '1rem auto auto',
  alignItems: 'center',
}))

const ListScrollRow = muiStyled(Box)(({ theme }) => ({
  maxHeight: '400px',
  overflowY: 'auto',
  width: '100%',
  margin: 'auto',
}))

const ApiKeyCell = muiStyled('div')(({ theme }) => {
  const downXL = useMediaQuery(theme.breakpoints.down('xl'))
  return {
    display: 'flex',
    paddingLeft: downXL ? '1rem' : '3rem',
    backgroundColor:
      theme.palette.mode === 'dark' ? newColor.charcoal : 'transparent',
    width: '30%',
    alignItems: 'center',
    gap: '1rem',
  }
})

const CellRegular = muiStyled('div')<{ customWidth?: number }>(
  ({ theme, customWidth = 10 }) => ({
    display: 'flex',
    width: `${customWidth}%`,
    backgroundColor:
      theme.palette.mode === 'dark' ? newColor.charcoal : 'transparent',
    alignItems: 'center',
  }),
)

const ApiKeyNameSpan = muiStyled('div')(({ theme }) => ({
  display: 'flex',
  fontSize: '14px',
  width: '30%',
  color: theme.palette.text.primary,
  padding: '0.187px 0.426px 0.813px 1.786px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '127%',
  borderRadius: '20px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor:
    theme.palette.mode === 'dark' ? newColor.charcoal : 'transparent',
  backgroundColor:
    theme.palette.mode === 'dark' ? newColor.charcoal : 'transparent',
  alignItems: 'center',
}))

const ApiKeySpan = muiStyled('div')(({ theme }) => ({
  display: 'flex',
  fontSize: '14px',
  width: '90%',
  color: theme.palette.text.primary,
  padding: '0.187px 0.426px 0.813px 1.786px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '127%',
  borderRadius: '20px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor:
    theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
  backgroundColor:
    theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.lightGray,
  alignItems: 'center',
}))

const CreatedSpan = muiStyled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'space-between',
  // gap: '0.5rem',
  fontSize: '14px',
  color: theme.palette.mode === 'dark' ? newColor.white : newColor.gunMetal,
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '127%',
  alignItems: 'center',
}))

export default function ApiKeys() {
  const theme = useTheme()
  const downLG = useMediaQuery(theme.breakpoints.down('lg'))
  const [showCreateNewApiKeyModal, setShowCreateNewApiKeyModal] =
    useState<boolean>(false)
  const userData = useSelector((state: any) => state.user)

  const {
    getWithAccessToken,
    isLoading,
    isAuthenticated,
    patchWithAccessToken,
  } = useContext(Auth0Context)
  const [apiKeys, setApiKeys] = useState<ApiKey[]>([])
  const [deleteApiKey, setDeleteApiKey] = useState<ApiKey | null>(null)
  const [itemToManage, setItemToManage] = useState<ApiKey | null>(null)
  const [selectedApiKey, setSelectedApiKey] = useState<ApiKey | null>(null)
  const [deactivateDialogOpen, setDeactivateDialogOpen] = useState(false)
  const [openManageSecurityDialogue, setOpenManageSecurityDialogue] = useState(false)
  const [loading, setLoading] = useState(true)
  const [apiKeyLength, setApiKeyLength] = useState<number>()
  const OrgID = useSelector((state: any) => state.user.organization_id)

  //secure key values
  const [showSecureKey, setShowSecureKey] = useState<boolean>(false);
  const [secureKeyDiv, setSecureKeyDiv] = useState<JSX.Element>();

  const revealDiv = (showApi: boolean) => {
    if (showApi) {
      return (
        <div
          style={{
            margin: 'auto 0',
            height: 'fit-content',
            cursor: 'pointer'
          }}
          data-tooltip-id="settingsModal"
          data-tooltip-html={'Click to hide your secret key. Please keep this secure!'}
        >
          <VisibilityIcon
            onClick={() => setShowSecureKey(prevState => !prevState)}
          />
        </div>
      );
    } else {
      return (
        <div
          style={{
            margin: 'auto 0',
            height: 'fit-content',
            cursor: 'pointer'
          }}
          data-tooltip-id="settingsModal"
          data-tooltip-html={'Click to reveal your secret key. Please keep this secure!'}
        >
          <VisibilityOffIcon
            onClick={() => setShowSecureKey(prevState => !prevState)}
          />
        </div>
      );
    }
  }

  //if in whitelist beta and could grab api jey
  useEffect(() => {
    if (itemToManage?.secret_key) {
      setSecureKeyDiv(
        <span
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: spacing.Spacing_xxs,
            padding: `0 ${spacing.Spacing_lg}`
          }}
        >
          <div
            style={{
              margin: 'auto 0',
              whiteSpace: 'nowrap'
            }}
          >Secret Key:</div>
          <TextField
            value={itemToManage?.secret_key}
            onChange={() => { }}
            className={
              theme.palette.mode === 'dark'
                ? 'inputFiledClassRounded'
                : 'inputFiledClassRoundedLight'
            }
            type={!showSecureKey ? 'password' : undefined}

          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: spacing.Spacing_xxs,
              verticalAlign: 'middle',
              padding: 'auto',
              height: 'fit-content',
              margin: 'auto 0'
            }}
          >
            <ContentCopyIcon
              onClick={() => {
                if (itemToManage?.secret_key) navigator.clipboard.writeText(itemToManage?.secret_key);
                toast.success('API Key copied to clipboard', { toastId: 'copyAPI' });
              }}
              style={{
                margin: 'auto 0',
                height: 'fit-content',
                cursor: 'pointer'
              }}
              data-tooltip-id="settingsModal"
              data-tooltip-html={'Click to copy your API key to your clipboard'}
            />
            {revealDiv(showSecureKey)}
          </div>
        </span>
      );
    }
  }, [itemToManage?.secret_key, showSecureKey, theme.palette.mode]);

  const updateApiKeyStatus = async (apiKey: ApiKey) => {
    try {
      setLoading(true)
      const data = await updateAPIKeyStatusAPI(patchWithAccessToken, {
        ...apiKey,
        is_active: false,
      })
      if (!data || !data.results) return
      getOrgApiKeys()
      setDeleteApiKey(null)
      setDeactivateDialogOpen(false)
    } catch (err: any) {
      toast.error(err?.message)
      console.log(err)
    }
  }

  const getOrgApiKeys = async () => {
    try {
      const envs: Environment[] =
        process.env.REACT_APP_ENVIRONMENT === 'PROD'
          ? [Environment.staging, Environment.prod]
          : [Environment.staging]
      const data = await getOrgAPIKeysAPI(getWithAccessToken, envs)

      if (!data || !data.results) return
      setApiKeys(_.orderBy(data.results, 'created_at', 'desc'))
      setApiKeyLength(data.results.length)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const copyToClipboard = async (text: string) => {
    await copytoclipboard(text)
  }

  const confirmDeactivateApiKey = (apiKey: ApiKey) => {
    setDeactivateDialogOpen(true)
    setDeleteApiKey(apiKey)
  }

  const manageSecurity = (apiKey: ApiKey) => {
    setOpenManageSecurityDialogue(true)
    setItemToManage(apiKey)
  }

  const toggleSecurity = async (apiKey: ApiKey | null) => {
    try {
      setLoading(true)
      const data = await updateAPIKeyStatusAPI(patchWithAccessToken, {
        ...apiKey,
        secured: !apiKey?.secured
      })
      if (!data || !data.results) return
      getOrgApiKeys()
      setItemToManage(null)
      setOpenManageSecurityDialogue(false)
    } catch (err: any) {
      toast.error(err?.message)
      console.log(err)
    }
  }

  //get organization API keys
  useEffect(() => {
    if (isLoading || !isAuthenticated || _.isEmpty(userData)) return

    if (
      !isSuperAdmin(userData) &&
      !isHelikaAdmin(userData) &&
      !isHelikaSuperAdmin(userData) &&
      !isOrgAdmin(userData)
    ) {
      window.location.replace('/profile')
    }

    getOrgApiKeys()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading, userData])

  return (
    <div style={{ width: '95%', margin: 'auto' }}>
      <ListHeader>
        <ListHeaderCell style={{ width: '30%', justifyContent: 'center' }}>
          <ApiKeyIcon /> API KEYS
        </ListHeaderCell>
        <ListHeaderCell style={{ width: '12%', textAlign: 'left' }}>
          ACCESS LEVEL
        </ListHeaderCell>
        <ListHeaderCell style={{ width: '10%' }}>SOURCE</ListHeaderCell>
        <ListHeaderCell style={{ width: '12%', textAlign: 'left' }}>
          <CreatedAtIcon /> CREATED
        </ListHeaderCell>
        <ListHeaderCell style={{ width: '10%' }}>
          {downLG ? 'ENV' : 'ENVIRONMENT'}
        </ListHeaderCell>
        <ListHeaderCell style={{ width: '10%' }}>PLATFORM</ListHeaderCell>
        <ListHeaderCell style={{ flex: 1, minWidth: '26px' }}>
          MANAGE
        </ListHeaderCell>
      </ListHeader>
      <ListScrollRow>
        {!loading ? (
          apiKeys
            ?.filter((apiKey) => apiKey?.is_active)
            .map((item, index) => (
              <ListRow key={index}>
                <ApiKeyCell>
                  <ApiKeyNameSpan
                    className="truncate"
                    style={{
                      display: 'block',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textAlign: 'left',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      if (!!selectedApiKey) {
                        setSelectedApiKey(null)
                      } else {
                        setSelectedApiKey(item)
                      }
                    }}
                  >
                    {item.name}
                  </ApiKeyNameSpan>
                  <ApiKeySpan
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <span
                      className="truncate"
                      style={{ textAlign: 'left', paddingLeft: '1rem' }}
                    >
                      {item.key}
                    </span>
                    <IconButton
                      aria-label="copy"
                      onClick={() => {
                        copyToClipboard(item.key)
                        toast.success('API key copied to clipboard!')
                      }}
                      sx={{ padding: '2px 5px', margin: '0 0 0 auto' }}
                    >
                      <StyledSvg>
                        <CopyIcon height={18} width={18} />
                      </StyledSvg>
                    </IconButton>
                  </ApiKeySpan>
                </ApiKeyCell>
                <CellRegular customWidth={12}>
                  <span style={{ flex: 1, width: '88px', textAlign: 'left' }}>
                    {AccessLevelMappings[item.access_level] ||
                      item.access_level}
                  </span>
                </CellRegular>
                <CellRegular>
                  <span style={{ flex: 1, width: '88px', textAlign: 'left' }}>
                    {SourceMappings[item.source] ?? item.source}
                  </span>
                </CellRegular>
                <CellRegular customWidth={12}>
                  <div
                    style={{
                      display: 'flex',
                      gap: '0.5rem',
                      marginLeft: '0.5rem',
                    }}
                  >
                    <DateIcon />
                    <span style={{ flex: 1, width: '88px', textAlign: 'left' }}>
                      {moment(item.created_at).format('YYYY-MM-DD')}
                    </span>
                  </div>
                </CellRegular>
                <CellRegular>
                  <span
                    style={{
                      flex: 1,
                      width: '88px',
                      textAlign: 'left',
                      marginLeft: '0.5rem',
                    }}
                  >
                    {item.env ? EnvironmentMappings[item.env] ?? item.env : '-'}
                  </span>
                </CellRegular>
                <CellRegular>
                  <span
                    style={{
                      flex: 1,
                      width: '88px',
                      textAlign: 'left',
                      marginLeft: '0.5rem',
                    }}
                  >
                    {item.platform
                      ? PlatformMappings[item.platform] ?? item.platform
                      : '-'}
                  </span>
                </CellRegular>
                <CreatedSpan>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      maxWidth: '30em',
                      margin: 'auto',
                      cursor: 'pointer',
                      height: '36px !important',
                      width: '36px !important',
                      justifyContent: 'center',
                      border: 'none',
                    }}
                  >
                    <Menu
                      setOpen={() => { }}
                      title={''}
                      sxProps={{}}
                      disabled={false}
                      darkMode={true}
                      menuItems={[
                        {
                          value: 'Deactivate',
                          onClick: () => {
                            confirmDeactivateApiKey(item)
                          },
                          icon: null
                        },
                        {
                          value: 'Security',
                          onClick: () => {
                            manageSecurity(item)
                          },
                          icon: null
                        },
                      ]}
                      sxPropsMenu={{
                        border: 'none',
                      }}
                      sxPropsMenuButton={{
                        background: 'transparent',
                        border: 'none',
                      }}
                      buttonComponent={
                        <MoreVertIcon
                          style={{
                            margin: 0,
                            border: 'none',
                            height: '24px',
                            width: '24px',
                            color: theme.palette.mode === 'dark' ? color_base.white : color_base.black
                          }}
                        />
                      }
                    />
                  </div>
                </CreatedSpan>
              </ListRow>
            ))
        ) : (
          <>
            <ListRow>
              <Skeleton width={'100%'} />
            </ListRow>
            <ListRow>
              <Skeleton width={'100%'} />
            </ListRow>
            <ListRow>
              <Skeleton width={'100%'} />
            </ListRow>
          </>
        )}
      </ListScrollRow>

      {!loading && OrgID !== 5 ? (
        apiKeyLength && apiKeyLength < 20 ? (
          <Button
            onClick={() => setShowCreateNewApiKeyModal(true)}
            sx={{ pr: 2, height: '26px', mt: 4 }}
            variant="contained"
            size="small"
            startIcon={<AddIcon />}
            className="helikaButtonClass"
          >
            Generate API Key
          </Button>
        ) : (
          <Button
            sx={{ pr: 2, height: '26px', mt: 4, cursor: 'not-allowed' }}
            variant="contained"
            size="small"
            startIcon={<AddIcon />}
            className="helikaButtonClass"
          >
            Generate API Key
          </Button>
        )
      ) : (
        <Button
          onClick={() => setShowCreateNewApiKeyModal(true)}
          sx={{ pr: 2, height: '26px', mt: 4 }}
          variant="contained"
          size="small"
          startIcon={<AddIcon />}
          className="helikaButtonClass"
        >
          Generate API Key
        </Button>
      )}

      <Dialog
        keepMounted={true}
        open={deactivateDialogOpen}
        onClose={() => setDeactivateDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <p>Deactivating an API Key is a permanent action.</p>
          <p>
            Make sure this key is not being used anywhere. Deactivating this key
            while in use will cause data to get dropped and lose data.
          </p>
          <p
            style={{
              fontSize: '1.5em',
              width: '100%',
              textAlign: 'center',
              color: 'red',
            }}
          >
            Are you sure?
          </p>
        </DialogTitle>
        <DialogActions>
          <Button
            style={{
              border: 'solid',
              color: 'white',
              backgroundColor: 'red',
              borderColor: 'red',
            }}
            onClick={() => setDeactivateDialogOpen(false)}
          >
            Cancel
          </Button>
          <Button
            style={{
              border: 'solid',
              color: 'white',
              backgroundColor: 'green',
              borderColor: 'green',
            }}
            onClick={() => updateApiKeyStatus(deleteApiKey!)}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        keepMounted={true}
        open={openManageSecurityDialogue}
        onClose={() => setOpenManageSecurityDialogue(false)}
        aria-labelledby="alert-dialog-title-security"
        aria-describedby="alert-dialog-description-security"
        PaperProps={{
          sx: {
            color: theme.palette.mode === 'dark' ? 'white' : 'black',
            background:
              theme.palette.mode === 'dark'
                ? newColor.midnight
                : newColor.cultured,
            width: '480px !important',
            minWidth: '480px'
          },
        }}
      >
        <DialogTitle id="alert-dialog-title-security">
          <p>Manage API Key Security</p>
          <p>
            {`Currently: ${itemToManage?.secured ? 'Secured' : 'Not Secured'}`}
          </p>
        </DialogTitle>
        {
          itemToManage?.secured && secureKeyDiv
        }
        <DialogActions>
          <HelikaButton
            type='Tertiary'
            handleClick={() => {
              setItemToManage(null)
              setOpenManageSecurityDialogue(false)
            }}
            darkMode={theme.palette.mode === 'dark'}
            disabled={loading}
          >
            Cancel
          </HelikaButton>
          <HelikaButton
            type='Primary'
            handleClick={() => toggleSecurity(itemToManage)}
            darkMode={theme.palette.mode === 'dark'}
            disabled={loading}
          >
            Toggle Security
          </HelikaButton>
        </DialogActions>
      </Dialog>
      <CreateNewApiKeyModal
        open={showCreateNewApiKeyModal}
        setOpen={setShowCreateNewApiKeyModal}
        onCreated={getOrgApiKeys}
      />
      <EditApiKeyModal
        open={!!selectedApiKey}
        data={selectedApiKey}
        setOpen={setSelectedApiKey}
        refetch={getOrgApiKeys}
      />
    </div>
  )
}
