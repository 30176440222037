//imports

import LoadingComponent from 'src/components/LoadingComponent';
import { Box } from '@mui/material';
import React from "react";
import { Unity, useUnityContext } from "react-unity-webgl";

//----------------------------------------------------------------------------------------------------------

export default function GameDemo() {

    const { unityProvider, requestFullscreen, isLoaded } = useUnityContext({
        loaderUrl: "https://gdc-demo-game.s3.us-east-2.amazonaws.com/public_build/gdc+single+game+demo.loader.js",
        dataUrl: "https://gdc-demo-game.s3.us-east-2.amazonaws.com/public_build/gdc+single+game+demo.data.unityweb",
        frameworkUrl: "https://gdc-demo-game.s3.us-east-2.amazonaws.com/public_build/gdc+single+game+demo.framework.js.unityweb",
        codeUrl: "https://gdc-demo-game.s3.us-east-2.amazonaws.com/public_build/gdc+single+game+demo.wasm.unityweb",
        streamingAssetsUrl: "https://gdc-demo-game.s3.us-east-2.amazonaws.com/public_build/StreamingAssets/"
    });

    async function clickHandler() {
        if (!isLoaded) return
        requestFullscreen(true)
    }

    return (
        <Box
            id='game_demo'
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                justifyContent: 'center',
                margin: 'auto'
            }}
            onClick={clickHandler}
        >
            {
                !isLoaded &&
                <div
                    style={{
                        display: isLoaded ? 'none' : 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '160px',
                    }}
                >
                    <LoadingComponent />
                </div>
            }
            <Unity
                unityProvider={unityProvider}
                style={{
                    display: isLoaded ? 'flex' : 'none'
                }}
            />
        </Box>
    );
};