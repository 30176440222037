import React, { useState } from 'react'
import {
  MenuItem,
  Select,
  Button,
  Typography,
  Popover,
  Stack,
  IconButton,
  useTheme,
} from '@mui/material'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/system'
import { newColor } from 'src/consts/colors'
import { typography } from 'helika-ui-sdk/dist/src/values/Typography'

// Common styles for text elements with dynamic theme
const commonTextStyles = (themeMode: 'dark' | 'light') => ({
  fontFamily: 'Open Sans, sans-serif',
  fontSize: '14px',
  color: themeMode === 'dark' ? newColor.white : newColor.black,
})

// Styled button for the sort control with dynamic theme
const CustomButton = styled(Button)<{ themeMode: 'dark' | 'light' }>(
  ({ themeMode }) => ({
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '110px',
    minWidth: '110px',
    height: '36px',
    border: '1px solid #434D5A',
    borderRadius: '4px',
    textTransform: 'none',
    background: themeMode === 'dark' ? newColor.charcoal : newColor.transparent,
    color: themeMode === 'dark' ? newColor.white : newColor.black,
    gap: '4px',
    '&:hover': {
      backgroundColor:
        themeMode === 'dark' ? newColor.darkGunmetal : newColor.lightGray,
    },
    ...typography.label_regular, // Apply label_regular typography
  }),
)

// Styled popover container for the sorting options with dynamic theme
const PopoverContainer = styled(Popover)<{ themeMode: 'dark' | 'light' }>(
  ({ themeMode }) => ({
    '.MuiPopover-paper': {
      display: 'flex',
      flexDirection: 'column',
      padding: '4px 0px',
      width: 'auto',
      background: themeMode === 'dark' ? newColor.charcoal : newColor.cultured,
      boxShadow: '0px 20px 20px rgba(0, 0, 0, 0.3)',
      borderRadius: '4px',
      border: 'none',
      fontFamily: 'Open Sans, sans-serif',
    },
  }),
)

// Container for sort controls inside the popover with dynamic theme
const SortContainer = styled(Stack)<{ themeMode: 'dark' | 'light' }>(
  ({ themeMode }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 16px',
    gap: '4px',
    width: '100%',
    background: themeMode === 'dark' ? newColor.charcoal : newColor.cultured,
    fontFamily: 'Open Sans, sans-serif',
  }),
)

// Row layout for sort controls
const SortRow = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px',
  width: '100%',
  justifyContent: 'space-between',
}))

// Styled select field for sorting criteria with consistent `Select` field styling
const SelectField = styled(Select)<{ themeMode: 'dark' | 'light' }>(
  ({ themeMode }) => ({
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '8px',
    width: '300px',
    height: '36px',
    background: themeMode === 'dark' ? '#1C2025' : newColor.white,
    border: `1px solid ${themeMode === 'dark' ? '#434D5A' : '#C0C0C0'}`,
    borderRadius: '4px',
    color: themeMode === 'dark' ? newColor.white : newColor.black,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '140%',
    fontFamily: 'Open Sans, sans-serif',
    flex: 'none',
    alignSelf: 'stretch',
    flexGrow: 0,
    '& fieldset': {
      border: 'none',
    },
    '.MuiSelect-select': {
      padding: '8px 12px 8px 12px',
      display: 'flex',
      alignItems: 'center',
    },
  }),
)

// Custom SVG icon for toggling sort order with dynamic color based on theme
const SVGSortIcon = ({
  sortOrder,
  themeMode,
}: {
  sortOrder: 'asc' | 'desc'
  themeMode: 'dark' | 'light'
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      transform: sortOrder === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
      transition: 'transform 0.3s ease',
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 5L3 10.0782H6V19H8V10.0782H11L7 5ZM15.3333 18H12V16H15.3333V18ZM12 8V6H22V8H12ZM18.6667 13H12V11H18.6667V13Z"
      fill={themeMode === 'dark' ? newColor.white : newColor.black}
    />
  </svg>
)

// SortButton component
const SortButton = ({
  sortField,
  sortOrder,
  onSortChange,
}: {
  sortField: string
  sortOrder: 'asc' | 'desc'
  onSortChange: (field: string, order: 'asc' | 'desc') => void
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const theme = useTheme()
  const themeMode = theme.palette.mode

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSortOrderToggle = () => {
    onSortChange(sortField, sortOrder === 'asc' ? 'desc' : 'asc')
  }

  return (
    <>
      <CustomButton onClick={handleClick} themeMode={themeMode}>
        <SwapVertIcon
          sx={{ color: themeMode === 'dark' ? newColor.white : newColor.black }}
        />
        <Typography
          sx={{
            ...typography.label_regular,
            textAlign: 'center',
            color: themeMode === 'dark' ? newColor.white : newColor.black,
          }}
        >
          Sort
        </Typography>
      </CustomButton>

      <PopoverContainer
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        themeMode={themeMode}
      >
        <SortContainer themeMode={themeMode}>
          <SortRow>
            <SelectField
              value={sortField}
              onChange={(e) =>
                onSortChange(e.target.value as string, sortOrder)
              }
              themeMode={themeMode}
            >
              <MenuItem value="updated_at" sx={commonTextStyles(themeMode)}>
                Last updated
              </MenuItem>
              <MenuItem value="created_at" sx={commonTextStyles(themeMode)}>
                Created date
              </MenuItem>
              <MenuItem value="name" sx={commonTextStyles(themeMode)}>
                Alphabetical
              </MenuItem>
            </SelectField>

            <IconButton onClick={handleSortOrderToggle}>
              <SVGSortIcon sortOrder={sortOrder} themeMode={themeMode} />
            </IconButton>

            <IconButton onClick={handleClose}>
              <CloseIcon
                sx={{
                  color: themeMode === 'dark' ? newColor.white : newColor.black,
                }}
              />
            </IconButton>
          </SortRow>
        </SortContainer>
      </PopoverContainer>
    </>
  )
}

export default SortButton
