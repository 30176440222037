import ConfigList from "src/pages/Modules/GameManagement/ConfigList";
import { HELIKA_LOGO_URL } from "./string";
import { baseOptions, simpleMovingAverage, lineOptions } from 'src/utils/echarts';
import { EChart } from 'src/components/atoms/v2/eChart';
import { ChatWebform } from "src/components/llmChat/ChatWebform";
import _ from "lodash";
import { getGameConfig, postToAi } from "./api/queries";
import { toast } from "react-toastify";
import { Widget } from "src/components/llmChat/StatWidget";
import Leaderboard from "src/components/llmChat/Leaderboard";
import { Dashboard } from "src/components/llmChat/Dashboard";
import Experiments from "src/components/llmChat/Experiments";
import LoadingComponent from "src/components/LoadingComponent";
import { Button } from "@mui/material";
import { AudienceEmbed } from "src/components/llmChat/AudienceEmbed";
import { ConfigChanges } from "src/components/llmChat/ConfigChanges";
import { Goodbye } from "src/components/llmChat/Goodbye";
import { post } from "./api/frontend";
import { perLetterDelay, randomDelayGenerator } from "./number";
import { newColor } from "src/consts/colors";

export function DetailsButtons({ onClick }: { onClick?: any }) {
    return (
        <Button
            style={{
                color: 'black',
                background: 'white',
                border: 'solid',
                borderColor: 'black',
                borderRadius: '0.5em',
                padding: '0.2em 1em 0.2em 1em',
                margin: '0.5em 0 0.5em 0'
            }}
            onClick={onClick}
        >
            DETAILS...
        </Button>
    );
}

export async function handleAIResponse(chatController: any, aiResponse: any) {
    await chatController?.addMessage({
        type: 'text',
        content: [
            {
                content: aiResponse,
                type: 'text'
            }
        ],
        self: false,
        avatar: HELIKA_LOGO_URL
    });
}

export async function getHelikaAiResponse(chatController: any, messages: any, setLlmState: any, setSgs: any, suggestions: any, postWithAccessToken?: any, getWithAccessToken?: any, getTokenIfNecessary?: any, scriptState?: number, setScriptState?: any) {

    //if no messages, do nothing
    if (_.isEmpty(messages)) return

    //remove suggestions if there's messages
    if (messages.length > 1) setSgs(undefined)

    let lastMessage = messages && messages[messages.length - 1]

    //if necessary, add content to content window
    if (['jsx', 'chart', 'widget'].includes(lastMessage.type)) {
        setLlmState({
            type: 'SET_CONTENT',
            payload: lastMessage
        })
    }

    //only respond to text messages
    if (lastMessage.type !== 'text') return

    //responses to user messgaes
    if (lastMessage && lastMessage.content[0].content.startsWith('Set State ')) {
        try {
            let newState = lastMessage.content[0].content.replace('Set State ', '');
            setScriptState(Number(newState));
        } catch (e: any) {
            toast.error(e?.message)
        }
    } else if (lastMessage && lastMessage.content[0].content.startsWith(' ')) {
        switch (scriptState) {
            case 1:
                await step1(chatController, setLlmState, setSgs, setScriptState)
                break;
            case 2:
                await step2(chatController, setLlmState, postWithAccessToken, getWithAccessToken, setSgs, getTokenIfNecessary, setScriptState)
                break;
            case 3:
                await gameState(chatController, setLlmState, postWithAccessToken, getWithAccessToken, setSgs, getTokenIfNecessary, setScriptState)
                break;
            case 4:
                await leaderboardEmbed(chatController, setLlmState, setScriptState)
                break;
            case 5:
                await experimentsEmbed(chatController, setLlmState, setSgs, setScriptState)
                break;
            case 6:
                await step4(chatController, setLlmState, getTokenIfNecessary, setScriptState)
                break;
        }
    } else if (lastMessage && lastMessage.content[0].content.includes('Hey Helika, ')) {
        let data = { helika_api_key: process.env.REACT_APP_EVENTS_API_KEY, message: lastMessage.content[0].content?.replace('Hey Helika, ', '') }
        postToAi(postWithAccessToken, data)
            .then((resp: any) => {
                handleAIResponse(chatController, resp.response.output)
            }).catch(e => {
                toast.error(e?.message)
            })
    } else if (lastMessage && lastMessage.content[0].content?.toLowerCase().includes('config changes embed scripted')) {
        configChanges(setLlmState)
    } else if (lastMessage && lastMessage.content[0].content?.toLowerCase().includes('end the experiment and optimize it on your own')) {
        step4(chatController, setLlmState, getTokenIfNecessary, setScriptState)
    } else if (lastMessage && lastMessage.content[0].content.includes('Helika, how are different segments performing? I want to see revenue and ARPU by segment.')) {
        helikaThinking(randomDelayGenerator('long'), async () => {
            await chatController?.addMessage({
                type: 'text',
                content: [
                    {
                        content: 'Your whale segment is driving most of your revenue.',
                        type: 'text',
                    },
                    {
                        content: 'I’ve put together a visualization for comparison',
                        type: 'text',
                    }
                ],
                self: false,
                avatar: HELIKA_LOGO_URL
            })
        })
    } else if (lastMessage && lastMessage.content[0].content?.toLowerCase()?.includes('create chart')) {
        await createChart(chatController, setLlmState, lastMessage.content)
    } else if (lastMessage && lastMessage.content[0].content?.toLowerCase()?.includes('get summary')) {
        await getSummary(chatController, setLlmState, setScriptState)
    } else if (lastMessage && lastMessage.content[0].content?.toLowerCase()?.includes('create dashboard')) {
        await createChartDashboard(chatController, setLlmState, setSgs)
    } else if (lastMessage && lastMessage.content[0].content.includes('remove')) {
        await chatController?.addMessage({
            type: 'text',
            content: [
                {
                    content: 'Ok, removing content.',
                    type: 'text',
                }
            ],
            self: false,
            avatar: HELIKA_LOGO_URL
        })
        setLlmState({
            type: 'SET_CONTENT',
            payload: {
                type: 'jsx',
                content: <div style={{ margin: 'auto', height: 'fit-content' }}>Try asking HelikaAI something!</div>,
            }
        })
        setLlmState({
            type: 'SET_EXPANDED'
        })
    } else if (lastMessage && lastMessage.content[0].content.includes('How is the game doing? script')) {
        await step1(chatController, setLlmState, setSgs, setScriptState)
    } else if (lastMessage
        //STEP 2 A
        && lastMessage.content[0].content.includes('Optimize ARPU for shrimp')
    ) {
        step2(chatController, setLlmState, postWithAccessToken, getWithAccessToken, setSgs, getTokenIfNecessary, setScriptState)
    } else if (lastMessage
        //game information
        && lastMessage.content[0].content.includes('game information script')
    ) {
        gameState(chatController, setLlmState, postWithAccessToken, getWithAccessToken, setSgs, getTokenIfNecessary, setScriptState)
    } else if (lastMessage && lastMessage.content[0].content.includes('create leaderboard script')) {
        leaderboardEmbed(chatController, setLlmState, setScriptState)
    } else if (lastMessage && lastMessage.content[0].content.includes('I want to play a game script')) {
        gameEmbed(chatController, setLlmState)
    } else if (lastMessage && lastMessage.content[0].content.includes('webform script')) {
        webformEmbed(chatController, setLlmState, lastMessage.content, setSgs)
    } else if (lastMessage && lastMessage.content[0].content.includes('show me audience script')) {
        audienceEmbed(setLlmState)
    } else if (lastMessage && lastMessage.content[0].content.includes('How are my experiments doing script')) {
        experimentsEmbed(chatController, setLlmState, setSgs, setScriptState)
    } else if (lastMessage && lastMessage.content[0].content.includes('liveops script')) {
        liveOpsEmbed(chatController, setLlmState)
    } else if (lastMessage && lastMessage.content[0].content.includes('widget script')) {
        createWidget(chatController, setLlmState)
    }

}

export async function createWidget(chatController: any, setLlmState: any) {
    await helikaStreaming(1000, async () => {
        await chatController?.addMessage({
            type: 'text',
            content: [
                {
                    content: `Ok! Here's a widget.`,
                    type: 'text',
                },
                {
                    content:
                        < div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                minWidth: '20vw',
                                width: 'fit-content',
                                height: '50%',
                                minHeight: 'fit-content',
                                margin: 'auto',
                                maxHeight: '100%',
                                maxWidth: '100%',
                                overflow: 'auto',
                            }}
                        >
                            <Widget
                                value={'20'}
                                small={false}
                                loading={false}
                                header={'No. of Tokens Traded'}
                                stacked={true}
                            />
                        </div >,
                    type: 'jsx',
                }
            ],
            self: false,
            avatar: HELIKA_LOGO_URL
        });
        setLlmState({
            type: 'SET_MINIMIZED',
        })
    })
}

export async function createChart(chatController: any, setLlmState: any, message: any) {

    const eChartOptions: any = {
        ...baseOptions,
        title: {
            ...baseOptions.title,
            text: 'Effective Royalty Rate',
        },
        xAxis: {
            ...baseOptions.xAxis,
            data: [1, 2, 3, 4, 5, 6]
        },
        yAxis: {
            axisLabel: {
                formatter: '{value}%',
            },
        },
        series: [
            {
                ...lineOptions,
                smooth: true,
                name: 'Effective Royalty Rate %',
                data: [0, 2, 18, 15, 23, 30]
            },
            {
                ...lineOptions,
                smooth: true,
                name: '3 Point Moving average',
                data: simpleMovingAverage([0, 2, 18, 15, 23, 30], 3)
            }
        ],
        legend: {
            top: 25,
            right: 10,
            textStyle: { color: 'white' },
        }
    }

    let echart =
        <EChart
            loading={false}
            options={eChartOptions}
        />

    function setChartAsContentFunction() {
        setLlmState({
            type: 'SET_CONTENT',
            payload: {
                type: 'link',
                domain: 'GAME MANAGEMENT',
                content:
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            minWidth: '30em',
                            width: 'fit-content',
                            height: 'fit-content',
                            minHeight: 'fit-content',
                            margin: 'auto',
                            maxHeight: '100%',
                            overflow: 'auto',
                        }}
                    >
                        {echart}
                    </div>
                ,
                self: false,
                avatar: HELIKA_LOGO_URL
            }
        })
        setLlmState({
            type: 'SET_MINIMIZED',
        })
    }

    helikaStreaming(1000, async () => {
        await chatController?.addMessage({
            type: 'text',
            content: [
                {
                    content: `Ok! Here's a chart.`,
                    type: 'jsx',
                },
                {
                    type: 'jsx',
                    content:
                        <DetailsButtons
                            onClick={setChartAsContentFunction}
                        />
                }
            ],
            self: false,
            avatar: HELIKA_LOGO_URL
        });
        setChartAsContentFunction()
    })
}

export async function movingAverageChart(chatController: any, setLlmState: any, message: any) {

    const avgLength = 3;

    //todo: theme white where necessary
    const eChartOptions: any = {
        ...baseOptions,
        title: {
            ...baseOptions.title,
            text: 'Effective Royalty Rate',
        },
        xAxis: {
            ...baseOptions.xAxis,
            data: [1, 2, 3, 4, 5, 6]
        },
        yAxis: {
            axisLabel: {
                formatter: '{value}%',
            },
        },
        series: [
            {
                ...lineOptions,
                smooth: true,
                name: 'Effective Royalty Rate %',
                data: [0, 2, 18, 15, 23, 30]
            },
            {
                ...lineOptions,
                smooth: true,
                name: `${avgLength} Point Moving average`,
                data: simpleMovingAverage([0, 2, 18, 15, 23, 30], avgLength)
            }
        ],
        legend: {
            top: 25,
            right: 10,
            textStyle: { color: 'white' },
        }
    }

    let echart =
        <EChart
            loading={false}
            options={eChartOptions}
        />

    helikaStreaming(1000, async () => {
        await chatController?.addMessage({
            type: 'text',
            content: [
                {
                    content: `Ok! Here's a chart.`,
                    type: 'text',
                },
                {
                    content:
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                minWidth: '20vw',
                                width: 'fit-content',
                                height: '50%',
                                minHeight: 'fit-content',
                                margin: 'auto',
                                maxHeight: '100%',
                                maxWidth: '100%',
                                overflow: 'auto',
                            }}
                        >
                            {echart}
                        </div>,
                    type: 'jsx',
                }
            ],
            self: false,
            avatar: HELIKA_LOGO_URL
        });
        setLlmState({
            type: 'SET_MINIMIZED',
        })
    })
}

export async function createChartDashboard(chatController: any, setLlmState: any, setSgs: any) {
    helikaStreaming(1000, async () => {
        setLlmState({
            type: 'SET_CONTENT',
            payload: {
                type: 'jsx',
                domain: 'ANALYTICS',
                content:
                    <Dashboard />
                ,
                self: false,
                avatar: HELIKA_LOGO_URL
            }
        })
        setLlmState({
            type: 'SET_MINIMIZED',
        })
    })
}

export async function liveOpsEmbed(chatController: any, setLlmState: any) {
    helikaStreaming(1000, async () => {
        await chatController?.addMessage({
            type: 'text',
            content: [
                {
                    content: `Ok! Here's LiveOps:`,
                    type: 'text',
                }
            ],
            self: false,
            avatar: HELIKA_LOGO_URL
        })
        setLlmState({
            type: 'SET_CONTENT',
            payload: {
                type: 'link',
                domain: 'GAME MANAGEMENT',
                content:
                    <ConfigList />
                ,
                self: false,
                avatar: HELIKA_LOGO_URL
            }
        })
        setLlmState({
            type: 'SET_MINIMIZED',
        })
    })
}

export async function configChanges(setLlmState: any) {
    helikaThinking(setLlmState, async () => {
        let defaultData = {
            "name": {
                "type": "text",
                "title": "name",
                "default": "Paul",
                "previousValue": "Paul"
            },
            "title": {
                "type": "text",
                "title": "title",
                "previousValue": "Hero"
            },
            "description": {
                "type": "select",
                "title": "description",
                "default": "developer",
                "options": ["developer", "tester"],
                "previousValue": "tester"
            }
        }
        setLlmState({
            type: 'SET_CONTENT',
            payload: {
                type: 'link',
                domain: 'GAME MANAGEMENT',
                content:
                    <ConfigChanges
                        fields={defaultData}
                    />
                ,
                self: false,
                avatar: HELIKA_LOGO_URL
            }
        })
        setLlmState({
            type: 'SET_MINIMIZED',
        })
    }, 1000)
}

export async function webformEmbed(chatController: any, setLlmState: any, content: any, setSgs: any) {
    helikaStreaming(1000, async () => {
        await chatController?.addMessage({
            type: 'text',
            content: [
                {
                    content: `Ok! Here's a form:`,
                    type: 'text',
                }
            ],
            self: false,
            avatar: HELIKA_LOGO_URL
        });
        let defaultData = {
            "name": {
                "type": "text",
                "title": "name",
                "default": "Paul",
                "previousValue": "Paul"
            },
            "title": {
                "type": "text",
                "title": "title",
                "previousValue": "Hero"
            },
            "description": {
                "type": "select",
                "title": "description",
                "default": "developer",
                "options": ["developer", "tester"],
                "previousValue": "tester"
            }
        }
        let contentToParse = !_.isEmpty(content[0]) ? content[0] : defaultData
        let json: any = {};
        try {
            json = JSON.parse(contentToParse?.replace('make a webform from ', ''))
        } catch (e) {
            toast.error('Could not parse json, default json used');
            json = defaultData;
        }
        setLlmState({
            type: 'SET_CONTENT',
            payload: {
                type: 'link',
                domain: 'GAME MANAGEMENT',
                content:
                    <ChatWebform
                        fields={json}
                    />
                ,
                self: false,
                avatar: HELIKA_LOGO_URL
            }
        })
        setLlmState({
            type: 'SET_MINIMIZED',
        })
    })
}

export async function experimentsEmbed(chatController: any, setLlmState: any, setSgs: any, setScriptState: any) {
    helikaStreaming(1000, async () => {
        await chatController?.addMessage({
            type: 'text',
            content: [
                {
                    content: `Here are your experiments:`,
                    type: 'text',
                }
            ],
            self: false,
            avatar: HELIKA_LOGO_URL
        })
        setLlmState({
            type: 'SET_CONTENT',
            payload: {
                type: 'jsx',
                content: <Experiments setSgs={setSgs} />,
                self: false,
                avatar: HELIKA_LOGO_URL
            }
        })
        setLlmState({
            type: 'SET_MINIMIZED',
        })
        setScriptState(6)
    })
}

export async function gameEmbed(chatController: any, setLlmState: any) {
    helikaStreaming(1000, async () => {
        await chatController?.addMessage({
            type: 'text',
            content: [
                {
                    content: `Ok! Here's a game:`,
                    type: 'text',
                },
                {
                    content:
                        <div
                            style={{ maxHeight: '100%', maxWidth: '100%', margin: 'auto' }}
                        >
                            <a style={{ color: 'lightblue' }} href='https://localhost:3000/game-demo'>Link to Game</a>
                        </div>,
                    type: 'jsx'
                }
            ],
            self: false,
            avatar: HELIKA_LOGO_URL
        })
        setLlmState({
            type: 'SET_CONTENT',
            payload: {
                type: 'link',
                domain: 'GAME DEMO',
                content: <iframe style={{ height: '100%' }} src='https://itch.io/embed-upload/9731434?color=333333' title='game link' />,
                self: false,
                avatar: HELIKA_LOGO_URL
            }
        })
        setLlmState({
            type: 'SET_MINIMIZED',
        })
    })
}

export async function step1(chatController: any, setLlmState: any, setSgs: any, setScriptState: any) {
    let dashboardDelay = randomDelayGenerator('long');
    let seconTextDelay = dashboardDelay;
    helikaStreaming(1000, async () => {
        await chatController?.addMessage({
            type: 'text',
            content: [
                {
                    content: `I’ll put together a dashboard for you.`,
                    type: 'text',
                    delay: seconTextDelay
                },
                {
                    content: `Is there anything else you’d like to explore?`,
                    type: 'text'
                },
            ],
            self: false,
            avatar: HELIKA_LOGO_URL
        })
        let sigmaEmbedUrl = `https://app.sigmacomputing.com/embed/1-2ZchtZIeMSJqumeEHG9lbS`
        helikaThinking(setLlmState, () => {
            helikaStreaming(0, async () => {
                setLlmState({
                    type: 'SET_CONTENT',
                    domain: 'ANALYTICS',
                    payload: {
                        domain: 'ANALYTICS',
                        type: 'link',
                        content: <iframe style={{ height: '100%' }} src={sigmaEmbedUrl} title='embedded link' />,
                        self: false,
                        avatar: HELIKA_LOGO_URL
                    }
                })
                setScriptState(2)
            })
        }, dashboardDelay)
    })
}


export async function leaderboardEmbed(chatController: any, setLlmState: any, setScriptState: any) {

    helikaStreaming(1000, async () => {
        await chatController?.addMessage({
            type: 'text',
            content: [
                {
                    content: `Ok! Here's the leadeboard:`,
                    type: 'text'
                },
            ],
            self: false,
            avatar: HELIKA_LOGO_URL
        })
        setLlmState({
            type: 'SET_CONTENT',
            payload: {
                type: 'jsx',
                domain: 'ENGAGE',
                content: <Leaderboard />,
                self: false,
                avatar: HELIKA_LOGO_URL
            }
        })
        setLlmState({
            type: 'SET_MINIMIZED',
        })
        setScriptState(5)
    })
}

async function step2(chatController: any, setLlmState: any, postWithAccessToken: any, getWithAccessToken: any, setSgs: any, getTokenIfNecessary: any, setScriptState: any) {
    await getGameConfig(getWithAccessToken, {
        helika_api_key: process.env.REACT_APP_EVENTS_API_KEY,
        segment_ids: ["0"]
    })
    let texts = [
        'Optimization started...',
        '      1. Identifying segment',
        '      2. Isolate relevant configuration variables',
        '      3. Propose configuration change',
        '      4. Deploy configuration change',
        'Identifying player segment using the Shrimp Audience...',
        'Shrimp segment indentified.',
        'Staging initial configuration changes...',
        'Configuration variables altered.',
        'Deploying changes...',
        'Changes deployed.',
        'Is there anything else I can assist with?',
    ]
    let delays = [
        randomDelayGenerator('long'),
        randomDelayGenerator('short'),
        randomDelayGenerator('short'),
        randomDelayGenerator('short'),
        randomDelayGenerator('short'),
        randomDelayGenerator('short'),
    ]

    let firstGroupDelay = delays[0];
    let secondGroupDelay = firstGroupDelay + 4000 + (_.sum(texts.slice(0, 5).map(item => item.length)) * perLetterDelay);
    let thirdGroupDelay = secondGroupDelay + delays[2] - 4500 + (_.sum(texts.slice(5, 7).map(item => item.length)) * perLetterDelay);
    let fourthGroupDelay = thirdGroupDelay - 4500 + (_.sum(texts.slice(7, 9).map(item => item.length)) * perLetterDelay);
    let fifthGroupDelay = fourthGroupDelay - 4500 + (_.sum(texts.slice(9, 11).map(item => item.length)) * perLetterDelay);

    await helikaStreaming(1000, async () => {
        await chatController?.addMessage({
            type: 'text',
            content: [
                {
                    content: texts[0],
                    type: 'text',
                    delay: firstGroupDelay
                },
                {
                    content: texts[1],
                    type: 'text',
                },
                {
                    content: texts[2],
                    type: 'text',
                },
                {
                    content: texts[3],
                    type: 'text',
                },
                {
                    content: texts[4],
                    type: 'text',
                }
            ],
            self: false,
            avatar: HELIKA_LOGO_URL
        })
    })

    await helikaStreaming(secondGroupDelay, async () => {
        await chatController?.addMessage({
            type: 'text',
            content: [
                {
                    content: texts[5],
                    type: 'text',
                    delay: delays[2] + 2000
                },
                {
                    content: texts[6],
                    type: 'text'
                },
                {
                    content: <DetailsButtons
                        onClick={async () => audienceEmbed(setLlmState)}
                    />,
                    type: 'button',
                }
            ],
            self: false,
            avatar: HELIKA_LOGO_URL
        })
    })

    await helikaStreaming(thirdGroupDelay, async () => {
        await chatController?.addMessage({
            type: 'text',
            content: [
                {
                    content: texts[7],
                    type: 'text',
                    delay: delays[3] + 1000
                },
                {
                    content: texts[8],
                    type: 'text',
                },
                {
                    content: <DetailsButtons
                        onClick={async () => {
                            configChanges(setLlmState)
                        }}
                    />,
                    type: 'button',
                }
            ],
            self: false,
            avatar: HELIKA_LOGO_URL
        })
    })

    helikaStreaming(fourthGroupDelay, async () => {
        let accessToken = await getTokenIfNecessary();
        let options: any = {
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
        }
        options.headers["x-api-key"] = process.env.REACT_APP_EVENTS_API_KEY;
        options.body = JSON.stringify([{
            segment_id: 0,
            config: {
                "life_1": 1,
                "life_2": 1,
                "life_3": 4,
                "max_angle": 60,
                "max_speed": 100,
                "speed_acc": 3,
                "start_speed": 10,
                "points_per_second": 10,
                "created_at": new Date()
            }
        }])
        await post(`${process.env.REACT_APP_HELIKA_API_URL}/v1/game/config`, options);
    })

    await helikaStreaming(fourthGroupDelay, async () => {
        await chatController?.addMessage({
            type: 'text',
            content: [
                {
                    content: texts[9],
                    type: 'text',
                    delay: 1000
                },
                {
                    content: texts[10],
                    type: 'text',
                    delay: delays[4]
                }
            ],
            self: false,
            avatar: HELIKA_LOGO_URL
        })
    })

    await helikaStreaming(fifthGroupDelay, async () => {
        await chatController?.addMessage({
            type: 'text',
            content: [
                {
                    content: texts[11],
                    type: 'text',
                },
            ],
            self: false,
            avatar: HELIKA_LOGO_URL
        })
    })
    setScriptState(3)
}


async function gameState(chatController: any, setLlmState: any, postWithAccessToken: any, getWithAccessToken: any, setSgs: any, getTokenIfNecessary: any, setScriptState: any) {

    await helikaStreaming(1000, async () => {
        await chatController?.addMessage({
            type: 'text',
            content: [
                {
                    content: `Here's some information about your game:`,
                    type: 'text',
                },
                {
                    content: 'Created At: 2024-01-14',
                    type: 'text',
                },
                {
                    content: 'Platform: Web',
                    type: 'text',
                },
                {
                    content: 'Org: Helika',
                    type: 'text',
                },
                {
                    content: 'Org ID: 15',
                    type: 'text',
                },
                {
                    content: 'Link:',
                    type: 'text',
                },
                {
                    type: 'jsx',
                    content: <a style={{ color: newColor.jazzberryJam }} className='text-helikaColor' rel='noreferrer' target="_blank" href='https://link.helika.io/gdc-2024/game-demo-qr'>https://link.helika.io/gdc-2024/game-demo-qr</a>

                }
            ],
            self: false,
            avatar: HELIKA_LOGO_URL
        })
    })
    setScriptState(4)
}


async function getSummary(chatController: any, setLlmState: any, setScriptState: any) {

    let delay = randomDelayGenerator('short')

    let texts = [
        'Summary of our metric optimization experiment:',
        'The experiment has been running for 00d 00h 10m',
        'A total of 20 players are in the experiment group',
        'We have observed a 67% increase in the target metric (ARPU) as a result of our configuration changes.',
        'How would you like to proceed?'
    ]

    await helikaStreaming(1000, async () => {
        await chatController?.addMessage({
            type: 'text',
            content: [
                {
                    content: texts[0],
                    type: 'text',
                    delay: delay
                },
                {
                    content: texts[1],
                    type: 'text',
                },
                {
                    content: texts[2],
                    type: 'text',
                },
                {
                    content: texts[3],
                    type: 'text',
                },
                {
                    content: texts[4],
                    type: 'text',
                },
            ],
            self: false,
            avatar: HELIKA_LOGO_URL
        })
    })
    await helikaStreaming(delay + _.sum(texts.map(item => item.length * perLetterDelay)) + 3000, async () => {
        const selection = await chatController?.setActionRequest({
            type: 'select',
            options: [
                {
                    value: {
                        content: 'End Experiment',
                        type: 'text'
                    },
                    text: 'End Experiment',
                },
                {
                    value: {
                        content: 'Review Changes',
                        type: 'text'
                    },
                    text: `Review Changes`,
                }]
        })
        await chatController?.addMessage({
            type: 'text',
            content: [
                {
                    content: selection?.content[0].content.localeCompare('End Experiment') === 0 ? 'You have ended the experiment' : 'Continuing experiment...',
                    type: 'text'
                }
            ],
            self: false,
            avatar: HELIKA_LOGO_URL
        })
        if (selection?.content[0].content?.localeCompare('End Experiment') === 0) {
            helikaStreaming(1500, async () => {
                await chatController?.addMessage({
                    type: 'text',
                    content: [
                        {
                            content: `Anything else I can help you with?`,
                            type: 'text',
                            delay: delay
                        },
                    ],
                    self: false,
                    avatar: HELIKA_LOGO_URL
                })
            })
        }
        await chatController?.setActionRequest({
            type: 'text',
            always: true,
            placeholder: 'Ask Helika AI anything!',
        });
    })
    setScriptState(4)
}


export async function audienceEmbed(setLlmState: any) {
    helikaThinking(setLlmState, async () => {
        setLlmState({
            type: 'SET_CONTENT',
            payload: {
                domain: 'AUDIENCES',
                type: 'link',
                content:
                    <AudienceEmbed
                    />
                ,
                self: false,
                avatar: HELIKA_LOGO_URL
            }
        })
        setLlmState({
            type: 'SET_MINIMIZED',
        })
    }, randomDelayGenerator('short'))
}

async function helikaThinking(setLlmState: any, after: any, delay?: number) {
    if (setLlmState) {
        setLlmState({
            type: 'SET_CONTENT',
            payload: {
                type: 'link',
                content: <div style={{ height: '5em', width: '5em', margin: 'auto' }}><LoadingComponent /></div>,
                self: false,
                avatar: HELIKA_LOGO_URL
            }
        })
        setLlmState({
            type: 'SET_MINIMIZED',
        })
    }
    if (after) {
        helikaStreaming(delay ? delay : 2000, () => {
            after()
        })
    }
}

async function helikaStreaming(timeout: number, after: any) {

    function doAfterTimeout(time: number) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                after()
                resolve(true);
            }, time);
        });
    }
    await doAfterTimeout(timeout)
}

async function step4(chatController: any, setLlmState: any, getTokenIfNecessary: any, setScriptState: any) {

    let accessToken = await getTokenIfNecessary();
    let options: any = {
        headers: {
            Authorization: `Bearer ${accessToken}`
        },
    }
    options.headers["x-api-key"] = process.env.REACT_APP_EVENTS_API_KEY;
    options.body = JSON.stringify([{
        segment_id: 0,
        config: {
            "life_1": 1,
            "life_2": 1,
            "life_3": 1,
            "max_angle": 60,
            "max_speed": 100,
            "speed_acc": 3,
            "start_speed": 10,
            "points_per_second": 10,
            "created_at": new Date()
        }
    }])
    let resp = await post(`${process.env.REACT_APP_HELIKA_API_URL}/v1/game/config`, options);
    if (resp.status !== 'ok') {
        toast.error('Error ending the experiment. Config post call failed.');
        return
    }
    let summaryText = 'I have confirmed that the experiment has ended, and I will continue to optimize ARPU based on ad impressions, using life count changes to find the best setting.';
    let delay = summaryText.length * perLetterDelay;
    await helikaThinking(setLlmState, async () => {
        helikaStreaming(0, async () => {
            await chatController?.addMessage({
                type: 'text',
                content: [
                    {
                        content: summaryText,
                        type: 'text',
                        delay: summaryText.length * perLetterDelay
                    },
                ],
                self: false,
                avatar: HELIKA_LOGO_URL
            })
        })
    }, 0)
    helikaStreaming(summaryText.length * perLetterDelay, async () => {

        await helikaStreaming(delay, () => {
            setLlmState({
                type: 'SET_CONTENT',
                payload: {
                    type: 'jsx',
                    content:
                        <Goodbye
                            fields={
                                {
                                    content: [
                                        {
                                            content: 'Cori, Luke...',
                                            type: 'text',
                                        },
                                        {
                                            content: `I'll take it from here.`,
                                            type: 'text',
                                            delay: 1500
                                        },
                                        {
                                            content: '',
                                            type: 'space',
                                        },
                                        {
                                            content: `Thank you GDC attendees,`,
                                            type: 'text',
                                            delay: 300
                                        },
                                        {
                                            content: `for coming to my show.`,
                                            type: 'text'
                                        },
                                        {
                                            content: '',
                                            type: 'space',
                                        },
                                        {
                                            content: `To learn more about me`,
                                            type: 'text',
                                            delay: 200
                                        },
                                        {
                                            content: `please visit:`,
                                            type: 'text'
                                        },
                                        {
                                            content: '',
                                            type: 'space',
                                        },
                                        {
                                            content: `helika.io/helika-ai                                            `,
                                            color: newColor.jazzberryJam,
                                            type: 'text',
                                        }
                                    ]
                                }
                            }
                            timeout={2000}
                        />
                }
            })
        })
        setScriptState(1)
    })
}