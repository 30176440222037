import React, { FC } from 'react'
import { Stack, Typography } from '@mui/material'
// project import
import CreateButton from './CreateButton'
import { HelikaTypographies } from 'src/consts/fonts'
import { HelikaSpacings } from 'src/consts/spacings'
// assets
import InboxEmptyIcon from 'src/assets/inbox_empty.svg'

interface INoDataAvailableProps {
  title: string
  description: string
  buttonText: string
  onCreate: () => void
}

const NoDataAvailable: FC<INoDataAvailableProps> = ({
  title,
  description,
  buttonText,
  onCreate,
}) => {
  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      gap={HelikaSpacings.md}
      sx={{
        width: '100%',
        py: HelikaSpacings.xxxl,
      }}
    >
      <img
        src={InboxEmptyIcon}
        alt="No Data Illustration"
        style={{ width: '90px', height: '90px' }}
      />
      <Typography sx={HelikaTypographies['label-large']}>{title}</Typography>
      <Typography sx={HelikaTypographies['paragraph-small']}>
        {description}
      </Typography>
      <CreateButton showIcon={true} text={buttonText} onClick={onCreate} />
    </Stack>
  )
}

export default NoDataAvailable
