import React, { useContext, useEffect, useState } from 'react';
import { newColor } from '../../consts/colors'
import { Box, Grid, MenuItem, Select, useTheme } from '@mui/material';
import CircularPageHeader from '../atoms/v2/circularPageHeader';
import { StyledSvg } from '../organisms/v2/tabs/styled';
import { ReactComponent as Segment } from 'src/assets/segment.svg'
import moment from 'moment';
import { Auth0Context } from 'src/contexts/Auth0Context';
import { getGameConfig } from 'src/utils/api/queries';
import _ from 'lodash';

export function ConfigChanges({ fields }: { fields?: any }): React.ReactElement {

  const theme: any = useTheme();
  const { getWithAccessToken } = useContext(Auth0Context)

  const [segment, setSegment] = useState<string>('Shrimp');
  const [initialConfig, setInitialConfig] = useState<any>();

  useEffect(() => {
    async function getLatestConfig() {
      if (!getWithAccessToken) return
      let resp = await getGameConfig(getWithAccessToken, {
        helika_api_key: process.env.REACT_APP_EVENTS_API_KEY,
        segment_ids: ["0"]
      })
      if (!resp || !resp.res || _.isEmpty(resp.res)) return;
      setInitialConfig(resp.res[0])
    }

    getLatestConfig();
  }, [getWithAccessToken])

  const configValues = [
    {
      text: 'life_milestone_1',
      tags: ['revenue', 'retention'],
      previousValue: '1',
      newValue: '1'
    },
    {
      text: 'life_milestone_2',
      tags: ['revenue', 'retention'],
      previousValue: '1',
      newValue: '1'
    },
    {
      text: 'life_milestone_3',
      tags: ['revenue', 'retention'],
      previousValue: '1',
      newValue: '4'
    },
    {
      text: 'max_angle',
      tags: ['engagement'],
      previousValue: '60',
      newValue: '60'
    },
    {
      text: 'start_speed',
      tags: ['engagement'],
      previousValue: '10',
      newValue: '10'
    },
    {
      text: 'max_speed',
      tags: ['engagement'],
      previousValue: '100',
      newValue: '100'
    }
  ]

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'left',
        margin: 'auto',
        flex: 1,
        padding: '1em',
        minWidth: '65em',
        gap: '1em'
      }}
    >

      <CircularPageHeader
        text="CONFIGURATION MANAGER"
        sx={{ padding: 0, paddingLeft: '1em' }}
      />

      <Box
        sx={{
          borderRadius: '0.5em',
          display: 'flex',
          flexDirection: 'column',
          background: theme.palette.mode === 'dark' ? newColor.midnight : 'white',
          borderStyle: 'solid',
          borderColor: newColor.outerSpace,
          borderWidth: '1px',
          padding: '1em'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <div style={{
              padding: '0 1em 0 1em', color: '#8BE1C9',
              fontSize: '1.5em'
            }}>
              ARPU Optimization
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
              }}
            >
              <div style={{ padding: 'auto 1em auto 1em', fontSize: '1em' }}>Last Updated {initialConfig ? moment.utc(initialConfig?.created_at).utcOffset(moment().utcOffset()).format('YYYY-MM-DD hh:mm A') : moment(new Date()).format('YYYY-MM-DD hh:mm A')}</div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'center'
              }}
            >
              <button style={{ minWidth: '10em', margin: '0 2em 0 2em', padding: '0 1em 0 1em !important' }} className='llm-button-gradient llm-button-gradient-small'>LOGS</button>
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            paddingLeft: '1.5em'
          }}
        >
          Summary
        </div>

        <Grid
          container
          style={{ width: '100%', padding: '1em 2em 1em 2em', height: 'fit-content' }}
        >
          <Grid item xs={8} style={{ display: 'flex', flexDirection: 'row', textAlign: 'left', lineHeight: '1em', margin: 'auto 0 auto 0' }}>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center'
              }}
            >
              <StyledSvg
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Segment style={{ paddingRight: '0.2em' }} />
              </StyledSvg>
              <div
                style={{ paddingLeft: '0.2em', paddingTop: '0.15em' }}
              >
                SEGMENT
              </div>
            </div>
          </Grid>
          <Grid item xs={4} style={{ padding: '0 1em 0 0.5em', textAlign: 'center' }}>
            <Select
              id="segment-selector"
              value={segment}
              size="small"
              onChange={(event: any) =>
                setSegment(event.target.value as string)
              }
              style={{ height: '2em', width: '100%' }}
            >
              <MenuItem value={'Shrimp'}>Shrimp</MenuItem>
              <MenuItem value={'Dolphin'}>Dolphin</MenuItem>
              <MenuItem value={'Whale'}>Whale</MenuItem>
            </Select>
          </Grid>
        </Grid>

        <Grid
          container
          style={{ width: '100%', padding: '1em 2em 1em 2em', height: 'fit-content' }}
        >
          <Grid item xs={5} style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', lineHeight: '1em', margin: 'auto 0 auto 0' }}>

            {
              configValues.map((configValue: any) => {
                return (
                  <div
                    key={`config_changes_${configValue.text}`}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      height: '3em',
                      fontWeight: 700,
                      color: 'white'
                    }}
                  >
                    <div
                      style={{
                        width: '10em',
                        height: 'fit-content',
                        margin: 'auto 0 auto 0'
                      }}
                    >
                      {configValue.text}:
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '1em'
                      }}
                    >
                      {
                        configValue.tags.map((tag: any) => {
                          return (
                            <div
                              key={`tag_${tag}`}
                              style={{
                                background: tag === 'revenue' ? '#FFA629' : (tag === 'retention' ? '#0D99FF' : '#D429FF'),
                                color: 'black',
                                height: 'fit-content',
                                margin: 'auto 0 auto 0',
                                padding: '0.3em 0.5em 0.3em 0.5em',
                                borderRadius: '0.5em'
                              }}
                            >
                              {tag}
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                )
              })
            }
          </Grid>
          <Grid item xs={7} style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', lineHeight: '1em', margin: 'auto 0 auto 0', background: 'gray', borderRadius: '1em' }}>

            {
              configValues.map(configValue => {
                return (
                  <Grid
                    key={`${configValue.newValue}_newValue_${configValue.text}`}
                    container
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      height: '3em',
                      fontWeight: 700,
                      color: 'white'
                    }}
                  >
                    <Grid item xs={5.5} style={{ padding: '0 1em 0 0.5em', textAlign: 'center', height: 'fit-content', margin: 'auto 0 auto 0' }}>

                      <input
                        type="text"
                        style={{
                          minWidth: '10em',
                          width: '100%',
                          maxWidth: '20em',
                          backgroundColor: theme.palette.background.default,
                          color: theme.palette.text.primary,
                          borderRadius: '100vmax',
                          display: 'flex',
                          padding: '0.5em 1em 0.5em 1em',
                          border: 'solid',
                          borderWidth: '1px',
                          borderColor: newColor.gunMetal,
                          outline: '1px white',
                          boxShadow: 'inset 0px'
                        }}
                        defaultValue={configValue.previousValue}
                      />
                    </Grid>
                    <Grid item xs={1} style={{ padding: '0 1em 0 0.5em', textAlign: 'center', height: 'fit-content', margin: 'auto 0 auto 0', color: 'black' }}>
                      {`->`}
                    </Grid>
                    <Grid item xs={5.5} style={{ padding: '0 1em 0 0.5em', textAlign: 'center', height: 'fit-content', margin: 'auto 0 auto 0' }}>

                      <input
                        type="text"
                        style={{
                          minWidth: '10em',
                          width: '100%',
                          maxWidth: '20em',
                          backgroundColor: configValue.previousValue !== configValue.newValue ? 'green' : theme.palette.background.default,
                          color: theme.palette.text.primary,
                          borderRadius: '100vmax',
                          display: 'flex',
                          padding: '0.5em 1em 0.5em 1em',
                          border: 'solid',
                          borderWidth: '1px',
                          borderColor: newColor.gunMetal,
                          outline: '1px white',
                          boxShadow: 'inset 0px'
                        }}
                        defaultValue={configValue.newValue}
                      />
                    </Grid>
                  </Grid>
                )
              })
            }
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          borderRadius: '0.5em',
          display: 'flex',
          flexDirection: 'column',
          background: theme.palette.mode === 'dark' ? newColor.midnight : 'white',
          borderStyle: 'solid',
          borderColor: newColor.outerSpace,
          borderWidth: '1px',
          padding: '1em'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <div style={{
              padding: '0 1em 0 1em', color: '#8BE1C9',
              fontSize: '1.5em'
            }}>
              Season 3 Loot Updates
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
              }}
            >
              <div style={{ padding: 'auto 1em auto 1em', fontSize: '1em' }}>Last Updated {moment(new Date('2024-03-10 8:43:32')).format('YYYY-MM-DD h:mm:ss A')}</div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'center'
              }}
            >
              <button style={{ minWidth: '10em', margin: '0 2em 0 2em', padding: '0 1em 0 1em !important' }} className='llm-button-gradient llm-button-gradient-small'>LOGS</button>
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            paddingLeft: '1.5em'
          }}
        >
          Summary
        </div>
      </Box>
      <Box
        sx={{
          borderRadius: '0.5em',
          display: 'flex',
          flexDirection: 'column',
          background: theme.palette.mode === 'dark' ? newColor.midnight : 'white',
          borderStyle: 'solid',
          borderColor: newColor.outerSpace,
          borderWidth: '1px',
          padding: '1em'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <div style={{
              padding: '0 1em 0 1em', color: '#8BE1C9',
              fontSize: '1.5em'
            }}>
              Arena Rewards Distribution
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
              }}
            >
              <div style={{ padding: 'auto 1em auto 1em', fontSize: '1em' }}>Last Updated {moment(new Date('2024-02-25 14:12:08')).format('YYYY-MM-DD h:mm:ss A')}</div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'center'
              }}
            >
              <button style={{ minWidth: '10em', margin: '0 2em 0 2em', padding: '0 1em 0 1em !important' }} className='llm-button-gradient llm-button-gradient-small'>LOGS</button>
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            paddingLeft: '1.5em'
          }}
        >
          Summary
        </div>
      </Box>
    </div >
  )
}