import { Divider, FormControlLabel, Pagination, Stack } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { Button, ErrorBanner, Select, Spacing, Textfield } from 'helika-ui-sdk'
import { ReactComponent as InboxEmpty } from 'src/assets/inbox_empty.svg'
import { StyledSvg } from "src/components/agGrid/SupportDataGridHeaderComponent";
import { lightPalette, newColor } from "src/consts/colors";
import React from "react";
import { MOTDCampaignHeaders, MOTDCampaignMessageHeaders } from "src/utils/agGrid";
import {
    DataGridPro,
} from '@mui/x-data-grid-pro'
import {
    styled as muiStyled,
} from '@mui/material'
import HelikaSearchBar from "src/components/HelikaSearchBar";
import { getCampaignMOTDMessages, getMOTDCampaign } from "src/utils/api/queries";
import { Auth0Context } from "src/contexts/Auth0Context";
import { toast } from "react-toastify";
import LoadingComponent from "src/components/LoadingComponent";
import { color_base, colors_semantic } from "helika-ui-sdk/dist/src/values/Colors";
import ReusableSnackbar from "../RedemptionCode/components/ReusableSnackbar";
import CreateMOTDCampaignModal, { Android12Switch } from "src/components/popups/CreateMOTDCampaignModal";
import EditMOTDCampaignModal from "src/components/popups/EditMOTDCampaignModal";
import { useLocation } from "react-router-dom";
import { spacing } from "helika-ui-sdk/dist/src/values/Spacing";
import SidePanel from "./SidePanel";
import { radii } from "helika-ui-sdk/dist/src/values/Radii";
import Breadcrumb from "./Breadcrumb";
import { typography } from "helika-ui-sdk/dist/src/values/Typography";
import { formatImageUrl } from "src/components/popups/UploadImageComponent";

export default function MessagePage() {

    const theme = useTheme()
    const location = useLocation()
    const path = location.pathname?.split('/')
    const campaign_slug = path[4]
    const { getWithAccessToken } = useContext(Auth0Context)
    const [campaign, setCampaign] = useState<any>(undefined)
    const [messages, setMessages] = useState<any[]>([])
    const [filteredCampains, setFilteredCampaigns] = useState<any[]>([])
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [editMessageMode, setEditMessageMode] = useState(false)
    const [openCreateCampaignModal, setOpenCreateCampaignModal] = useState<boolean>(false)
    const [openEditCampaignModal, setOpenEditCampaignModal] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [missingDetails, setMissingDetails] = useState(false)
    const [messageName, setMessageName] = useState<string>('')
    const [isActive, setIsActive] = useState<boolean>(false)
    const [image, setImage] = useState<any>()
    const [storedImageId, setStoredImageId] = useState<any>()
    const [audiences, setAudiences] = useState<any[]>(['Audience 1', 'Audience 2'])
    const [selectedAudience, setSelectedAudience] = useState<any>(undefined)
    const [audienceSearchTerm, setAudienceSearchTerm] = useState<string>('')
    const [snackbarMessage, setSnackbarMessage] = useState<string>('')
    const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>(
        'success',
    )
    // const [sort, setSort] = useState<string>('Created At Desc')
    // pagination
    const [pageSize, setPageSize] = useState<string>('10')
    const [pageNumber, setPageNumber] = useState<number>(1)
    const handleAudienceChange = (
        event: React.ChangeEvent<any>,
    ) => {
        if (event?.target?.value === 'Select audience') {
            setSelectedAudience(undefined)
            return;
        }
        setSelectedAudience(event?.target?.value)
    }

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMessageName(event.target.value)
    }

    const handlePageSizeChange = (
        event: React.ChangeEvent<HTMLSelectElement>,
    ) => {
        setPageSize(event.target.value)
    }

    //get campaign
    useEffect(() => {
        async function getCampaignMessages() {
            setLoading(true)
            const campaign_data = {
                id: 1,
                name: 'Campaign 1'
            }
            // const campaign_data = await getMOTDCampaign(getWithAccessToken, campaign_slug).catch((e: any) => {
            //     toast.error(e)
            //     setLoading(false)
            // })
            console.log('111')
            if (_.isEmpty(campaign_data)) {
                setLoading(false)
                return;
            }
            console.log('222')
            setCampaign(campaign_data)

            console.log('333')
            // const data = await getCampaignMOTDMessages(getWithAccessToken, campaign_data.id).catch((e: any) => {
            //     toast.error(e)
            //     setLoading(false)
            // })
            // if (data?.results?.messages) {
            //     setMessages(data?.results?.messages)
            // }
            console.log('got here')
            setMessages([
                {
                    id: 1,
                    name: 'Message1'
                },
                {
                    id: 2,
                    name: 'Message2'
                }
            ])
            setLoading(false)
        }
        if (!campaign_slug) return
        getCampaignMessages()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    console.log('campaign', campaign)

    useEffect(() => {
        setLoading(true)
        let updatedList = messages
        if (!_.isEmpty(searchTerm?.trim())) {
            updatedList = messages?.filter(message => message?.title?.toLocaleLowerCase()?.trim()?.includes(searchTerm?.toLocaleLowerCase()?.trim()))
        }
        // if (!_.isEmpty(updatedList)) {
        //     switch (sort) {
        //         case 'Created At Asc':
        //             updatedList = [...updatedList]?.sort((a: any, b: any) =>
        //                 a.created_at > b.created_at ? 1 : -1,
        //             )
        //             break;
        //         case 'Created At Desc':
        //         default:
        //             updatedList = [...updatedList]?.sort((a: any, b: any) =>
        //                 a.created_at < b.created_at ? 1 : -1,
        //             )

        //     }
        // }

        //sort by updated_ay date
        let newUpdatedList = [...updatedList]
        if (!_.isEmpty(updatedList)) {
            newUpdatedList = [...updatedList]?.sort((a: any, b: any) =>
                a?.updated_at?.toString() < b?.updated_at?.toString() ? 1 : -1,
            )
        }

        setFilteredCampaigns(newUpdatedList)
        setLoading(false)
    }, [messages, pageNumber, pageSize, searchTerm])

    async function onCreateMessage(newMessage: any) {
        setMessages((prevState: any[]) => {
            if (_.isEmpty(prevState)) return [newMessage]
            return prevState.concat(newMessage)
        })
        setSnackbarMessage('Message created')
        setSnackbarVariant('success')
        setSnackbarOpen(true)
    }

    async function onEditMessage(editedMessage: any) {
        setMessages((prevState: any[]) => {
            if (_.isEmpty(prevState)) return [editedMessage]
            return prevState.map(message => {
                if (message.id === editedMessage.id) return editedMessage
                return message
            })
        })
        setSnackbarMessage('Message edited')
        setSnackbarVariant('success')
        setSnackbarOpen(true)
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: 'fit-content',
                height: 'fit-content',
                maxHeight: 'calc(100vh - 11em)',
                gap: '1em',
                background: theme.palette.mode === 'dark' ? colors_semantic.background_primary : lightPalette.bgPage,
            }}
            className="open-sans-fontFam400"
        >
            <Stack
                direction={'row'}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        height: '100%',
                        minWidth: '450px',
                        padding: `${spacing.Spacing_sm} ${spacing.Spacing_xl} ${spacing.Spacing_sm} ${spacing.Spacing_xl}`
                    }}
                >

                    <Breadcrumb
                        items={[
                            { string: 'Message of the Day', isLink: true, link: `${process.env.REACT_APP_AUTH0_BASE_URL}/game_management/motd/campaigns` },
                            { string: 'Campaigns', isLink: true, link: `${process.env.REACT_APP_AUTH0_BASE_URL}/game_management/motd/campaigns` },
                            { string: campaign?.name }
                        ]}
                    />
                    <Box
                        sx={{
                            ...typography.heading2,
                            color: !_.isEmpty(messageName) ? colors_semantic.text_primary : colors_semantic.text_disabled,
                            padding: `${spacing.Spacing_xxs} ${spacing.Spacing_xl} ${spacing.Spacing_sm} 0`
                        }}
                    >
                        {
                            !_.isEmpty(messageName)
                                ? messageName
                                : 'Untitled'

                        }
                    </Box>
                    <Divider
                        sx={{
                            marginBottom: spacing.Spacing_lg,
                        }}
                    />
                    {
                        (image || storedImageId)
                            ?
                            <img
                                src={(image && image.size !== 0) ? URL.createObjectURL(image) : `${formatImageUrl(storedImageId)}`}
                                style={{
                                    borderRadius: '5px',
                                    height: '100%',
                                    width: '100%',
                                    objectFit: 'cover',//contain
                                }}
                                alt='uploaded_image'
                            />
                            : <Box
                                sx={{
                                    margin: 'auto',
                                    color: colors_semantic.text_secondary,
                                    ...typography.label_large
                                }}
                            >
                                No Background Image
                            </Box>
                    }
                </Box>
                <SidePanel
                    darkMode={theme.palette.mode === 'dark'}
                    title={'Settings'}
                    dismissible={true}
                    show={true}
                    setShow={() => { }}
                    onClickClear={() => { }}
                    hasCancelButton={true}
                    hasActionButton={true}
                    cancelButtonFunction={() => { }}
                    actionButtonFunction={() => { }}
                    actionButtonTitle={'Save'}
                    actionButtonDisabled={loading || _.isEmpty(messageName?.trim())}
                    cancelButtonDisabled={loading}
                >
                    <Stack
                        direction={'column'}
                        padding={0}
                    >

                        <Stack
                            direction={'column'}
                            gap={spacing.Spacing_sm}
                        >
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    // padding: `${Spacing.Spacing_lg} ${Spacing.Spacing_lg} ${Spacing.Spacing_lg} ${Spacing.Spacing_lg}`,
                                }}
                            >
                                <Box
                                    sx={{
                                        fontSize: '20px',
                                        lineHeight: '140%',
                                        fontWeight: 600,
                                    }}
                                >
                                    Create Message
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: spacing.Spacing_md,
                                    // padding: `${Spacing.Spacing_lg}`
                                }}
                            >
                                <ErrorBanner
                                    show={missingDetails && (_.isEmpty(messageName))}
                                    errorMessage='Fill out missing message details.'
                                />
                                <Textfield
                                    darkMode={theme.palette.mode === 'dark'}
                                    label={'Name'}
                                    value={messageName}
                                    onChange={handleNameChange}
                                    hasError={(missingDetails && _.isEmpty(messageName?.trim()))}
                                    placeHolder='Untitled'
                                />
                                <Stack direction="row" gap={1}>
                                    <FormControlLabel
                                        control={
                                            <Android12Switch
                                                checked={isActive}
                                                onClick={() => {
                                                    setIsActive(!isActive)
                                                }}
                                            />}
                                        label={
                                            <Box
                                                sx={{
                                                    padding: 0,
                                                    fontFamily: "Open Sans",
                                                    fontSize: '14px',
                                                    fontStyle: 'normal',
                                                    fontWeight: 400,
                                                    lineHeight: '140%', /* 19.6px */
                                                }}
                                            >
                                                Message status is {isActive ? 'Active' : 'Inactive'}
                                            </Box>}
                                    />
                                </Stack>
                            </Box >
                        </Stack>

                        <Divider
                            sx={{
                                // padding: `${spacing.Spacing_lg} 0 ${spacing.Spacing_lg} 0`
                                marginTop: spacing.Spacing_lg,
                                marginBottom: spacing.Spacing_lg,
                            }}
                        />

                        <Stack
                            direction={'column'}
                            style={{
                                justifyContent: 'space-between',
                                gap: spacing.Spacing_sm
                            }}
                        >
                            <Box
                                sx={{
                                    fontSize: '20px',
                                    lineHeight: '140%',
                                    fontWeight: 600,
                                }}
                            >
                                Background Image
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    width: '100%',
                                    height: '100%',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: spacing.Spacing_xxs,
                                    minHeight: '250px',
                                    borderRadius: radii.md,
                                    border: `1px dashed ${colors_semantic.border_tertiary}`,
                                    background: colors_semantic.background_teriary,
                                }}
                            >

                            </Box>
                        </Stack>

                        <Divider
                            sx={{
                                marginTop: spacing.Spacing_lg,
                                marginBottom: spacing.Spacing_lg,
                            }}
                        />

                        <Stack
                            direction={'column'}
                            gap={spacing.Spacing_sm}
                        >
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Box
                                    sx={{
                                        fontSize: '20px',
                                        lineHeight: '140%',
                                        fontWeight: 600,
                                    }}
                                >
                                    Audience
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: spacing.Spacing_md,
                                    // padding: `${Spacing.Spacing_lg}`
                                }}
                            >
                                <ErrorBanner
                                    show={missingDetails && (_.isEmpty(messageName))}
                                    errorMessage='Fill out missing message details.'
                                />
                                <Select
                                    darkMode={theme.palette.mode === 'dark'}
                                    label={'Target Audience'}
                                    value={selectedAudience}
                                    onChange={handleAudienceChange}
                                    options={audiences?.filter(item => item?.trim()?.toLocaleLowerCase()?.includes(audienceSearchTerm?.trim()?.toLocaleLowerCase()))}
                                    hint="If set, the message will appear only to target audience."
                                    hasSearch={true}
                                    placeHolder={'Select audience'}
                                />
                            </Box >
                        </Stack>

                        <Divider
                            sx={{
                                marginTop: spacing.Spacing_lg,
                                marginBottom: spacing.Spacing_lg,
                            }}
                        />

                        <Stack
                            direction={'column'}
                            gap={spacing.Spacing_sm}
                        >
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    // padding: `${Spacing.Spacing_lg} ${Spacing.Spacing_lg} ${Spacing.Spacing_lg} ${Spacing.Spacing_lg}`,
                                }}
                            >
                                <Box
                                    sx={{
                                        fontSize: '20px',
                                        lineHeight: '140%',
                                        fontWeight: 600,
                                    }}
                                >
                                    Schedule
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: spacing.Spacing_md,
                                }}
                            >
                                <Box
                                    sx={{
                                        width: 'fit-content'
                                    }}
                                >
                                    <Button
                                        type={"Secondary"}
                                    >
                                        Set Schedule
                                    </Button>
                                </Box>
                            </Box >
                        </Stack>

                    </Stack>
                </SidePanel>
            </Stack>
        </Box >
    )
}