import useSWR from 'swr';

import { get } from 'src/utils/api/frontend';

export const useGetLeaderboard = (accessToken: any, apiKey: any) => {

  const { data, error, isLoading } = useSWR<any>(
    {
      url: `${process.env.REACT_APP_HELIKA_API_URL}/v1/game/leaderboard`,
      options: {
        headers: {
          authorization: `Bearer ${accessToken}`,
          'x-api-key': apiKey
        }
      },
    },
    ({ url, options }) => get(url, options),
    {
      refreshInterval: 5000
    }
  );

  if (!accessToken) return {
    leaderboards: [],
    loadingLeaderboards: false,
    errorLeaderboards: 'No access to retrieve leaderboards'
  }

  return data
    ? {
      loadingLeaderboards: isLoading,
      errorLeaderboards: error,
      leaderboards: data
    }
    : {
      loadingLeaderboards: isLoading,
      errorLeaderboards: error,
    };
};
