import dayjs from 'dayjs';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { ChatController } from 'src/utils/chat/src';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from 'src/state';
import { getHelikaAiResponse } from 'src/utils/helikaAi';
import { ChatMessage } from './ChatMessage';
import { Auth0Context } from 'src/contexts/Auth0Context';

export function MessagesList({
  chatController,
  suggestions,
  setActReq,
  setSgs
}: React.PropsWithChildren<{
  chatController: ChatController,
  suggestions?: any;
  setActReq: any;
  setSgs: any
}>): React.ReactElement {

  const dispatch = useDispatch()
  const { postWithAccessToken, getWithAccessToken, getTokenIfNecessary } = useContext(Auth0Context)
  const { setLlmState } = bindActionCreators(actionCreators, dispatch)
  const [messages, setMessages] = useState(chatController?.getMessages());
  const [hasOverflow, setHasOverflow] = useState(false);
  const [isAtTop, setIsAtTop] = useState(false);
  const [scriptState, setScriptState] = useState(1);

  const msgRef = useRef<HTMLDivElement>(null)
  const scroll = useCallback((): void => {
    setTimeout(() => {
      if (msgRef.current) {
        msgRef.current.scrollTop = msgRef.current.scrollHeight
      }
    }, 100)
  }, [msgRef])


  useEffect(() => {

    function handleMassagesChanged(): void {
      let newMessages = [...chatController.getMessages()]
      setMessages(newMessages)
    }
    function handleActionChanged(): void {
      setActReq(chatController.getActionRequest())
      scroll()
    }

    if (!chatController) return

    chatController.addOnMessagesChanged(handleMassagesChanged)
    chatController.addOnActionChanged(handleActionChanged)

    scroll();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatController]);

  useEffect(() => {
    scroll();
  }, [messages?.length, scroll]);

  useEffect(() => {
    getHelikaAiResponse(chatController, messages, setLlmState, setSgs, suggestions, postWithAccessToken, getWithAccessToken, getTokenIfNecessary, scriptState, setScriptState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatController, messages]);


  function showMessages() {
    return messages.map((msg: any): React.ReactElement => {
      let showDate = false;
      let showTime = !!chatController.getOption().showDateTime;
      if (!!chatController.getOption().showDateTime && !msg.deletedAt) {
        const current = dayjs(
          msg.updatedAt ? msg.updatedAt : msg.createdAt,
        );

        if (current.format('YYYYMMDD') !== prevDate.format('YYYYMMDD')) {
          showDate = true;
        }
        prevDate = current;

        if (current.diff(prevTime) < 60_000) {
          showTime = false;
        } else {
          prevTime = current;
        }
      }
      if (msg.showEvenWithContext !== true && msg.type !== 'text') {
        return <div
          key={messages.indexOf(msg)}
        />
      }
      if (['jsx', 'chart', 'text', 'widget', 'button'].includes(msg.type)) {
        return (
          <ChatMessage
            key={messages.indexOf(msg)}
            id={`cu-msg-${messages.indexOf(msg) + 1}`}
            message={msg}
            showDate={showDate}
            showTime={showTime}
            scroll={scroll}
          />
        );
      }
      return (
        <ChatMessage
          key={messages.indexOf(msg)}
          id={`cu-msg-${messages.indexOf(msg) + 1}`}
          message={unknownMsg}
          showDate={showDate}
          showTime={showTime}
          scroll={scroll}
        />
      );
    })
  }


  const unknownMsg = {
    type: 'text',
    content: 'Unknown message.',
    self: false,
  };

  let prevDate = dayjs(0);
  let prevTime = dayjs(0);

  const checkIsTop = (): boolean => {
    if (msgRef.current) {
      return msgRef.current.scrollTop === 0;
    }
    return false;
  };

  const scrollTop = useCallback((): void => {
    if (msgRef.current) {
      msgRef.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }, [msgRef]);

  const checkOverflow = (): boolean => {
    if (msgRef.current) {
      return (
        msgRef.current.offsetHeight < msgRef.current.scrollHeight || msgRef.current.offsetWidth < msgRef.current.scrollWidth
      );
    }
    return false;
  };

  useEffect(() => {
    setHasOverflow(checkOverflow())
  }, [messages])

  return (
    <Box
      ref={msgRef}
      sx={{
        overflow: 'auto',
        position: 'relative'
      }}
      onScroll={() => {
        setIsAtTop(checkIsTop())
      }}
    >
      {
        hasOverflow && !isAtTop &&
        <div
          style={{
            position: 'sticky',
            width: 'fit-content',
            top: '0.5em',
            margin: '0 auto 0.5em auto',
            padding: '0.2em 2em 0.2em 2em',
            borderRadius: '100vmax',
            background: 'linear-gradient(90deg, #8BE1C9 17.2%, #9747FF 87.63%)',
            color: 'black',
            cursor: 'pointer',
            zIndex: 10,
            fontSize: '0.7em'
          }}
          onClick={scrollTop}
        >
          Scroll to Top
        </div>
      }
      {
        showMessages()
      }
    </Box>
  );
}