import React, { useState, useContext, useEffect } from 'react'
import { Stack, Typography, TableCell, useTheme, Box } from '@mui/material'
import SearchInput from './components/SearchInput'
import ReusableTable from './components/ReusableTable'
import CampaignModal from './components/CampaignModal'
import StatusRow from './components/StatusRow'
import { ICampaign } from 'src/types/engage'
import {
  getCampaignsList,
  updateCampaign,
  updateBulkCampaigns,
} from 'src/utils/api/queries'
import { Auth0Context } from 'src/contexts/Auth0Context'
import CreateHookButton from './components/CreateHookButton'
import SortButton from './components/SortButton'
import FilterButton from './components/FilterButton'
import LoadingComponent from 'src/components/LoadingComponent'
import ReusableSnackbar from './components/ReusableSnackbar'
import dayjs from 'dayjs'
import { blackAndWhite, newColor } from 'src/consts/colors'
import { useNavigate } from 'react-router-dom'

const ProjectDetail: React.FC = () => {
  const {
    getWithAccessToken,
    putWithAccessToken,
    patchWithAccessToken,
    isLoading: authLoading,
  } = useContext(Auth0Context)
  const theme = useTheme()
  const navigate = useNavigate()

  // Snackbar state management
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState<string>('')
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>(
    'success',
  )

  const [campaigns, setCampaigns] = useState<ICampaign[]>([])
  const [pagination, setPagination] = useState({ page: 1, limit: 10, count: 0 })
  const [filteredDataItems, setFilteredDataItems] = useState<ICampaign[]>([])

  // States for managing sorting, filtering, and searching
  const [sortField, setSortField] = useState<string>('updated_at')
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc')
  const [filters, setFilters] = useState<any[]>([])
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [tableLoading, setTableLoading] = useState<boolean>(false)

  const [openModal, setOpenModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [localCampaignData, setLocalCampaignData] = useState<
    ICampaign | undefined
  >(undefined)

  const formatDateToISO = (date: string | Date) => dayjs(date).toISOString()

  const handleRowClick = (campaign: ICampaign) => {
    navigate(`/game_management/redemption_codes/campaigns/${campaign.id}`, {
      state: {
        id: campaign.id,
        name: campaign.name,
        startDate: campaign.startDate,
        endDate: campaign.endDate,
        status: campaign.status,
      },
    })
  }

  const handleOpenCreateModal = () => {
    setIsEdit(false)
    setLocalCampaignData(undefined)
    setOpenModal(true)
  }

  const handleOpenEditModal = (campaign: ICampaign) => {
    setIsEdit(true)
    setLocalCampaignData(campaign)
    setOpenModal(true)
  }

  const handleCloseModal = (refreshTable: boolean = false) => {
    setOpenModal(false)
    setLocalCampaignData(undefined)
    if (refreshTable) {
      getCampaigns(
        pagination.page,
        pagination.limit,
        sortField,
        sortOrder,
        searchTerm,
        filters,
      )
    }
  }

  const handleSearch = async (term: string) => {
    setSearchTerm(term)
    setPagination((prev) => ({ ...prev, page: 1 }))
    await getCampaigns(1, pagination.limit, sortField, sortOrder, term, filters)
  }

  const handleSortChange = (field: string, order: 'asc' | 'desc') => {
    setSortField(field)
    setSortOrder(order)
  }

  const handleFilter = (appliedFilters: any[]) => {
    const updatedFilters = appliedFilters.map((filter) => ({
      ...filter,
      by: filter.by.toLowerCase(),
      value: filter.value.toLowerCase().replace(/\s+/g, '_'),
    }))
    setFilters(updatedFilters)
    getCampaigns(
      pagination.page,
      pagination.limit,
      sortField,
      sortOrder,
      searchTerm,
      updatedFilters,
    )
  }

  const refreshTable = async () => {
    setTableLoading(true)
    await getCampaigns(
      pagination.page,
      pagination.limit,
      sortField,
      sortOrder,
      searchTerm,
      filters,
    )
    setTableLoading(false)
  }

  const getCampaigns = async (
    page: number = pagination.page,
    pageSize: number = pagination.limit,
    sortBy: string = sortField,
    direction: string = sortOrder,
    name: string = searchTerm,
    appliedFilters: any[] = filters,
  ) => {
    try {
      setTableLoading(true)
      const response = await getCampaignsList(
        getWithAccessToken,
        page,
        pageSize,
        sortBy,
        direction,
        name,
        appliedFilters,
      )
      if (response?.results?.campaigns) {
        const apiCampaigns = response.results.campaigns.map(
          (campaign: any): ICampaign => ({
            id: campaign.id,
            name: campaign.name,
            startDate: campaign.start_date,
            endDate: campaign.end_date,
            status: campaign.status,
          }),
        )
        setCampaigns(apiCampaigns)
        setFilteredDataItems(apiCampaigns)
        setPagination((prev) => ({
          ...prev,
          count: response.results.count,
        }))
      }
    } catch (error) {
      setSnackbarMessage('Failed to fetch campaigns data')
      setSnackbarVariant('error')
      setSnackbarOpen(true)
    } finally {
      setTableLoading(false)
    }
  }

  const handleArchiveCampaignById = async (campaignId: number) => {
    try {
      const campaign = campaigns.find((item) => item.id === campaignId)
      if (!campaign) throw new Error('Campaign not found')

      if (!campaign.startDate || !campaign.endDate) {
        throw new Error(
          'Start Date and End Date are required to archive a campaign.',
        )
      }

      const formattedStartDate = formatDateToISO(campaign.startDate)
      const formattedEndDate = formatDateToISO(campaign.endDate)

      const payload = {
        id: campaign.id,
        name: campaign.name,
        start_date: formattedStartDate,
        end_date: formattedEndDate,
        status: 'archived',
      }

      setTableLoading(true)
      await updateCampaign(putWithAccessToken, campaign.id, payload)
      setTableLoading(false)

      setSnackbarMessage(`Campaign "${campaign.name}" archived successfully!`)
      setSnackbarVariant('success')
      setSnackbarOpen(true)
      refreshTable()
    } catch (error: any) {
      setSnackbarMessage(error.message || 'Failed to archive campaign')
      setSnackbarVariant('error')
      setSnackbarOpen(true)
      setTableLoading(false)
    }
  }

  const handleBulkArchiveCampaigns = async (selectedCampaignIds: number[]) => {
    try {
      const payload = { ids: selectedCampaignIds, status: 'archived' }
      setTableLoading(true)
      await updateBulkCampaigns(patchWithAccessToken, payload)
      setTableLoading(false)
      setSnackbarMessage('Selected campaigns archived successfully!')
      setSnackbarVariant('success')
      setSnackbarOpen(true)
      refreshTable()
    } catch (error: any) {
      setSnackbarMessage('Failed to archive selected campaigns')
      setSnackbarVariant('error')
      setSnackbarOpen(true)
      setTableLoading(false)
    }
  }

  // useEffect to fetch campaigns when component mounts or pagination changes
  useEffect(() => {
    getCampaigns()
  }, [pagination.page, pagination.limit])

  // useEffect to fetch campaigns when sorting changes
  useEffect(() => {
    getCampaigns(
      pagination.page,
      pagination.limit,
      sortField,
      sortOrder,
      searchTerm,
      filters,
    )
  }, [sortField, sortOrder, searchTerm, filters])

  const handleChangePagination = ({ page }: { page: number }) => {
    setPagination((prev) => ({ ...prev, page }))
  }

  const renderRow = (campaign: ICampaign) => [
    <TableCell key="name">
      <Typography
        sx={{
          fontFamily: 'Open Sans, sans-serif',
          color:
            theme.palette.mode === 'dark' ? newColor.white : newColor.black,
        }}
      >
        {campaign.name}
      </Typography>
    </TableCell>,
    <TableCell key="startDate">
      <Typography
        sx={{
          fontFamily: 'Open Sans, sans-serif',
          color:
            theme.palette.mode === 'dark' ? newColor.white : newColor.black,
        }}
      >
        {dayjs(campaign.startDate).format('YYYY-MM-DD hh:mm A')}
      </Typography>
    </TableCell>,
    <TableCell key="endDate">
      <Typography
        sx={{
          fontFamily: 'Open Sans, sans-serif',
          color:
            theme.palette.mode === 'dark' ? newColor.white : newColor.black,
        }}
      >
        {dayjs(campaign.endDate).format('YYYY-MM-DD hh:mm A')}
      </Typography>
    </TableCell>,
    <TableCell key="status">
      <Box onClick={(event) => event.stopPropagation()}>
        <StatusRow
          campaign={campaign}
          onChangeStatus={(newStatus) => {
            const updatedCampaigns = campaigns.map((item) =>
              item.id === campaign.id ? { ...item, status: newStatus } : item,
            )
            setCampaigns(updatedCampaigns)
            setFilteredDataItems(updatedCampaigns)
            refreshTable()
          }}
          setTableLoading={setTableLoading}
        />
      </Box>
    </TableCell>,
  ]

  return (
    <Stack
      sx={{
        backgroundColor:
          theme.palette.mode === 'dark' ? blackAndWhite.grey900 : 'transparent',
      }}
    >
      {tableLoading || authLoading ? (
        <LoadingComponent />
      ) : (
        <Stack
          direction="column"
          gap={1}
          sx={{
            maxWidth: { xs: '100%', md: '90%' },
            width: '100%',
            height: '100%',
            position: 'relative',
            margin: '0px auto',
            padding: 2,
          }}
        >
          <Stack direction="column" sx={{ py: 2 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              gap={1}
            >
              <Typography
                fontSize={'24px'}
                fontWeight={600}
                sx={{
                  fontFamily: 'Open Sans, sans-serif',
                  color:
                    theme.palette.mode === 'dark'
                      ? newColor.white
                      : newColor.black,
                }}
              >
                Campaigns
              </Typography>
              <CreateHookButton onClick={handleOpenCreateModal} />
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ mt: 2 }}
            >
              <Stack direction="row" gap={2} alignItems="center">
                <SearchInput onSearch={handleSearch} />
                <FilterButton
                  filterOptions={{
                    categories: ['Status'],
                    conditions: ['is', 'is not'],
                    valuesByCategory: {
                      Status: ['Not started', 'Active', 'Finished', 'Archived'],
                    },
                  }}
                  onApplyFilter={handleFilter}
                />
                <SortButton
                  onSortChange={handleSortChange}
                  sortField={sortField}
                  sortOrder={sortOrder}
                />
              </Stack>
            </Stack>
          </Stack>

          <ReusableTable
            hooks={filteredDataItems ?? []}
            pagination={pagination}
            headers={['Name', 'Start Date', 'End Date', 'Status']}
            renderRow={renderRow}
            onChangePagination={handleChangePagination}
            goToEditHook={handleOpenEditModal}
            onArchiveHook={handleArchiveCampaignById}
            onBulkArchive={handleBulkArchiveCampaigns}
            refreshTable={refreshTable}
            handleRowClick={handleRowClick}
          />
        </Stack>
      )}
      <ReusableSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        variant={snackbarVariant}
        handleClose={() => setSnackbarOpen(false)}
      />

      <CampaignModal
        open={openModal}
        onClose={handleCloseModal}
        isEdit={isEdit}
        campaignData={localCampaignData}
        setTableLoading={setTableLoading}
      />
    </Stack>
  )
}

export default ProjectDetail
