import React, { useContext, useState } from 'react'

import {
  Box,
  useTheme,
} from '@mui/material'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import {
  Dialog,
} from '@mui/material'
import { Button, Colors, Spacing } from 'helika-ui-sdk'
import { editMOTDCampaign } from 'src/utils/api/queries'
import { Auth0Context } from 'src/contexts/Auth0Context'
import _, { debounce } from 'lodash'
import CloseIcon from '@mui/icons-material/Close';
import { spacing } from 'helika-ui-sdk/dist/src/values/Spacing'
import ReusableSnackbar from 'src/pages/Modules/GameManagement/RedemptionCode/components/ReusableSnackbar'

export interface SimpleDialogProps {
  open: boolean
  setOpen: any
  onEditCampaign: any
  selectedCampaign: any
}

export interface Dashboard_mapping {
  name: string
  iframe_link: string
}

const ArchiveMOTDCampaignModal = (props: SimpleDialogProps) => {
  const { open, setOpen, onEditCampaign, selectedCampaign } = props
  const theme = useTheme()
  const { getTokenIfNecessary } = useContext(Auth0Context);

  const [loading, setLoading] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState<string>('')
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>(
    'success',
  )

  const handleClose = () => {
    setOpen(false)
  }

  const debouncedClickHandler = debounce(archiveCampaignApi, 200, { maxWait: 500 })

  async function archiveCampaignApi() {

    try {
      if (loading) return
      setLoading(true)

      let offer: any = {
        ...selectedCampaign,
        status: 'archived',
      }

      await editMOTDCampaign(getTokenIfNecessary, {
        offer: offer
      }).then((resp: any) => {
        if (!_.isEmpty(resp?.results?.campaign)) {
          onEditCampaign(resp?.results?.campaign)
          setOpen(false)
          setLoading(false)
        } else {
          throw new Error('Could not archive campaign')
        }
      })
    } catch (e: any) {
      setSnackbarMessage(e?.message)
      setSnackbarVariant('error')
      setSnackbarOpen(true)
      setLoading(false)
    }

  }

  return (
    <Dialog
      disableRestoreFocus
      fullScreen={true}
      className="defaultModalStyle open-sans-fontFam400"
      sx={{
        margin: 'auto',
        borderRadius: '1px !important',
        borderStyle: 'none',
        width: '420px',
        border: 'none',
        background: '#1A000000'
      }}
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          background: theme.palette.mode === 'dark' ? Colors.background.primary : 'white',
          borderStyle: 'none',
          borderColor: '#404040',
          borderWidth: '1px',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '1px !important',
          width: '420px',
          boxShadow: '0px 20px 20px 0px #4D000000 !important'
        },
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: `${Spacing.Spacing_sm} ${Spacing.Spacing_sm} ${Spacing.Spacing_sm} ${Spacing.Spacing_lg}`,
        }}
      >
        <Box
          sx={{
            fontSize: '20px',
            lineHeight: '140%',
            fontWeight: 600,
          }}
        >
          Archive Campaign
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: 'auto 0 auto 0',
            cursor: 'pointer',
            height: '32px',
            width: '32px'

          }}
          onClick={handleClose}
        >
          <CloseIcon
            sx={{
              fontSize: '24px',
              height: '24px',
              width: '24px',
              margin: 'auto'
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: spacing.Spacing_md,
          padding: `${Spacing.Spacing_lg}`
        }}
      >
        {`Are you sure you want to archive the campaign "${selectedCampaign.name}"?`}
      </Box >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          gap: Spacing.Spacing_xxs,
          padding: `${Spacing.Spacing_sm} ${spacing.Spacing_lg}`
        }}
      >
        <Button
          type={theme.palette.mode === 'dark' ? 'Tertiary' : 'Primary'}
          handleClick={() => {
            setOpen(false)
          }}
        >
          Cancel
        </Button>
        <Button
          type='Primary'
          disabled={loading}
          handleClick={debouncedClickHandler}
        >
          Archive
        </Button>
      </Box>
      <ReusableSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        variant={snackbarVariant}
        handleClose={() => setSnackbarOpen(false)}
      />
    </Dialog>
  )
}

export default ArchiveMOTDCampaignModal
