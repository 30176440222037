import { ChangeEvent, useContext, useEffect, useState } from 'react'
import {
  Box,
  styled as muiStyled,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  useTheme,
  Grid,
} from '@mui/material'
import { toast } from 'react-toastify'
import { createAPIKeyApi } from '../../utils/api/queries'
import { Auth0Context } from '../../contexts/Auth0Context'
import ExpandIcon from '../../assets/expand.svg'
import SmartIcon from '../../assets/Smart_Icon.svg'
import loadingSVG from '../../assets/loading.svg'
import SmartIconDark from '../../assets/Smart_Icon_dark.svg'
import { StyledAccordion } from '../AccordionGroup'
import { newColor } from '../../consts/colors'
import CustomSwitch from './customSwitch'

const StyledTextarea = muiStyled('textarea')(({ theme }) => ({
  width: '100%',
  height: '80px',
  borderRadius: '10px',
  backgroundColor: theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.ghostWhite,
  margin: '0.875rem 0',
  color: theme.palette.text.primary,
  padding: '1rem',
  '&:focus, &:hover': {
    outline: newColor.indigo,
    border: `1px solid ${newColor.jazzberryJam};`,
  },
}))

const StyledTxt = muiStyled(Typography)(({ theme }) => ({
  color: 'white',
  fontSize: '14px',
}))
const InputGroup = muiStyled(Box)(({ theme }) => ({
  alignItems: 'left',
  textAlign: 'start',
  marginLeft: '20px',
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
}))
const Input = muiStyled('input')(({ theme }) => ({
  width: '315px',
  height: '28px',
  borderRadius: '20px',
  backgroundColor: theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.ghostWhite,
  margin: '0.875rem 0',
  color: theme.palette.text.primary,
  padding: '0 1rem',
  '&:focus, &:hover': {
    outline: newColor.indigo,
    border: `1px solid ${newColor.jazzberryJam};`,
  },
}))

const SelectCategory = muiStyled('select')(({ theme }) => ({
  width: '315px',
  height: '28px',
  borderRadius: '3px',
  backgroundColor: theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.cultured,
  border: '1px solid ',
  borderColor: theme.palette.mode === 'dark' ? newColor.darkGunmetal : newColor.ghostWhite,
  margin: '0.875rem 0',
  color: theme.palette.text.primary,
  padding: '0 1rem',
  '&:focus, &:hover': {
    outline: newColor.indigo,
    border: `1px solid ${newColor.jazzberryJam};`,
  },
}))

interface Org {
  label: string
  value: number
}

export default function CreateApiKeyOrg({ orgs }: { orgs: Org[] }) {
  const theme = useTheme()
  const { postWithAccessToken } = useContext(Auth0Context)
  const [org, setOrg] = useState<Org | null>(null)
  const [apiKeyName, setApiKeyName] = useState('')
  const [securedStatus, setSecuredStatus] = useState<boolean>(true)
  const [loading, setLoading] = useState(false)
  const [resultPanelData, setResultPanelData] = useState('')

  useEffect(() => {
    if (!orgs || orgs.length < 1) return
    setOrg({
      label: orgs[0].label,
      value: orgs[0].value,
    })
  }, [orgs])

  const setSelectedOrg = (e?: ChangeEvent<HTMLSelectElement>) => {
    if (e?.target) {
      const newOption = orgs.find(
        (org) => org.value === Number(e?.target.value),
      )
      setOrg(newOption || null)
    }
  }

  const onChangeApiKeyName = (e?: ChangeEvent<HTMLInputElement>) => {
    if (e?.target) {
      setApiKeyName(e?.target.value.trim())
    }
  }

  const createAPIKey = async () => {
    if (apiKeyName && org?.value) {
      setLoading(true)
      await createAPIKeyApi(postWithAccessToken, apiKeyName, org.value, securedStatus)
        .then((res: any) => {
          if (res.status === 'ok') {
            toast.success('Successful create API Key')
            setResultPanelData(
              `Created API KEY Name: ${res.results.name}\n\nAPI KEY: ${res.results.key}`,
            )
          } else {
            toast.warning(res.status)
          }
        })
        .catch((err: any) => {
          console.log(err)
        })
      setLoading(false)
    } else {
      toast.warning('Please enter API Key Name and select an organization.')
      setLoading(false)
    }
  }

  return (
    <StyledAccordion>
      <AccordionSummary
        expandIcon={<img src={ExpandIcon} alt="Expand" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className="accordionOrg"
      >
        <img
          src={theme.palette.mode === 'dark' ? SmartIcon : SmartIconDark}
          alt="SocialOrganization"
        />
        <Typography>&nbsp;&nbsp;CREATE API KEY FOR ORGANIZATION</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ textAlign: 'center' }}>
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item md={8}>
            <InputGroup>
              <Typography>Enter API Key Name</Typography>
              <Input
                placeholder="Please enter API Key name"
                value={apiKeyName}
                type="text"
                required
                onChange={onChangeApiKeyName}
              />
            </InputGroup>
            <InputGroup>
              <Typography>Choose organization</Typography>
              <SelectCategory value={org?.value} onChange={setSelectedOrg}>
                {orgs
                  ?.sort((a: any, b: any) =>
                    a.label?.toLowerCase() > b.label?.toLowerCase() ? 1 : -1,
                  )
                  ?.map((org) => (
                    <option key={org.value} value={org.value}>
                      {org.label}
                    </option>
                  ))}
              </SelectCategory>
            </InputGroup>
            <InputGroup>
              <Typography>{`API Key is ${securedStatus ? 'secured' : 'not secured'}`}</Typography>
              <CustomSwitch
                checked={securedStatus}
                onChange={() => setSecuredStatus(!securedStatus)}
              />
            </InputGroup>
          </Grid>
        </Grid>
        <Button
          onClick={createAPIKey}
          className="helikaButtonClass"
          disabled={loading}
        >
          {loading && (
            <img
              width="14px"
              className="animate-spin"
              style={{ marginRight: '2px' }}
              src={loadingSVG}
              alt=""
            />
          )}
          <StyledTxt>Create New API Key</StyledTxt>
        </Button>
        <div
          style={{
            marginLeft: '28px',
            marginRight: '28px',
          }}
        >
          <StyledTextarea value={resultPanelData} readOnly />
        </div>
      </AccordionDetails>
    </StyledAccordion>
  )
}
