import { Box, Grid } from '@mui/material';

import { ErrorAlert } from 'src/components/atoms/v2/ErrorAlert';
import { width } from 'src/utils/echarts';

export interface IWidgetProps {
  loading: boolean;
  value?: string;
  header: string;
  error?: Error;
  stacked?: boolean;
  small?: boolean;
  full?: boolean;
}
export const Widget = ({ loading, value = '', header, error, stacked, small, full = true }: IWidgetProps) => {
  return (
    <Grid item minWidth={'min-content'} width={'100%'} height={'100%'}>
      <ErrorAlert error={error} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          bgcolor: 'background.paper',
          borderRadius: '0.5rem',
          p: 2,
          margin: 'auto',
          width: full ? '100%' : (small ? `fit-content` : width),
          height: full ? '100%' : (small ? `fit-content` : width),
          justifyContent: 'space-between'
        }}
      >
        <Box sx={{ color: 'text.secondary', marginBottom: 'auto' }}>{header}</Box>
        <Box sx={{ color: 'text.primary', fontSize: '1.5rem', fontWeight: 'medium' }}>
          {
            loading
              ? 'Loading...'
              : value
          }
        </Box>
      </Box>
    </Grid>
  );
};
