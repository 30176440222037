import React from 'react'
import {
  Stack,
  Typography,
  TextField,
  OutlinedTextFieldProps,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { HelikaRadiis, HelikaSpacings } from 'src/consts/spacings'
import { HelikaTypographies } from 'src/consts/fonts'

interface FormTextFieldProps extends Omit<OutlinedTextFieldProps, 'variant'> {
  multiline?: boolean
  rows?: number
  maxRows?: number
}

// Styled TextField component with conditional styling based on multiline prop
const StyledTextField = styled(TextField)<FormTextFieldProps>(
  ({ theme, error, multiline }) => ({
    marginBottom: '0px !important',
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'var(--Color-Background-Interactive-Secondary, #1C2025)',
      fontFamily: 'Open Sans',
      fontWeight: 400,
      height: multiline ? 'auto' : '36px',
      minHeight: multiline ? '100px' : '36px',
      borderRadius: HelikaRadiis.md,
      '& fieldset': {
        border: error
          ? '1px solid var(--Color-Border-System-Danger, #C73A3A)'
          : '1px solid var(--Color-Border-General-Secondary, #434D5A)',
      },
      '&:hover fieldset': {
        borderColor: error
          ? 'var(--Color-Border-System-Danger, #C73A3A)'
          : 'var(--Color-Border-General-Secondary, #434D5A)',
      },
      '&.Mui-focused fieldset': {
        borderColor: error
          ? 'var(--Color-Border-System-Danger, #C73A3A)'
          : 'var(--Color-Border-General-Secondary, #434D5A)',
      },
    },
    '& .MuiOutlinedInput-input': {
      padding: `${HelikaSpacings.xxs} ${HelikaSpacings.xs}`, // Same padding for both single and multiline
      lineHeight: multiline ? '1.5' : 'inherit', // Better line height for multiline
      '&::placeholder': {
        color: 'var(--Color-Text-Placeholder, #999999)',
        opacity: 1,
      },
    },
    '& .MuiSvgIcon-root': {
      marginLeft: 0,
      marginTop: 0,
    },
    // Adjust the textarea specific styles
    '& .MuiInputBase-multiline': {
      padding: '0 14px 0 0', // Remove default multiline padding
    },
    // Add scrollbar styling for textarea
    '& .MuiOutlinedInput-input::-webkit-scrollbar': {
      width: '8px',
    },
    '& .MuiOutlinedInput-input::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '& .MuiOutlinedInput-input::-webkit-scrollbar-thumb': {
      background: 'var(--Color-Border-General-Secondary, #434D5A)',
      borderRadius: '4px',
    },
  }),
)

// Error icon styles
const StyledErrorIcon = styled(ErrorOutlineIcon)({
  color: 'var(--Color-Border-System-Danger, #C73A3A)',
  marginLeft: '8px',
  alignSelf: 'flex-start',
  marginTop: '8px',
})

const FormTextField: React.FC<FormTextFieldProps> = ({
  label,
  placeholder,
  multiline = false,
  rows = 4,
  maxRows = 8,
  ...rest
}) => {
  return (
    <Stack direction="column" gap={HelikaSpacings.xxxs}>
      <Typography sx={HelikaTypographies['label-regular']}>{label}</Typography>
      <StyledTextField
        {...rest}
        variant="outlined"
        placeholder={placeholder}
        multiline={multiline}
        rows={multiline ? rows : undefined}
        maxRows={multiline ? maxRows : undefined}
        InputProps={{
          ...rest.InputProps,
          endAdornment: rest.error ? (
            <Stack height="100%" alignItems="center" justifyContent="center">
              <StyledErrorIcon />
            </Stack>
          ) : null,
        }}
      />
    </Stack>
  )
}

export default FormTextField
