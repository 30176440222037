import { Box, Stack } from "@mui/material";
import React from "react";
import "@fontsource/open-sans"; // Defaults to weight 400
import { spacing } from "helika-ui-sdk/dist/src/values/Spacing";
import CloseIcon from '@mui/icons-material/Close';
import { typography } from "helika-ui-sdk/dist/src/values/Typography";
import Button from "helika-ui-sdk/dist/src/components/Button";
import { color_base, colors_semantic } from "helika-ui-sdk/dist/src/values/Colors";
import { HEADER } from "src/consts/sizes";

export default function SidePanel({
    darkMode = true,
    title,
    children,
    dismissible,
    show,
    setShow,
    onClickClear,
    hasCancelButton = true,
    hasActionButton = true,
    cancelButtonFunction = () => { },
    actionButtonFunction = () => { },
    actionButtonTitle = 'Action',
    actionButtonDisabled = false,
    cancelButtonDisabled = false,
}: {
    darkMode?: boolean,
    title: string,
    children: any,
    dismissible: boolean,
    show: boolean,
    setShow: any,
    onClickClear: any,
    hasCancelButton?: boolean,
    hasActionButton?: boolean,
    cancelButtonFunction?: any,
    actionButtonFunction?: any,
    actionButtonTitle?: string
    actionButtonDisabled?: boolean,
    cancelButtonDisabled?: boolean,
}) {

    if (!show) return null

    return (
        <Stack
            sx={{
                display: show ? 'flex' : 'none',
                flexDirection: 'column',
                borderLeft: `1px solid ${colors_semantic.border_primary}`,
                background: darkMode ? colors_semantic.background_secondary : color_base.white,
                width: '600px',
                height: `calc(100vh - ${HEADER.height})`,
                overflow: 'auto',
                minWidth: '400px',
                // minHeight: '1024px',
                color: darkMode ? color_base.white : color_base.black,
            }}
        >
            <Stack
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: `${spacing.Spacing_sm} ${spacing.Spacing_sm} ${spacing.Spacing_sm} ${spacing.Spacing_lg}`,
                    justifyContent: 'space-between',
                    gap: spacing.Spacing_xxs,
                    height: 'fit-content',
                    borderBottom: `1px solid ${colors_semantic.border_primary}`,
                    background: darkMode ? colors_semantic.background_secondary : color_base.white,
                }}
            >
                <Box
                    sx={{
                        flex: 1,
                        textAlign: 'left',
                        ...typography.heading3,
                    }}
                >
                    {
                        title
                    }
                </Box>
                <Box
                    sx={{
                        display: dismissible ? 'flex' : 'none',
                        height: '28px !important',
                        width: '28px !important',
                        borderRadius: '100vmax',
                        cursor: 'pointer',
                        padding: '2px',
                        '&:hover': {
                            background: colors_semantic.background_int_tertiary_hover
                        },
                    }}
                >
                    <CloseIcon
                        style={{
                            fontSize: '24px',
                            color: colors_semantic.icon_secondary,
                            margin: 'auto',
                            width: '24px',
                            height: '24px',
                        }}
                        onClick={onClickClear ? onClickClear : () => {
                            setShow(false)
                        }}
                    />
                </Box>
            </Stack >
            <Stack
                sx={{
                    // padding: spacing.Spacing_lg,
                    flex: 1,
                    padding: spacing.Spacing_lg
                }}
            >
                {
                    children
                }
            </Stack >
            <Stack
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    padding: `${spacing.Spacing_sm} ${spacing.Spacing_sm} ${spacing.Spacing_sm} ${spacing.Spacing_lg}`,
                    justifyContent: 'end',
                    gap: spacing.Spacing_xxs,
                    height: 'fit-content',
                    borderTop: `1px solid ${colors_semantic.border_primary}`,
                    background: darkMode ? colors_semantic.background_secondary : color_base.white,
                    maxWidth: '100%',
                    overflow: 'clip'
                }}
            >
                {
                    hasCancelButton &&
                    <Button
                        type={'Tertiary'}
                        disabled={cancelButtonDisabled}
                        handleClick={cancelButtonFunction}
                        darkMode={darkMode}
                    >
                        Cancel
                    </Button>
                }
                {
                    hasActionButton &&
                    <Button
                        type={'Primary'}
                        disabled={actionButtonDisabled}
                        handleClick={actionButtonFunction}
                        darkMode={darkMode}
                    >
                        {actionButtonTitle}
                    </Button>
                }
            </Stack >
        </Stack >
    );
};