import React, { ChangeEvent } from 'react'
import { styled } from '@mui/material/styles'
import Switch, { SwitchProps } from '@mui/material/Switch'
import { newColor } from 'src/consts/colors'

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 64,
  height: 34,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    left: '6px',
    top: '6px',
    padding: 0,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      top: '6px',
      transform: 'translateX(30px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 34 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      top: 10,
      width: 16,
      height: 16,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: newColor.white,
      fontSize: '10px',
      fontWeight: 'bold',
    },
    '&::before': {
      content: '"ON"',
      left: 10,
    },
    '&::after': {
      content: '"OFF"',
      right: 10,
    },
  },
}))

export const ToggleSwitch = ({
  checked,
  onChange,
}: {
  checked: boolean
  onChange?: (checked: boolean) => void
}) => {
  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    onChange?.(checked)
  }

  return <IOSSwitch checked={checked} onChange={handleChange} />
}

export default ToggleSwitch
