
import React, { useEffect, useMemo, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { ChatComponent } from "../../components/llmChat/ChatComponent";
import { ChatController } from 'src/utils/chat/src';
import { actionCreators } from "src/state";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { HELIKA_LOGO_URL } from "src/utils/string";
import _ from "lodash";

export default function LLMDemo(): React.ReactElement {

  const theme = useTheme()
  const dispatch = useDispatch()
  const { setLlmState } = bindActionCreators(actionCreators, dispatch)
  const chatCtl = useSelector((state: any) => state.llmChat.chatController)
  const isLlmExpanded = useSelector((state: any) => state.llmChat.isExpanded)
  const llmContent = useSelector((state: any) => state.llmChat.content)
  const [suggestions, setSuggestions] = useState<any[] | undefined>([])

  //save chat
  useEffect(() => {
    setLlmState({
      type: 'SET_CHAT',
      payload: chatCtl,
    })
  }, [chatCtl, setLlmState])


  useMemo(() => {
    echo(chatCtl, setSuggestions);
  }, [chatCtl]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        height: '100vh',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: isLlmExpanded ? '0%' : '70%',
          justifyContent: 'center',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }}
        className='llmContent'
      >
        <div
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            width: isLlmExpanded ? '0em' : '100%',
            height: '100%',
            justifyContent: 'center',
            overflow: 'auto',
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          }}
          className='llmContent'
        >
          {
            llmContent && llmContent.domain &&
            <div
              style={{ height: 0 }}
            >
              <div
                style={{
                  position: 'sticky',
                  top: '0.5em',
                  margin: '1em auto 0 1em',
                  padding: '0.2em 2em 0.2em 2em',
                  borderRadius: '100vmax',
                  background: 'linear-gradient(90deg, #8BE1C9 17.2%, #9747FF 87.63%)',
                  color: 'black',
                  cursor: 'pointer',
                  zIndex: 10,
                  fontSize: '0.7em',
                  height: 'fit-content',
                  width: 'fit-content'
                }}
                onClick={() => { console.log('pill touch') }}
              >
                {
                  llmContent.domain
                }
              </div>
            </div>
          }
          {
            llmContent && (
              (['jsx', 'link', 'chart', 'widget'].includes(llmContent?.type))
                ? llmContent.content
                : <div style={{ textAlign: 'center' }}> {llmContent.content} </div>
            )
          }
        </div>
      </div>
      <Box
        className='llmChat'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: isLlmExpanded ? '100%' : '30%',
          minWidth: '20em',
          bgcolor: 'background.default',
          padding: 0,
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          maxHeight: '100%',
          maxWidth: '100%',
          color: 'white',
          padding: 0
        }}
        >
          <ChatComponent
            chatController={chatCtl}
            suggestions={suggestions}
          />
        </Box>
      </Box>
    </div>
  )
}

async function echo(chatCtl: ChatController, setSuggestions: any): Promise<void> {

  // start with welcome if new convo
  if (_.isEmpty(chatCtl.getMessages())) {
    await chatCtl.addMessage({
      type: 'text',
      content: [
        {
          content: `Welcome to Helika AI! Ask me anything!`,
          type: 'text'
        },
      ],
      self: false,
      avatar: HELIKA_LOGO_URL,
    });
  }
  await chatCtl.setActionRequest({
    type: 'text',
    always: true,
    placeholder: 'Ask Helika AI anything!',
  }, (resp: any) => {
    setSuggestions(undefined);
    //todo: add response thing here
  });
}
