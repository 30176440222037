export const HelikaSpacings = {
  0: '0px',
  xxxs: '4px',
  xxs: '8px',
  xs: '12px',
  sm: '16px',
  md: '20px',
  lg: '24px',
  xl: '32px',
  xxl: '48px',
  xxxl: '64px',
}

export const HelikaRadiis = {
  sm: '2px',
  md: '4px',
  lg: '8px',
  full: '100px',
}

export const HelikaSizes = {
  0: '0px',
  xxxs: '8px',
  xxs: '16px',
  xs: '18px',
  sm: '20px',
  md: '24px',
  lg: '32px',
  xl: '36px',
  xxl: '42px',
  xxxl: '48px',
}
