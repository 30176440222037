import { useContext } from 'react'
import { Auth0Context } from 'src/contexts/Auth0Context'

export type KeyedObject = {
  [key: string]: any
}

export const useRequests = () => {
  const baseUrl = process.env.REACT_APP_HELIKA_API_URL
  const {
    getWithAccessToken,
    postWithAccessToken,
    patchWithAccessToken,
    deleteWithAccessToken,
  } = useContext(Auth0Context)

  return {
    getList: async ({
      resource,
      meta,
      rawQueryUrl,
      version = 'v1',
    }: {
      resource?: string
      meta?: KeyedObject
      rawQueryUrl?: string
      version?: string
    }) =>
      Boolean(rawQueryUrl)
        ? await getWithAccessToken(
            `${baseUrl}/${version}/${resource}?${rawQueryUrl}`,
          )
        : await getWithAccessToken(`${baseUrl}/${version}/${resource}`, meta),
    getOne: async ({
      resource,
      id,
      version = 'v1',
    }: {
      resource: string
      id?: string | number
      version?: string
    }) =>
      id
        ? await getWithAccessToken(`${baseUrl}/${version}/${resource}/${id}`)
        : await getWithAccessToken(`${baseUrl}/${version}/${resource}`),
    create: async ({
      resource,
      payload,
      version = 'v1',
    }: {
      resource: string
      payload: KeyedObject
      version?: string
    }) =>
      await postWithAccessToken(`${baseUrl}/${version}/${resource}`, payload),
    update: async ({
      resource,
      payload,
      version = 'v1',
    }: {
      resource: string
      payload: KeyedObject
      version?: string
    }) =>
      await patchWithAccessToken(`${baseUrl}/${version}/${resource}`, payload),
    delete: async ({
      resource,
      id,
      version = 'v1',
    }: {
      resource: string
      id?: string | number
      version?: string
    }) =>
      id
        ? await deleteWithAccessToken(`${baseUrl}/${version}/${resource}/${id}`)
        : await deleteWithAccessToken(`${baseUrl}/${version}/${resource}`),
  }
}
