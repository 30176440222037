import React, { FC, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Box,
  Stack,
  Typography,
  Alert,
  useTheme,
} from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import * as Yup from 'yup'
import { useFormik } from 'formik'
// project import
import { useRequests } from 'src/utils/lib'
import ReusableSnackbar from './ReusableSnackbar'
import { FormTextField } from './form'
import ToggleSwitch from './ToggleSwitch'
// types
import { IEngageCampaign } from 'src/types/engage'
// assets
import { newColor } from 'src/consts/colors'
import { HelikaTypographies } from 'src/consts/fonts'
import { HelikaRadiis, HelikaSpacings } from 'src/consts/spacings'

interface CampaignModalProps {
  open: boolean
  onClose: (refreshTable: boolean) => void
  isEdit: boolean
  campaignData?: IEngageCampaign
}

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: HelikaSpacings.md,
  color: theme.palette.text.primary,
}))

type FormDataType = Omit<
  IEngageCampaign,
  'id' | 'labels' | 'created_at' | 'updated_at'
>

const CampaignModal: FC<CampaignModalProps> = ({
  open,
  onClose,
  isEdit,
  campaignData,
}) => {
  const theme = useTheme()
  const themeMode = theme.palette.mode
  const { organization_id } = useSelector((state: any) => state.user)
  const { create, update } = useRequests()

  const [snackBar, setSnackBar] = useState<{
    open: boolean
    message?: string
    variant?: 'success' | 'error'
  }>({ open: false, message: '', variant: 'success' })

  const initialValues: FormDataType = {
    organization_id,
    priority: 1,
    active: true,
    name: '',
    description: '',
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().max(255).required('Name is required'),
    description: Yup.string().max(5000).required('Description is required'),
  })

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (isEdit && campaignData) {
          const updatedCampaign = {
            priority: values.priority,
            active: values.active,
            name: values.name,
            description: values.description,
          }
          await update({
            resource: `engage/internal/projects/${campaignData.id}`,
            payload: updatedCampaign,
          })
          onClose(true)
          setSnackBar({
            message: 'Successfully updated engage campaign.',
            variant: 'success',
            open: true,
          })
        } else {
          const newCampaign = {
            ...initialValues,
            name: values.name,
            description: values.description,
          }
          await create({
            resource: 'engage/internal/projects',
            payload: newCampaign,
          })
          onClose(true)
          setSnackBar({
            message: 'Successfully created engage campaign.',
            variant: 'success',
            open: true,
          })
        }
        setSubmitting(false)
      } catch (error) {
        setSnackBar({
          message: `Failed to ${isEdit ? 'update' : 'create'} engage campaign`,
          variant: 'error',
          open: true,
        })
        console.error(error)
      }
    },
  })

  const {
    values,
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    setValues,
    setErrors,
  } = formik

  useEffect(() => {
    setErrors({})
  }, [open])

  useEffect(() => {
    if (campaignData) {
      setValues({
        organization_id,
        priority: campaignData.priority,
        active: campaignData.active,
        name: campaignData.name,
        description: campaignData.description,
      })
    }
  }, [open, isEdit, campaignData])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Dialog
        disableRestoreFocus
        open={open}
        onClose={() => onClose(false)}
        scroll="paper"
        PaperProps={{
          sx: {
            width: '420px',
            maxHeight: '90vh',
            color: themeMode === 'dark' ? newColor.white : newColor.black,
            background:
              themeMode === 'dark' ? newColor.charcoal : newColor.cultured,
            borderRadius: '8px',
            filter: 'drop-shadow(0px 20px 20px rgba(0, 0, 0, 0.3))',
          },
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor:
              theme.palette.mode === 'dark'
                ? newColor.charcoal
                : newColor.cultured,
            p: `${HelikaSpacings.sm} ${HelikaSpacings.sm} ${HelikaSpacings.sm} ${HelikaSpacings.lg}`,
          }}
        >
          {isEdit ? 'Edit' : 'Create'} Campaign
          <CloseButton
            onClick={() => {
              setValues(initialValues)
              onClose(false)
            }}
          >
            <CloseIcon />
          </CloseButton>
        </DialogTitle>

        <DialogContent
          sx={{
            backgroundColor:
              theme.palette.mode === 'dark'
                ? newColor.charcoal
                : newColor.cultured,
            px: HelikaSpacings.lg,
          }}
        >
          <Stack direction="column">
            {Object.values(errors).filter((_) => !!_).length > 0 && (
              <Alert
                severity="error"
                onClose={() => {}}
                sx={{
                  bgcolor:
                    themeMode === 'dark'
                      ? 'rgba(199, 58, 58, 0.1)'
                      : 'rgba(199, 58, 58, 0.2)',
                  borderLeft: `3px solid ${newColor.error}`,
                  borderRadius: `${HelikaRadiis.md}`,
                  padding: `${HelikaSpacings.xxs} ${HelikaSpacings.sm}`,
                  '& .MuiAlert-action': {
                    mr: 0,
                  },
                  mb: HelikaSpacings.md,
                }}
              >
                Fill out missing campaign details.
              </Alert>
            )}

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <FormTextField
                fullWidth
                label="Name"
                placeholder="Campaign name"
                value={values.name}
                onChange={(e) => setFieldValue('name', e.target.value)}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                sx={{ mb: 2 }}
              />

              {isEdit && (
                <>
                  <FormTextField
                    fullWidth
                    label="Priority"
                    placeholder="Campaign pririty"
                    value={values.priority}
                    onChange={(e) => setFieldValue('priority', e.target.value)}
                    error={Boolean(touched.priority && errors.priority)}
                    helperText={touched.priority && errors.priority}
                    sx={{ mb: 2 }}
                  />

                  <Stack direction="column" gap={HelikaSpacings.xxxs}>
                    <Typography sx={HelikaTypographies['label-regular']}>
                      Status
                    </Typography>

                    <ToggleSwitch
                      checked={values.active}
                      onChange={() => setFieldValue('active', !values.active)}
                    />
                  </Stack>
                </>
              )}

              <FormTextField
                fullWidth
                label="Description"
                placeholder="Campaign Description"
                multiline={true}
                value={values.description}
                onChange={(e) => setFieldValue('description', e.target.value)}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
              />
            </Box>
          </Stack>
        </DialogContent>

        <DialogActions
          sx={{
            p: `${HelikaSpacings.sm} ${HelikaSpacings.lg}`,
            backgroundColor:
              theme.palette.mode === 'dark'
                ? newColor.charcoal
                : newColor.cultured,
          }}
        >
          <Button
            onClick={() => onClose(false)}
            sx={{
              color: 'white',
              borderRadius: HelikaRadiis.md,
              height: HelikaSpacings.xl,
              p: `${HelikaSpacings.xxs} ${HelikaSpacings.sm}`,
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => handleSubmit()}
            disabled={isSubmitting}
            sx={{
              color: 'white',
              bgcolor: 'var(--Color-Background-Interactive-Primary, #EA335F)',
              '&:hover': {
                bgcolor: '#d81b60',
              },
              borderRadius: HelikaRadiis.md,
              height: HelikaSpacings.xl,
              p: `${HelikaSpacings.xxs} ${HelikaSpacings.sm}`,
            }}
          >
            {isEdit ? 'Save' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>

      <ReusableSnackbar
        open={snackBar.open}
        message={snackBar.message as string}
        variant={snackBar.variant as 'success' | 'error'}
        handleClose={() => setSnackBar({ open: false })}
      />
    </LocalizationProvider>
  )
}

export default CampaignModal
