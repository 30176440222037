import React from 'react';

export function ExperimentsCreatedAtRenderer(props: any) {

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <div>{props.value?.date}</div>
      <div>By: {props.value?.created_by}</div>
    </div>
  );
};