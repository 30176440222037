import React from 'react'
import {
  Box,
  styled,
  Switch,
} from '@mui/material'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import { color_base, colors_semantic } from 'helika-ui-sdk/dist/src/values/Colors'

export const Android12Switch: any = styled(Switch)(({ theme }) => ({
  "&.MuiSwitch-root": {
    padding: '0',
    width: '60px',
    height: '32px'
  },
  "& .MuiSwitch-switchBase": {
    "&.Mui-checked": {
      "+ .MuiSwitch-track": {
        backgroundColor: `${colors_semantic.background_int_primary} !important`,
        opacity: 1,
      },
      ".MuiSwitch-thumb": {
        backgroundColor: color_base.white,
      },
      "&.Mui-checked": {
        transform: "translateX(26px)",
      },
    },
    bottom: '6px',
  },
  "&.Mui-disabled + .MuiSwitch-track": {
    backgroundColor: "black"
  },
  '& .MuiSwitch-track': {
    borderRadius: '100px',
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      width: '20px',
      height: '20px',
    },
    height: '32px',
    backgroundColor: `${colors_semantic.background_int_inactive} !important`,
    '&::before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="white" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      top: '6px',
      left: '8px',
    },
    '&::after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="white" d="M19,13H5V11H19V13Z" /></svg>')`,
      top: '6px',
      right: '8px',
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: colors_semantic.icon_primary,
    boxShadow: 'none',
    width: '24px',
    height: '24px',
    margin: '6px -4px 0px -4px',
  },
}));

export const MOTDCampaignStatusRenderer = (props: any) => {

  let clickHandler = props.colDef?.slotProps?.debouncedClickHandler ? props.colDef?.slotProps?.debouncedClickHandler : () => { console.log('no click handler') }

  return (
    <Box
      sx={{
        cursor: 'default',
        margin: 'auto auto auto 0',
        height: '32px'
      }}
    >
      <Android12Switch
        checked={!props.row.archived}
        onClick={() => {
          props.colDef?.slotProps?.setSelectedCampaign(props.row)
          clickHandler(props.row)
        }}
      />
    </Box>
  )
}
