import React, { useContext, useState } from 'react'

import {
  Box,
  styled,
  Switch,
  useTheme,
} from '@mui/material'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import {
  Dialog,
} from '@mui/material'
import { Button, Colors, Textfield, ErrorBanner, Spacing } from 'helika-ui-sdk'
import { createMOTDCampaign } from 'src/utils/api/queries'
import { Auth0Context } from 'src/contexts/Auth0Context'
import { useSelector } from 'react-redux'
import _, { debounce } from 'lodash'
import { isStringEmpty } from 'src/utils/string'
import CloseIcon from '@mui/icons-material/Close';
import { spacing } from 'helika-ui-sdk/dist/src/values/Spacing'
import { color_base, colors_semantic } from 'helika-ui-sdk/dist/src/values/Colors'
import ReusableSnackbar from 'src/pages/Modules/GameManagement/RedemptionCode/components/ReusableSnackbar'
import { lightPalette } from 'src/consts/colors'

export interface SimpleDialogProps {
  open: boolean
  setOpen: any
  onCreateCampaign: any
}

export interface Dashboard_mapping {
  name: string
  iframe_link: string
}


export const Android12Switch: any = styled(Switch)(({ theme }) => ({
  left: 12,
  "&.MuiSwitch-root": {
    margin: 0,
    padding: 0
  },
  "& .MuiSwitch-switchBase": {
    padding: '3px',
    "&.Mui-checked": {
      "+ .MuiSwitch-track": {
        backgroundColor: `${colors_semantic.background_int_primary} !important`,
        opacity: 1,
      },
      ".MuiSwitch-thumb": {
        backgroundColor: color_base.white,
      },
      "&.Mui-checked": {
        transform: "translateX(26px)",
      },
    },
    left: '2px'
  },
  "&.Mui-disabled + .MuiSwitch-track": {
    backgroundColor: "black"
  },
  '& .MuiSwitch-track': {
    borderRadius: '100px',
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      width: '20px',
      height: '20px',
    },
    height: '32px',
    width: '60px',
    backgroundColor: `${colors_semantic.background_int_inactive} !important`,
    '&::before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="white" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      top: '6px',
      left: '6px',
    },
    '&::after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="white" d="M19,13H5V11H19V13Z" /></svg>')`,
      top: '6px',
      right: '24px',
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: colors_semantic.icon_primary,
    boxShadow: 'none',
    width: '24px',
    height: '24px',
    marginTop: '1px'
  },
  width: '5em',
  height: '32px',
}));

const CreateMOTDCampaignModal = (props: SimpleDialogProps) => {
  const { open, setOpen, onCreateCampaign } = props
  const theme = useTheme()
  const { postWithAccessToken } = useContext(Auth0Context);
  const { organization_id, id } = useSelector((state: any) => state.user)

  const [name, setName] = useState<string>('');
  const [missingDetails, setMissingDetails] = useState<boolean>(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState<string>('')
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>(
    'success',
  )

  const [loading, setLoading] = useState<boolean>(false);

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const debouncedClickHandler = debounce(createCampaignApi, 200, { maxWait: 500 })

  async function resetFields() {
    setName('')
    setMissingDetails(false)
  }

  async function createCampaignApi() {

    try {
      if (loading) return
      setLoading(true)

      try {
        isStringEmpty(name, 'Name required')
      } catch (e: any) {
        setMissingDetails(true)
        throw new Error(e)
      }

      let campaign: any = {
        title: name,
        organization_id: organization_id,
        creator: id,
        start: null,
        end: null,
        active: true,
        tags: []
      }

      await createMOTDCampaign(postWithAccessToken, campaign).then((resp: any) => {
        if (!_.isEmpty(resp?.results)) {
          onCreateCampaign(resp?.results)
          setOpen(false)
          setLoading(false)
          setMissingDetails(false)
          resetFields()
        } else {
          throw new Error('Could not create MOTD Campaign')
        }
      })
    } catch (e: any) {
      setSnackbarMessage(e?.message)
      setSnackbarVariant('error')
      setSnackbarOpen(true)
      setLoading(false)
    }

  }

  return (
    <Dialog
      disableRestoreFocus
      fullScreen={true}
      className="defaultModalStyle open-sans-fontFam400"
      sx={{
        margin: 'auto',
        borderRadius: '1px !important',
        border: 'none',
        background: '#1A000000',
        overflow: 'auto',
      }}
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          background: theme.palette.mode === 'dark' ? Colors.background.primary : lightPalette.bgPage,
          borderStyle: 'none',
          borderColor: '#404040',
          borderWidth: '1px',
          display: 'flex',
          borderRadius: '1px !important',
          width: 'fit-content',
          boxShadow: '0px 20px 20px 0px #4D000000 !important',
          overflow: 'auto',
          maxWidth: 'min(400px, 90vw)'
        },
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: `${Spacing.Spacing_sm} ${Spacing.Spacing_sm} ${Spacing.Spacing_sm} ${Spacing.Spacing_lg}`,
          minWidth: '350px'
        }}
      >
        <Box
          sx={{
            fontSize: '20px',
            lineHeight: '140%',
            fontWeight: 600,
            flex: 1,
            whiteSpace: 'nowrap',
          }}
        >
          Create Campaign
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: 'auto 0 auto 0',
            cursor: 'pointer',
            height: '32px',
            width: '32px'

          }}
          onClick={handleClose}
        >
          <CloseIcon
            sx={{
              fontSize: '24px',
              height: '24px',
              width: '24px',
              margin: 'auto'
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: spacing.Spacing_md,
          padding: `${Spacing.Spacing_lg}`
        }}
      >
        <ErrorBanner
          show={missingDetails && (_.isEmpty(name))}
          errorMessage='Fill out missing offer details.'
        />
        <Textfield
          darkMode={theme.palette.mode === 'dark'}
          label={'Name'}
          value={name}
          onChange={handleChangeName}
          hasError={(missingDetails && _.isEmpty(name?.trim()))}
          placeHolder='Campaign name'
        />
      </Box >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          gap: Spacing.Spacing_xxs,
          padding: `${Spacing.Spacing_sm} ${spacing.Spacing_lg}`,
          width: '100%',
          minWidth: '350px'
        }}
      >
        <Button
          type={theme.palette.mode === 'dark' ? 'Tertiary' : 'Primary'}
          handleClick={() => { setOpen(false) }}
        >
          Cancel
        </Button>
        <Button
          type='Primary'
          disabled={loading}
          handleClick={debouncedClickHandler}
        >
          Create
        </Button>
      </Box>
      <ReusableSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        variant={snackbarVariant}
        handleClose={() => setSnackbarOpen(false)}
      />
    </Dialog >
  )
}

export default CreateMOTDCampaignModal
