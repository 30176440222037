import React, { useState, useEffect } from 'react'
import { Stack, Typography, Box } from '@mui/material'
import DateRangeIcon from '@mui/icons-material/DateRange'
import { styled } from '@mui/system'
import { newColor } from 'src/consts/colors'
import dayjs from 'dayjs'
import { typography } from 'helika-ui-sdk/dist/src/values/Typography'

const statusColorMap: Record<string, string> = {
  not_started: newColor.oceanBlue || '#1A74A8',
  active: newColor.green || '#067A57',
  finished: newColor.electricPurple || '#9654F0',
  archived: newColor.jet || '#545454',
}

const statusLabelMap: Record<string, string> = {
  not_started: 'Not Started',
  active: 'Active',
  finished: 'Finished',
  archived: 'Archived',
}

const StyledDateText = styled(Typography)({
  ...typography.paragraph_small,
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
})

const StyledLabelText = styled(Typography)({
  ...typography.label_small,
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
})

const StatusDotContainer = styled(Box)({
  width: '16px',
  height: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const StatusDot = styled(Box)<{ dotColor: string }>(({ dotColor }) => ({
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  backgroundColor: dotColor,
}))

const StatusChip = styled(Box)(({ theme }) => ({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '0px 12px 0px 4px',
  gap: '4px',
  height: '24px',
  borderRadius: '100px',
  border: '1px solid #434D5A',
  backgroundColor: 'transparent',
}))

interface CampaignDateProps {
  startDate?: string
  endDate?: string
  status?: string
}

const CampaignDate: React.FC<CampaignDateProps> = ({
  startDate,
  endDate,
  status,
}) => {
  const [statusColor, setStatusColor] = useState<string>(newColor.gray)
  const [formattedStatus, setFormattedStatus] = useState<string>('Unknown')
  const [formattedStartDate, setFormattedStartDate] = useState<string>('N/A')
  const [formattedEndDate, setFormattedEndDate] = useState<string>('N/A')

  useEffect(() => {
    const lowerCaseStatus = status?.toLowerCase()
    setStatusColor(
      lowerCaseStatus && statusColorMap[lowerCaseStatus]
        ? statusColorMap[lowerCaseStatus]
        : newColor.gray,
    )
    setFormattedStatus(
      lowerCaseStatus && statusLabelMap[lowerCaseStatus]
        ? statusLabelMap[lowerCaseStatus]
        : 'Unknown',
    )
    setFormattedStartDate(
      startDate ? dayjs(startDate).format('YYYY-MM-DD hh:mm A') : 'N/A',
    )
    setFormattedEndDate(
      endDate ? dayjs(endDate).format('YYYY-MM-DD hh:mm A') : 'N/A',
    )
  }, [startDate, endDate, status])

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      sx={{ marginTop: 1 }}
    >
      <DateRangeIcon sx={{ fontSize: '18px', color: '#D2D2D3' }} />
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <StyledLabelText>Start</StyledLabelText>
        <StyledDateText>{formattedStartDate}</StyledDateText>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <StyledLabelText>End</StyledLabelText>
        <StyledDateText>{formattedEndDate}</StyledDateText>
      </Stack>
      <StatusChip>
        <StatusDotContainer>
          <StatusDot dotColor={statusColor} />
        </StatusDotContainer>
        <StyledDateText>{formattedStatus}</StyledDateText>
      </StatusChip>
    </Stack>
  )
}

export default CampaignDate
