import React, { useEffect, useState } from 'react';
import { MessageSection } from './MessageSection';
import _ from 'lodash';
import { perLetterDelay } from 'src/utils/number';
import QRCode from 'react-qr-code';
import EvolutionGraphic from 'src/assets/evolution_graphic.jpg'

export function Goodbye({ fields, timeout }: { fields?: any, timeout: any }): React.ReactElement {

  let [data, setData] = useState<any>({});

  useEffect(() => {
    setTimeout(() => {
      setData(fields)
    }, timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (_.isEmpty(data)) return <div />

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        backgroundSize: 'cover'
      }}
      className='landingPage'
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          margin: 'auto',
          minWidth: '35em',
          height: '100%',
          minHeight: '10em',
          width: 'fit-content',
          textAlign: 'left',
          verticalAlign: 'middle',
          justifyContent: 'center',
          padding: '5em',
          maxWidth: '50%'
        }}
      >
        {
          data?.content.map((item: any, index: number) => {
            return <MessageSection
              key={index}
              content={item.content}
              type={item.type}
              isSelf={false}
              scroll={undefined}
              color={item.color}
              delay={
                _.sum(
                  data?.content.slice(0, index)
                    .map((item: any) =>
                      ((item.type === 'text' ? item.content.length : 0) * perLetterDelay) +
                      (item.delay ? item.delay : 1000)
                    )
                )
              }
              size={'large'}
              messageIndex={index}
              isLoading={data?.content?.map(() => false)}
              setIsLoading={() => { }}
            />
          })
        }
      </div>
      <div
        style={{
          padding: '4em',
          margin: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <img src={EvolutionGraphic} alt='evolution' style={{ margin: '1em auto 1em auto', maxWidth: '100%', maxHeight: '50%' }} />
        <div style={{ background: 'white', minHeight: '3em', minWidth: '3em', height: "auto", margin: "1em auto 1em auto", padding: '1em' }}>
          <QRCode
            size={1000}
            style={{ height: "auto", maxWidth: "100%", width: "100%", maxHeight: '50vh' }}
            value={'https://link.helika.io/gdc-2024/gdc-helika-ai'}
            viewBox={`0 0 256 256`}
          />
        </div>
      </div>
    </div>
  )
}