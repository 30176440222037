import React, { useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Pagination as MuiPagination,
  useTheme,
} from '@mui/material'
import styled from 'styled-components'
import EngageCampaignTableRow from '../Campaigns/EngageCampaignTableRow'
import ReusableSnackbar from './ReusableSnackbar'
import { Colors } from 'helika-ui-sdk'
import { newColor } from 'src/consts/colors'
// types
import { IEngageCampaign } from 'src/types/engage'
import { KeyedObject } from 'src/utils/lib'

const Pagination = styled(MuiPagination)`
  .MuiButtonBase-root {
    &.MuiPaginationItem-root {
      background-color: ${newColor.tertiary};

      &.Mui-selected {
        background: linear-gradient(to right, #ea335f, #612ea6);
      }

      &.Mui-disabled {
        background-color: ${newColor.tertiary};
        opacity: 50%;
      }
    }
  }
`

const commonTextStyles = (themeMode: 'dark' | 'light') => ({
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '14px',
  color: themeMode === 'dark' ? newColor.white : newColor.black,
  alignItems: 'center',
  flex: 'none',
  flexGrow: 1,
})

interface ReusableTableProps<T> {
  data: T[]
  pagination: {
    page: number
    limit: number
    count: number
  }
  headers: {
    label: string
    align: 'left' | 'right'
  }[]
  refreshTable: () => Promise<void>
  onChangePagination: ({ page, limit }: { page: number; limit: number }) => void
  onClickEditCampaign: (campaign: IEngageCampaign) => void
}

const ReusableTable: React.FC<ReusableTableProps<any>> = ({
  data,
  pagination,
  headers,
  refreshTable,
  onChangePagination,
  onClickEditCampaign,
}) => {
  const [snackBar, setSnackBar] = useState<{
    open: boolean
    message?: string
    variant?: 'success' | 'error'
  }>({ open: false, message: '', variant: 'success' })

  const theme = useTheme()
  const themeMode = theme.palette.mode

  const handleChangePagination = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    onChangePagination({ ...pagination, page: value, limit: pagination.limit })
  }

  return (
    <Stack sx={{ position: 'relative' }}>
      <TableContainer sx={{ backgroundColor: 'transparent' }}>
        <Table sx={{ borderCollapse: 'separate', borderSpacing: '0' }}>
          <TableHead>
            <TableRow>
              {headers.map(
                (
                  header: {
                    label: string
                    align: 'left' | 'right'
                  },
                  index: number,
                ) => (
                  <TableCell
                    key={index}
                    sx={{
                      textAlign: header.align,
                      height: '60px',
                      borderBottom: `2px solid ${Colors.actionBar.borderColor}`,
                      '&:empty': {
                        borderBottom: `2px solid ${Colors.actionBar.borderColor}`,
                      },
                      ...commonTextStyles(themeMode),
                    }}
                  >
                    {header.label}
                  </TableCell>
                ),
              )}
              <TableCell
                align="right"
                sx={{
                  borderBottom: `2px solid ${Colors.actionBar.borderColor}`,
                }}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data.map((item: any) => {
                return (
                  <TableRow
                    key={item.id}
                    sx={{
                      backgroundColor: 'transparent',
                      height: '60px',
                      borderBottom: `1px solid ${Colors.blackAndWhite.grey600}`,
                    }}
                  >
                    <EngageCampaignTableRow
                      engageCampaign={item}
                      refetch={refreshTable}
                      onClickEditCampaign={onClickEditCampaign}
                    />
                  </TableRow>
                )
              })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={headers.length + 2}
                  align="center"
                  sx={commonTextStyles(themeMode)}
                >
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {pagination.count > pagination.limit && (
        <Stack alignItems="center" mt={3} sx={{ width: '100%' }}>
          <Pagination
            shape="rounded"
            count={Math.ceil(pagination.count / pagination.limit)}
            page={pagination.page}
            onChange={handleChangePagination}
          />
        </Stack>
      )}

      {/* ReusableSnackbar for notifications */}
      <ReusableSnackbar
        open={snackBar.open}
        message={snackBar.message as string}
        variant={snackBar.variant as 'success' | 'error'}
        handleClose={() => setSnackBar({ open: false })}
      />
    </Stack>
  )
}

export default ReusableTable
