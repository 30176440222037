import { blackAndWhite } from 'src/consts/colors'

export const HelikaTypographies = {
  fontFamily: ['Open Sans', 'sans-serif'].join(','),

  heading1: {
    fontSize: '36px',
    lineHeight: 1.3,
    fontWeight: 600,
    textTransform: 'capitalize',
    color: blackAndWhite.white,
  },
  heading2: {
    fontSize: '24px',
    lineHeight: 1.3,
    fontWeight: 600,
    textTransform: 'capitalize',
    color: blackAndWhite.white,
  },
  heading3: {
    fontSize: '20px',
    lineHeight: 1.4,
    fontWeight: 600,
    textTransform: 'capitalize',
    color: blackAndWhite.white,
  },
  heading4: {
    fontSize: '18px',
    lineHeight: 1.5,
    fontWeight: 600,
    textTransform: 'capitalize',
    color: blackAndWhite.white,
  },
  'label-large': {
    fontSize: '16px',
    lineHeight: 1.5,
    fontWeight: 600,
    textTransform: 'capitalize',
    color: blackAndWhite.white,
  },
  'label-regular': {
    fontSize: '14px',
    lineHeight: 1.4,
    fontWeight: 600,
    textTransform: 'capitalize',
    color: blackAndWhite.white,
  },
  'label-small': {
    fontSize: '12px',
    lineHeight: 1.4,
    fontWeight: 600,
    textTransform: 'capitalize',
    color: blackAndWhite.white,
  },
  'paragraph-regular': {
    fontSize: '14px',
    lineHeight: 1.4,
    fontWeight: 400,
    textTransform: 'none',
    color: blackAndWhite.white,
  },
  'paragraph-small': {
    fontSize: '12px',
    lineHeight: 1.4,
    fontWeight: 400,
    textTransform: 'none',
    color: blackAndWhite.white,
  },
}
