import React, { FC, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
// mui
import { IconButton, Stack, Typography } from '@mui/material'
import { ArrowBack as GoBackIcon } from '@mui/icons-material'
// components
import {
  HeaderImageWrapper,
  HeaderImage,
  RotatableButton,
} from './Projects.styled'
import {
  CreateHookButton,
  ReorganiseHooksButton,
  SearchInput,
  DraggableList,
  WorkflowEditor,
} from './components'
// libs
import { useRequests } from 'src/utils/lib'
import { useList, useOne } from 'src/utils/lib/queries'
import { GET_PROJECT_BY_ID, GET_HOOKS_BY_PROJECT_ID } from 'src/utils/lib/keys'
// types
import { IHook } from 'src/types/engage'
// assets
import { newColor } from 'src/consts/colors'
import { ReactComponent as CaratDownSvg } from 'src/assets/carat_down.svg'
import { ReactComponent as SettingSvg } from 'src/assets/setting_icon.svg'

const ProjectDetail: FC = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { update } = useRequests()
  const { data: project, isLoading } = useOne({
    queryKey: GET_PROJECT_BY_ID,
    resource: 'engage/internal/projects',
    message: 'Engage Project',
    id,
  })

  const [pagination, setPagination] = useState<{
    page: number
    limit: number
    count: number
  }>({ page: 1, limit: 5, count: 0 })
  const [searchQuery, setSearchQuery] = useState<string>('')

  const {
    data: hooks,
    isLoading: hooksLoading,
    refetch: fetchHooks,
  } = useList({
    queryKey: GET_HOOKS_BY_PROJECT_ID,
    resource: `engage/internal/projects/${id}/hooks`,
    message: `hooks for Project ID:${id}`,
    showNotification: true,
    meta: {
      page: pagination.page,
      page_size: pagination.limit,
      labels: searchQuery,
    },
  })

  useEffect(() => {
    setPagination({ ...pagination, count: hooks?.count || 0 })
  }, [hooks?.results])

  const [draftHooks, setDraftHooks] = useState<IHook[]>([])
  const [prevHooks, setPrevHooks] = useState<IHook[]>([])
  const [isShowHooksReorganiseBtn, setShowHooksReorganiseBtn] =
    useState<boolean>(false)
  const [isHooksReorganising, setHooksReorganising] = useState<boolean>(false)
  const [isProjectOpen, setProjectOpen] = useState<boolean>(false)
  const [showWorkflowEditor, setShowWorkflowEditor] = useState<boolean>(false) // State to control WorkflowEditor visibility
  const [selectedProjectHooks, setSelectedProjectHooks] = useState<
    IHook[] | null
  >(null) // State to hold selected hooks

  useEffect(() => {
    setPrevHooks([])
    setDraftHooks(hooks?.results)
  }, [hooks?.results])

  const handleSearch = (search: string) => {
    const castedQueries = search.trim().split(',')
    setSearchQuery(castedQueries.join(','))
  }

  const handleCreateHook = () => {
    setSelectedProjectHooks([]) // Clear selected hooks
    setShowWorkflowEditor(true) // Show WorkflowEditor when Create Hook is clicked
  }

  const handleGoToEditHook = (hook: IHook) => {
    setSelectedProjectHooks([hook]) // Set the hook to be edited
    setShowWorkflowEditor(true) // Show WorkflowEditor when Edit Hook is clicked
  }

  const handleChangePagination = ({
    page,
    limit,
  }: {
    page: number
    limit: number
  }) => {
    setPagination({ ...pagination, page, limit })
  }

  const handleChangeHooks = (_hooks: IHook[]) => {
    const updatedHooks = _hooks.map((hook: IHook, index: number) => ({
      ...hook,
      priority: hooks?.results?.[index].priority ?? hook.priority,
    }))

    setPrevHooks(draftHooks)
    setDraftHooks(updatedHooks)

    if (JSON.stringify(draftHooks) !== JSON.stringify(updatedHooks)) {
      setShowHooksReorganiseBtn(true)
    }
  }

  const handleSaveReorganisedHooks = async () => {
    try {
      setHooksReorganising(true)
      await Promise.all(
        draftHooks.map(async (hook: IHook) => {
          await update({
            resource: `engage/internal/projects/${hook.project_id}/hooks/${hook.id}`,
            payload: hook,
          })
        }),
      )
      setShowHooksReorganiseBtn(false)
      fetchHooks()
      toast.success(
        `Successfully reorganized hooks: ${draftHooks.map((hook) => hook.id).join(',')}`,
      )
    } catch (err) {
      setDraftHooks(prevHooks)
      if (err instanceof Error) {
        toast.error(`${err.message}`)
      } else {
        toast.error(`${err}`)
      }
    } finally {
      setHooksReorganising(false)
    }
  }

  // Conditionally render either the WorkflowEditor or the Project Detail UI
  if (showWorkflowEditor) {
    return <WorkflowEditor selectedProjectHooks={selectedProjectHooks} />
  }

  return (
    <Stack sx={{ minHeight: '100%' }}>
      <Stack
        direction="column"
        gap={1}
        sx={{
          maxWidth: { xs: '100%', md: '90%' },
          width: '100%',
          height: '100%',
          position: 'relative',
          margin: '0px auto',
          padding: 2,
        }}
      >
        <Stack
          direction="column"
          sx={{
            py: 2,
          }}
        >
          <Typography fontSize={14} fontWeight={400} color={newColor.jet}>
            {project?.result?.organization_name ?? ''}
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap={1}
          >
            <Stack direction="row" alignItems="center" gap={1} mt={2}>
              <IconButton
                onClick={() => navigate('/game_management/engage/campaigns')}
              >
                <GoBackIcon />
              </IconButton>
              <Typography fontSize={20} fontWeight={400}>
                Campagins
              </Typography>
            </Stack>

            <SearchInput onSearch={handleSearch} />
          </Stack>
        </Stack>

        <HeaderImageWrapper>
          <HeaderImage
            src="https://img-c1.sigmacomputing.com/c2fd0412-75b7-4698-a930-5277adc78305/de9e4d52-c229-4161-b434-2684ff96aa71"
            alt=""
          />
        </HeaderImageWrapper>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ pt: 4, pb: 1 }}
          gap={1}
        >
          <Stack direction="row" alignItems="center" gap={2}>
            <Typography fontSize={20} fontWeight={400}>
              Hooks
            </Typography>
            <Stack sx={{ mt: -0.5 }}>
              <SettingSvg />
            </Stack>
          </Stack>

          <Stack direction="row" alignItems="center" gap={1}>
            {isShowHooksReorganiseBtn && (
              <ReorganiseHooksButton
                loading={isHooksReorganising}
                onClick={handleSaveReorganisedHooks}
              />
            )}
            <CreateHookButton onClick={handleCreateHook} />
          </Stack>
        </Stack>

        <DraggableList
          hooks={draftHooks ?? []}
          fetchHooks={fetchHooks}
          onChangeHooks={handleChangeHooks}
          loading={hooksLoading}
          pagination={pagination}
          onChangePagination={handleChangePagination}
          goToEditHook={handleGoToEditHook}
        />
      </Stack>
    </Stack>
  )
}

export default ProjectDetail
