import { Pagination, Stack } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import _, { debounce } from "lodash";
import { useContext, useEffect, useState } from "react";
import { Button, FilterButton, SortButton } from 'helika-ui-sdk'
import { ReactComponent as InboxEmpty } from 'src/assets/inbox_empty.svg'
import { StyledSvg } from "src/components/agGrid/SupportDataGridHeaderComponent";
import { lightPalette, newColor } from "src/consts/colors";
import React from "react";
import { MOTDCampaignHeaders } from "src/utils/agGrid";
import {
    DataGridPro,
} from '@mui/x-data-grid-pro'
import {
    styled as muiStyled,
} from '@mui/material'
import HelikaSearchBar from "src/components/HelikaSearchBar";
import { editMOTDCampaign, getMOTDCampaigns } from "src/utils/api/queries";
import { Auth0Context } from "src/contexts/Auth0Context";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import LoadingComponent from "src/components/LoadingComponent";
import { color_base, colors_semantic } from "helika-ui-sdk/dist/src/values/Colors";
import ReusableSnackbar from "../RedemptionCode/components/ReusableSnackbar";
import CreateMOTDCampaignModal from "src/components/popups/CreateMOTDCampaignModal";
import EditMOTDCampaignModal from "src/components/popups/EditMOTDCampaignModal";
import ArchiveMOTDCampaignModal from "src/components/popups/ArchiveMOTDCampaignModal";

export default function Campaigns() {

    const theme = useTheme()
    const { getWithAccessToken, patchWithAccessToken } = useContext(Auth0Context)
    const { organization_id } = useSelector((state: any) => state.user)
    const [campaigns, setCampaigns] = useState<any[]>([])
    const [filteredCampains, setFilteredCampaigns] = useState<any[]>([])
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [sortField, setSortField] = useState<string>('created_at')
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc')
    const [openCreateCampaignModal, setOpenCreateCampaignModal] = useState<boolean>(false)
    const [openEditCampaignModal, setOpenEditCampaignModal] = useState<boolean>(false)
    const [openArchiveCampaignModal, setOpenArchiveCampaignModal] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [editLoading, setEditLoading] = useState<boolean>(false)
    const [activeFilter, setActiveFilter] = useState<string>('')
    const [snackbarMessage, setSnackbarMessage] = useState<string>('')
    const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>(
        'success',
    )
    // const [sort, setSort] = useState<string>('Created At Desc')
    // pagination
    const [pageSize, setPageSize] = useState<string>('10')
    const [pageNumber, setPageNumber] = useState<number>(1)
    const handlePaginationChange = (
        event: React.ChangeEvent<unknown>,
        page: number,
    ) => {
        setPageNumber(page)
    }

    const handlePageSizeChange = (
        event: React.ChangeEvent<HTMLSelectElement>,
    ) => {
        setPageSize(event.target.value)
    }

    const [selectedCampaign, setSelectedCampaign] = useState<any>({})

    async function toggleCampaignActive(campaign: any) {

        if (!campaign?.id) {
            console.log('Could not find campaign')
        }

        try {
            if (loading || editLoading) return
            setEditLoading(true)

            let updatedCampaign: any = {
                ...campaign,
                archived: !(campaign.archived),
                updated_at: new Date(),
            }

            await editMOTDCampaign(patchWithAccessToken, {
                ...updatedCampaign
            }).then((resp: any) => {
                if (!_.isEmpty(resp?.results)) {
                    onEditCampaign(resp?.results)
                    setSnackbarMessage('Campaign updated')
                    setSnackbarVariant('success')
                    setSnackbarOpen(true)
                    setEditLoading(false)
                } else {
                    throw new Error('Could not edit campaign')
                }
            })
        } catch (e: any) {
            setSnackbarMessage(e?.message)
            setSnackbarVariant('error')
            setSnackbarOpen(true)
            setEditLoading(false)
        }

    }

    const debouncedClickHandler = debounce(toggleCampaignActive, 200, { maxWait: 500 })


    const [columnDefs, setColumnDefs] = useState<any>(
        MOTDCampaignHeaders(
            campaigns,
            setSelectedCampaign,
            selectedCampaign,
            theme,
            setOpenEditCampaignModal,
            debouncedClickHandler
        ),
    )

    // show select column
    useEffect(() => {
        setColumnDefs(
            MOTDCampaignHeaders(
                campaigns,
                setSelectedCampaign,
                selectedCampaign,
                theme,
                setOpenEditCampaignModal,
                debouncedClickHandler
            ),
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaigns, selectedCampaign, setSelectedCampaign, theme])

    function CustomPagination(hasPageSizeSelector: boolean = false) {

        const SelectCustom = muiStyled('select')(({ theme }) => ({
            width: '60px',
            borderRadius: '3px',
            backgroundColor: theme.palette.mode === 'dark' ? colors_semantic.background_primary : 'white',
            border: '1px solid ',
            borderColor: colors_semantic.border_primary,
            color: colors_semantic.text_primary,
            padding: '0 5px',
            '&:focus, &:hover': {
                outline: newColor.indigo,
                borderWidth: '1px',
                BorderStyle: 'solid',
                borderColor: newColor.gunmetalGray
            },
        }))

        return (
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    margin: '15px 25px 0 0',
                    gap: '8px',
                    fontFamily: 'Open Sans, sans-serif'
                }}
            >
                <Pagination
                    count={
                        isNaN(campaigns?.length / Number(pageSize))
                            ? 1
                            : Math.ceil(campaigns?.length / Number(pageSize))
                    }
                    page={pageNumber}
                    onChange={handlePaginationChange}
                    shape="rounded"
                    sx={{
                        ul: {
                            '& .MuiPaginationItem-root': {
                                backgroundColor:
                                    theme.palette.mode === 'dark' ? newColor.tertiary : 'white',
                                fontFamily: 'Open Sans, sans-serif'
                            },
                            '& .MuiPaginationItem-root.Mui-selected': {
                                background: theme.palette.mode === 'dark' ? newColor.gunmetalGray : color_base.grey200,
                            },
                            '& .MuiPaginationItem-root.MuiPaginationItem-ellipsis': {
                                height: '32px!important',
                                display: 'flex',
                                justifyContent: 'center',
                                alignContent: 'end',
                                alignItems: 'center',
                                borderRadius: '5px!important',
                            },
                        },
                    }}
                />
                {
                    hasPageSizeSelector &&
                    <SelectCustom value={pageSize} onChange={handlePageSizeChange}>
                        <option defaultChecked value={5}>
                            5
                        </option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                    </SelectCustom>
                }
            </Box>
        );
    }

    //get campaigns
    useEffect(() => {
        async function getMOTDCampaignsApi() {
            setLoading(true)

            const data = await getMOTDCampaigns(getWithAccessToken, organization_id).catch((e: any) => {
                toast.error(e)
                setLoading(false)
            })
            if (data?.results) {
                setCampaigns(data?.results)
            }
            setLoading(false)
        }
        getMOTDCampaignsApi()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    useEffect(() => {
        setLoading(true)

        //search filter
        let updatedList = campaigns
        if (!_.isEmpty(searchTerm?.trim())) {
            updatedList = campaigns?.filter(campaign => campaign?.title?.toLocaleLowerCase()?.trim()?.includes(searchTerm?.toLocaleLowerCase()?.trim()))
        }

        //category filter
        let filteredList = [...updatedList]
        if (!_.isEmpty(activeFilter?.trim())) {
            filteredList = [...filteredList]?.filter(item => (item.archived && activeFilter === 'Not Active') || (!item.archived && activeFilter === 'Active'))
        }

        //sort by updated_ay date
        let sortedList = [...filteredList]
        if (!_.isEmpty(sortedList)) {
            sortedList = [...sortedList]?.sort((a: any, b: any) => {
                if (sortOrder === 'desc') {
                    return a?.[sortField]?.toString() < b?.[sortField]?.toString() ? 1 : -1
                } else {
                    return a?.[sortField]?.toString() >= b?.[sortField]?.toString() ? 1 : -1
                }
            })
        }

        setFilteredCampaigns(sortedList)
        setLoading(false)
    }, [campaigns, pageNumber, pageSize, searchTerm, activeFilter, sortOrder, sortField])

    async function onCreateCampaign(newCampaign: any) {
        setCampaigns((prevState: any[]) => {
            if (_.isEmpty(prevState)) return [newCampaign]
            return prevState.concat(newCampaign)
        })
        setSnackbarMessage('Campaign created')
        setSnackbarVariant('success')
        setSnackbarOpen(true)
    }

    async function onEditCampaign(editedCampaign: any) {
        setCampaigns((prevState: any[]) => {
            if (_.isEmpty(prevState)) return [editedCampaign]
            return prevState.map(campaign => {
                if (campaign.id === editedCampaign.id) return editedCampaign
                return campaign
            })
        })
        setSnackbarMessage('Campaign edited')
        setSnackbarVariant('success')
        setSnackbarOpen(true)
    }

    const NoCampaignsCTA =
        <Box
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'center'
            }}
        >
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '10em auto auto auto',
                    textAlign: 'center',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <StyledSvg
                    sx={{
                        '& svg, & path': {
                            fill: (theme.palette.mode === 'dark' ? newColor.white : newColor.black),
                        },
                        width: 'fit-content',
                        cursor: 'pointer',
                        margin: 'auto'
                    }}
                >
                    <InboxEmpty
                        style={{
                            cursor: 'pointer',
                        }}
                    />
                </StyledSvg>
                <Box
                    sx={{
                        fontFamily: "Open Sans",
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: '150%', /* 24px */
                    }}
                >
                    Create and manage campaigns
                </Box>
                <div
                    style={{
                        height: '8px'
                    }}
                />
                <Box
                    sx={{
                        fontFamily: "Open Sans",
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '140%', /* 16.8px */
                    }}
                >
                    Let's start creating your first campaign!
                </Box>
                <div
                    style={{
                        height: '16px'
                    }}
                />
                <Button
                    type="Primary"
                    handleClick={() => setOpenCreateCampaignModal(true)}
                    icon="Plus"
                    iconPosition="Left"
                >
                    Create New Campaign
                </Button>
            </Box>
        </Box >

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: 'fit-content',
                height: 'fit-content',
                maxHeight: 'calc(100vh - 11em)',
                padding: '2em',
                gap: '1em',
                background: theme.palette.mode === 'dark' ? colors_semantic.background_primary : lightPalette.bgPage,
                minWidth: '500px'
            }}
            className="open-sans-fontFam400"
        >
            <Box
                id='MOTDCampaignsManagementHeader'
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <Box
                    sx={{
                        fontSize: '24px !important',
                        lineHeight: '130%',
                        fontWeight: 600,
                        margin: 'auto 0 auto 0'
                    }}
                    className="open-sans-fontFam400"
                >
                    Message of the Day Campaigns
                </Box>
                {(loading) && (
                    <Stack
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            zIndex: 10000,
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <LoadingComponent />
                    </Stack>
                )}
                <ReusableSnackbar
                    open={snackbarOpen}
                    message={snackbarMessage}
                    variant={snackbarVariant}
                    handleClose={() => setSnackbarOpen(false)}
                />
                <CreateMOTDCampaignModal
                    open={openCreateCampaignModal}
                    setOpen={setOpenCreateCampaignModal}
                    onCreateCampaign={onCreateCampaign}
                />
                <EditMOTDCampaignModal
                    open={openEditCampaignModal}
                    setOpen={setOpenEditCampaignModal}
                    onEditCampaign={onEditCampaign}
                    selectedCampaign={selectedCampaign}
                />
                <ArchiveMOTDCampaignModal
                    open={openArchiveCampaignModal}
                    setOpen={setOpenArchiveCampaignModal}
                    onEditCampaign={onEditCampaign}
                    selectedCampaign={selectedCampaign}
                />
                <Button
                    type="Primary"
                    handleClick={() => setOpenCreateCampaignModal(true)}
                    icon="Plus"
                    iconPosition="Left"
                >
                    Create New
                </Button>
            </Box>
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%'
                    }}
                >

                    {
                        //search and filters
                    }
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <Box
                            sx={{
                                width: '20em',
                                maxWidth: '40%',
                                margin: 'auto 0 auto 0'
                            }}
                        >
                            <HelikaSearchBar
                                value={searchTerm}
                                setValue={setSearchTerm}
                                placeholder={'Search'}
                            />
                        </Box>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            sx={{ mt: '24px', mb: '24px' }}
                        >
                            <Stack direction="row" gap={2} alignItems="center">
                                <FilterButton
                                    darkMode={theme.palette.mode === 'dark'}
                                    filterFields={[
                                        { name: 'Category', options: ['', 'Status'] },
                                        { name: 'Is', options: ['Is'] },
                                        { name: 'FilterValue', options: ['', 'Active', 'Not Active'] }
                                    ]}
                                    onFilterChange={(updatedFilters: any) => {
                                        switch (updatedFilters?.Category) {
                                            case 'Status': {
                                                setActiveFilter(updatedFilters?.FilterValue)
                                                break;
                                            }
                                            default:
                                                setActiveFilter('')
                                                break;
                                        }
                                    }}
                                    onClearAll={() => {
                                        setActiveFilter('')
                                    }}
                                />
                                <SortButton
                                    sortFields={[
                                        { value: 'created_at', label: 'Created At' },
                                        { value: 'updated_at', label: 'Updated At' },
                                    ]}
                                    sortField={sortField}
                                    sortOrder={sortOrder}
                                    onSortChange={(field, order) => {
                                        setSortField(field);
                                        setSortOrder(order);
                                    }}
                                    darkMode={theme.palette.mode === 'dark'}
                                />
                            </Stack>
                        </Stack>
                    </Box>

                    {
                        // campaigns
                    }
                    {
                        loading
                            ? <Box sx={{ height: 'fit-content', width: 'fit-content', margin: 'auto' }}><LoadingComponent /></Box>
                            : _.isEmpty(filteredCampains)
                                ? NoCampaignsCTA
                                :
                                <Box
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        paddingTop: '2em',
                                        height: '100%',
                                        maxHeight: '100%'
                                    }}
                                >
                                    <DataGridPro
                                        autoHeight={false}
                                        rows={filteredCampains}
                                        columns={columnDefs}
                                        getRowId={(row: any) => {
                                            return row.id
                                        }}
                                        onCellClick={(item) => {
                                            setSelectedCampaign(item.row)
                                        }}
                                        sx={{
                                            border: 'none',
                                            "& .MuiDataGrid-columnHeaders": {
                                                minHeight: 'fit-content !important',
                                            },
                                            // Neutralize the hover colour (causing a flash)
                                            "& .MuiDataGrid-row.Mui-hovered": {
                                                backgroundColor: "transparent",
                                            },
                                            // Take out the hover colour
                                            "& .MuiDataGrid-row:hover": {
                                                backgroundColor: "transparent",
                                            },
                                            "& .MuiDataGrid-cell": {
                                                borderTop: 1,
                                                borderColor: colors_semantic.border_tertiary,
                                                borderBottom: 'none',
                                                paddingLeft: '12px !important',
                                                paddingRight: '12px !important',
                                                display: 'flex !important',
                                                flexDirection: 'row !important',
                                                justifyContent: 'center !important',
                                            },
                                            "& .MuiDataGrid-cellContent": {
                                                width: '100% !important',
                                                textAlign: 'left'
                                            },
                                            "& .MuiDataGrid-virtualScrollerContent": {
                                                border: 'none'
                                            },
                                            "& .MuiDataGrid-virtualScroller": {
                                                border: 'none'
                                            },
                                            "& .MuiDataGrid-main": {
                                                border: 'none'
                                            },
                                            "& .MuiDataGrid-footerContainer": {
                                                borderColor: colors_semantic.border_primary,
                                            },
                                        }}
                                        pageSizeOptions={[Number(pageSize)]}
                                        paginationModel={{
                                            pageSize: 10,
                                            page: pageNumber - 1
                                        }}
                                        paginationMode='client'
                                        pagination
                                        slots={{
                                            pagination: () => CustomPagination()
                                        }}
                                        disableRowSelectionOnClick
                                    />
                                </Box>
                    }
                </Box>

            </Box>
        </Box >
    )
}