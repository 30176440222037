
import { useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { experimentsTableHeaders } from "src/utils/agGrid";
import CircularPageHeader from "../atoms/v2/circularPageHeader";
import Experiment from 'src/assets/experiment.svg'
import { Auth0Context } from "src/contexts/Auth0Context";
import { getGameConfig } from "src/utils/api/queries";
import _ from "lodash";
import moment from "moment";

export default function Experiments({ setSgs }: { setSgs: any }) {

  const theme: any = useTheme()
  let headers: any = experimentsTableHeaders(theme, setSgs)
  const [experiments, setExperiments] = useState<any[]>([])

  const { getWithAccessToken } = useContext(Auth0Context)

  const [initialConfig, setInitialConfig] = useState<any>();

  useEffect(() => {
    async function getLatestConfig() {
      if (!getWithAccessToken) return
      let resp = await getGameConfig(getWithAccessToken, {
        helika_api_key: process.env.REACT_APP_EVENTS_API_KEY,
        segment_ids: ["0"]
      })
      if (!resp || !resp.res || _.isEmpty(resp.res)) return;
      setInitialConfig(resp.res[0])
    }

    getLatestConfig();
  }, [getWithAccessToken])

  useEffect(() => {
    setExperiments([
      {
        id: 1,
        title: 'ARPU Ad Impressions Test',
        type: 'Pre/Post',
        status: 'Active',
        audience: 'Shrimp',
        traffic: '100%',
        observations: '45',
        created: {
          date: initialConfig ? moment.utc(initialConfig?.created_at).utcOffset(moment().utcOffset()).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD'),
          created_by: 'Cori G'
        },
        summary: 'Dashboards'
      },
      {
        id: 2,
        title: 'Season 3 Loot Updates',
        type: 'A/B Test',
        status: 'Complete',
        audience: 'F2P',
        traffic: '10%%, Random Sampling 50/50 Split',
        observations: '12,312 / 12,423',
        created: {
          date: '2024-03-10',
          created_by: 'Tory R'
        },
        summary: 'Dashboards'
      },
      {
        id: 3,
        title: 'Arena Rewards Distribution',
        type: 'Multi-Variant',
        status: 'Complete',
        audience: 'Hardcore',
        traffic: '25%, 33/33/33 split',
        observations: '5,343 / 4,923 / 5123',
        created: {
          date: '2024-02-25',
          created_by: 'Luke F'
        },
        summary: 'Dashboards'
      }
    ])
  }, [initialConfig])


  return (
    <div
      style={{
        height: 'fit-content',
        textAlign: 'center',
        padding: '2em',
        minWidth: '60em',
        maxWidth: '100%',
        margin: 'auto'
      }}
    >
      <CircularPageHeader
        text="EXPERIMENTS"
        icon={Experiment}
        sx={{ padding: 0, paddingLeft: '1em', marginBottom: '1em' }}
      />
      <DataGridPro
        rows={experiments}
        columns={headers}
        getRowId={(row: any) => {
          return row.id
        }}
        // hideFooter={true}
        getRowHeight={() => 'auto'}
        style={{
        }}
        getCellClassName={(params) => 'experiments-cell'}
        sx={{
          width: '100%',
          '&:hover .highlightExperiment': {
            backgroundColor: '#6926ad99'
          },
        }}
        getRowClassName={(row) => {
          return row.id === 1 ? 'highlightExperiment' : ''
        }}
      />
    </div>
  )
}