import React, { FC, useEffect, useState } from 'react'
// project import
import EngageCampaignsLayout from './Layout'
import CampaignModal from '../components/CampaignModal'
import { LoadingOverlay, NoDataAvailable, ReusableTable } from '../components'
// queries & mutations
import { useRequests } from 'src/utils/lib'
// types
import { IEngageCampaign } from 'src/types/engage'

const EngageCampaigns: FC = () => {
  const { getList } = useRequests()

  const [snackBar, setSnackBar] = useState<{
    open: boolean
    message: string
    variant: 'success' | 'error'
  }>({ open: false, message: '', variant: 'success' })

  const [campaigns, setCampaigns] = useState<IEngageCampaign[]>([])
  const [filteredDataItems, setFilteredDataItems] = useState<IEngageCampaign[]>(
    [],
  )
  const [tableControllers, setTableControllers] = useState<{
    page: number
    limit: number
    count: number
    labels: string[]
    loading: boolean
  }>({
    page: 1,
    limit: 10,
    count: 0,
    labels: [],
    loading: false,
  })

  const [campaignModal, setCampaignModal] = useState<{
    open: boolean
    mode: 'create' | 'edit'
    data?: IEngageCampaign | undefined
  }>({ open: false, mode: 'create', data: undefined })

  const getEngageCampaigns = async ({
    page = 1,
    limit = 10,
    labels = [],
  }: {
    page: number
    limit: number
    labels?: string[]
  }) => {
    try {
      setTableControllers((prevState) => ({ ...prevState, loading: true }))
      const labelsParam = labels.length ? `&labels=${labels.join(',')}` : ''
      const apiCampaigns = await getList({
        resource: 'engage/internal/projects',
        rawQueryUrl: `page=${page}&page_size=${limit}${labelsParam}`,
      })
      if (apiCampaigns?.results) {
        setCampaigns(apiCampaigns.results)
        setFilteredDataItems(apiCampaigns.results)
        setTableControllers((prev) => ({
          ...prev,
          count: apiCampaigns.count,
        }))
      }
    } catch (error) {
      setSnackBar({
        message: 'Failed to fetch engage campaigns',
        variant: 'error',
        open: true,
      })
    } finally {
      setTableControllers((prevState) => ({ ...prevState, loading: false }))
    }
  }

  useEffect(() => {
    ;(async () => {
      await getEngageCampaigns({
        page: tableControllers.page,
        limit: tableControllers.limit,
        labels: tableControllers.labels,
      })
    })()
  }, [])

  const handleOpenCreateModal = () => {
    setCampaignModal({
      open: true,
      mode: 'create',
    })
  }

  const handleOpenEditModal = (campaign: IEngageCampaign) => {
    setCampaignModal({
      open: true,
      mode: 'edit',
      data: campaign,
    })
  }

  const handleCloseModal = async (refreshTable: boolean = false) => {
    setCampaignModal((prev) => ({ ...prev, open: false, data: undefined }))
    if (refreshTable) {
      await getEngageCampaigns({
        page: tableControllers.page,
        limit: tableControllers.limit,
        labels: tableControllers.labels,
      })
    }
  }

  const handleChangePagination = async ({
    page,
    limit,
  }: {
    page: number
    limit: number
  }) => {
    await getEngageCampaigns({ page, limit })
    setTableControllers((prev) => ({ ...prev, page, limit }))
  }

  const handleRefreshTable = async () => {
    await getEngageCampaigns({ page: 1, limit: 10 })
    setTableControllers((prev) => ({ ...prev, page: 1, limit: 10 }))
  }

  const headers = [
    { label: 'Priority', align: 'left' },
    { label: 'Name', align: 'left' },
    { label: 'Labels', align: 'right' },
    { label: 'Status', align: 'left' },
  ] as {
    label: string
    align: 'left' | 'right'
  }[]

  return (
    <EngageCampaignsLayout onCreate={handleOpenCreateModal}>
      <LoadingOverlay loading={tableControllers.loading} />
      {filteredDataItems.length > 0 ? (
        <ReusableTable
          data={filteredDataItems}
          pagination={{
            page: tableControllers.page,
            limit: tableControllers.limit,
            count: tableControllers.count,
          }}
          headers={headers}
          refreshTable={handleRefreshTable}
          onChangePagination={handleChangePagination}
          onClickEditCampaign={handleOpenEditModal}
        />
      ) : (
        <NoDataAvailable
          title="Create and manage hooks"
          description="Start creating a campaign to add hooks."
          buttonText="Create New Campaign"
          onCreate={handleOpenCreateModal}
        />
      )}

      <CampaignModal
        open={campaignModal.open}
        isEdit={campaignModal.mode === 'edit'}
        campaignData={campaignModal.data}
        onClose={handleCloseModal}
      />
    </EngageCampaignsLayout>
  )
}

export default EngageCampaigns
