import React, { FC, useEffect, useState } from 'react'
import {
  Button,
  Divider,
  MenuItem,
  Menu,
  Stack,
  TableCell,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { ControlPoint, Delete, Edit } from '@mui/icons-material'
import { DraggableProvided } from '@hello-pangea/dnd'
import { toast } from 'react-toastify'
import { debounce } from 'lodash'
import { RefetchOptions, QueryObserverResult } from '@tanstack/react-query'
// components
import NumericInput from './NumericInput'
import Chips from './Chips'
import ToggleSwitch from './ToggleSwitch'
import AddNewLabelModal from './AddNewLabelModal'
import RemoveHookConfirmModal from './RemoveHookConfirmModal'
// hooks
import { useRequests } from 'src/utils/lib'
// types
import { KeyedObject } from 'src/utils/lib'
import { IHook } from 'src/types/engage'
// assets
import { newColor } from 'src/consts/colors'
import { ReactComponent as DraggableMoveSvg } from 'src/assets/move_icon.svg'
import { ReactComponent as ActionSvg } from 'src/assets/action_icon.svg'

const SaveButton = styled(Button)(({ theme }) => ({
  backgroundColor: newColor.jazzberryJam,
  color: 'white',
  fontSize: '12px',
  fontWeight: '400',
  borderRadius: '5px',
  padding: '4px 8px',
  textTransform: 'capitalize',
  '&:hover': {
    backgroundColor: '#E91E63',
  },
}))

export type HookItemProps = {
  provided: DraggableProvided
  hook: IHook
  onEditHook: (hook: IHook) => void
  fetchHooks: (
    options?: RefetchOptions | undefined,
  ) => Promise<QueryObserverResult<any, Error>>
}

const HookItem: FC<HookItemProps> = ({
  provided,
  hook,
  fetchHooks,
  onEditHook,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [priority, setPriority] = useState<number>(0)
  const [isShowSaveBtn, setShowSaveBtn] = useState<boolean>(false)
  const [isShowAddLabelModal, setShowAddLabelModal] = useState<boolean>(false)
  const [isShowRemoveHookConfirmModal, setShowRemoveHookConfirmModal] =
    useState<boolean>(false)
  const { update, delete: removeHook } = useRequests()

  useEffect(() => {
    setPriority(hook.priority)
  }, [JSON.stringify(hook)])

  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClosePopover = () => {
    setAnchorEl(null)
  }

  const handleEditHook = () => {
    onEditHook(hook)
    handleClosePopover()
  }

  const handleChangePriority = (_priority: number) => {
    setPriority(_priority)
    debounce(() => {
      if (_priority != hook.priority) {
        setShowSaveBtn(true)
      } else {
        setShowSaveBtn(false)
      }
    }, 500)()
  }

  const handleSavePriority = async () => {
    const resource = `engage/internal/projects/${hook.project_id}/hooks/${hook.id}/priority`
    const payload: KeyedObject = {
      priority: priority,
    }

    try {
      await update({ resource, payload })
      fetchHooks()
      toast.success(`Successfully updated priority of hook id:${hook.id}`)
      setShowSaveBtn(false)
    } catch (err) {
      if (err instanceof Error) {
        toast.error(`${err.message}`)
      } else {
        toast.error(`${err}`)
      }
    }
  }

  const handleChangeStatus = async () => {
    const resource = `engage/internal/projects/${hook.project_id}/hooks/${hook.id}/status`
    const payload: KeyedObject = {
      active: !hook.active,
    }

    try {
      await update({ resource, payload })
      fetchHooks()
      toast.success(`Successfully updated status of hook id:${hook.id}`)
    } catch (err) {
      if (err instanceof Error) {
        toast.error(`${err.message}`)
      } else {
        toast.error(`${err}`)
      }
    }
  }

  const handleAddLabel = async (label: string) => {
    await update({
      resource: `engage/internal/projects/${hook.project_id}/hooks/${hook.id}/labels`,
      payload:
        hook?.labels && Array.isArray(hook.labels)
          ? [...hook.labels, label]
          : [label],
    })

    fetchHooks()
  }

  const handleRemoveLabel = async (label: string) => {
    try {
      await update({
        resource: `engage/internal/projects/${hook.project_id}/hooks/${hook.id}/labels`,
        payload:
          hook?.labels && Array.isArray(hook.labels)
            ? hook.labels.filter((_label) => _label != label)
            : [],
      })

      fetchHooks()

      toast.success(`Successfully removed new label: ${label}`)
    } catch (err) {
      if (err instanceof Error) {
        toast.error(`${err.message}`)
      } else {
        toast.error(`${err}`)
      }
    }
  }

  const handleRemoveHook = async () => {
    await removeHook({
      resource: `engage/internal/projects/${hook.project_id}/hooks/${hook.id}`,
    })

    fetchHooks()
  }

  const open = Boolean(anchorEl)
  const id = open ? 'hook-item-popover' : undefined

  return (
    <>
      <TableCell scope="row" {...provided.dragHandleProps}>
        <DraggableMoveSvg />
      </TableCell>
      <TableCell sx={{ width: '86px' }}>
        <Stack direction="row" alignItems="center" gap={1}>
          <NumericInput value={priority} onChange={handleChangePriority} />
          {isShowSaveBtn && (
            <SaveButton onClick={handleSavePriority}>Save</SaveButton>
          )}
        </Stack>
      </TableCell>
      <TableCell>{hook.name}</TableCell>
      <TableCell align="right">
        {hook?.labels && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            flexWrap="wrap"
            gap={1}
          >
            {hook.labels.map((label: string) => (
              <Chips key={label} label={label} onClose={handleRemoveLabel} />
            ))}
          </Stack>
        )}
      </TableCell>
      <TableCell align="right" sx={{ width: '64px' }}>
        <ToggleSwitch checked={hook.active} onChange={handleChangeStatus} />
      </TableCell>
      <TableCell sx={{ width: '34px' }}>
        <Button sx={{ p: 0, minWidth: '34px' }} onClick={handleOpenPopover}>
          <ActionSvg />
        </Button>
      </TableCell>
      <Menu
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          elevation: 2,
          sx: {
            minWidth: '120px',
            backgroundColor: newColor.charcoal,
            borderRadius: '8px',
            '& .MuiMenuItem-root': {
              fontSize: '14px',
              fontWeight: 400,
              padding: '4px 16px',
              '&:hover': {
                backgroundColor: newColor.jet,
              },
            },
          },
        }}
      >
        <MenuItem onClick={handleEditHook}>
          <Edit sx={{ fontSize: 14 }} />
          &nbsp;&nbsp;Edit Hook
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClosePopover()
            setShowRemoveHookConfirmModal(true)
          }}
        >
          <Delete sx={{ fontSize: 14 }} />
          &nbsp;&nbsp;Remove Hook
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            handleClosePopover()
            setShowAddLabelModal(true)
          }}
        >
          <ControlPoint sx={{ fontSize: 14 }} />
          &nbsp;&nbsp;Add Label
        </MenuItem>
      </Menu>

      <AddNewLabelModal
        open={isShowAddLabelModal}
        onClose={() => setShowAddLabelModal(false)}
        onConfirm={handleAddLabel}
      />
      <RemoveHookConfirmModal
        open={isShowRemoveHookConfirmModal}
        onClose={() => setShowRemoveHookConfirmModal(false)}
        onConfirm={handleRemoveHook}
      />
    </>
  )
}

export default HookItem
