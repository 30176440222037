import { Stack } from "@mui/material";
import React from "react";
import "@fontsource/open-sans"; // Defaults to weight 400
import { spacing } from "helika-ui-sdk/dist/src/values/Spacing";
import { colors_semantic } from "helika-ui-sdk/dist/src/values/Colors";
import { typography } from "helika-ui-sdk/dist/src/values/Typography";

export default function Breadcrumb({
    darkMode = true,
    items
}: {
    darkMode?: boolean,
    items: {
        isLink?: boolean,
        string: string,
        link?: string
    }[]
}) {

    const interleave = (arr: any[], x: any) => arr.flatMap(e => [e, x]).slice(0, -1)

    return (
        <Stack
            direction={'row'}
            gap={spacing.Spacing_xxxs}
            sx={{
                whiteSpace: 'nowrap'
            }}
        >
            {
                interleave(items, { isLink: false, string: '/', isSeperator: true }).map(item => {
                    if (item.isLink) {
                        return <a
                            href={item.link} target="_self"
                            style={{
                                color: colors_semantic.text_secondary,
                                ...typography.paragraph_small,
                                cursor: 'pointer',
                                textDecoration: 'none'
                            }}
                        >
                            {item.string}
                        </a>
                    } else if (item.isSeperator) {
                        return <p
                            style={{
                                color: colors_semantic.text_secondary,
                                ...typography.paragraph_small,
                                padding: 0,
                                margin: 0
                            }}
                        >
                            {item.string}
                        </p>
                    }
                    return <div
                        style={{
                            color: colors_semantic.text_primary,
                            ...typography.label_small
                        }}
                    >
                        {item.string}
                    </div>
                })
            }
        </Stack>
    );
};