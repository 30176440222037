import { Switch } from '@mui/material'
import { styled } from '@mui/system'
import { newColor } from 'src/consts/colors' // Ensure you are importing your color constants

// CustomSwitch component for toggle functionality with custom styles
const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 22,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(16px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: newColor.white,
      '& + .MuiSwitch-track': {
        backgroundColor: newColor.jazzberryJam,
        opacity: 1,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    width: 22,
    height: 22,
    boxShadow: 'none',
    backgroundColor: newColor.gainsboro,
  },
  '& .MuiSwitch-track': {
    borderRadius: 13,
    backgroundColor: newColor.darkGray,
    opacity: 1,
    position: 'relative',
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 12,
      height: 12,
    },
    '&::before': {
      left: 8,
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="12" width="12" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        newColor.gray,
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
    },
    '&::after': {
      right: 8,
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="12" width="12" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        newColor.white,
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      display: 'none',
    },
  },
  '& .Mui-checked + .MuiSwitch-track:before': {
    display: 'none',
  },
  '& .Mui-checked + .MuiSwitch-track:after': {
    display: 'block',
    right: 10,
  },
}))

export default CustomSwitch
