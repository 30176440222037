import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { HELIKA_LOGO_URL } from "../../utils/string";
import { newColor } from '../../consts/colors'
import _ from 'lodash';
import { Box, Button, Grid, MenuItem, Select, useTheme } from '@mui/material';
import CircularPageHeader from '../atoms/v2/circularPageHeader';
import Config from 'src/assets/config.svg'
import { StyledSvg } from '../organisms/v2/tabs/styled';
import { ReactComponent as Gear } from 'src/assets/settings.svg'
import { ReactComponent as Segment } from 'src/assets/segment.svg'

export function ChatWebform({ fields }: { fields?: any }): React.ReactElement {

  const theme: any = useTheme();

  const chatController = useSelector((state: any) => state.llmChat.chatController)
  const [input, setInput] = useState<string>('');
  const [typeInput, setTypeInput] = useState<string>('');
  const [segment, setSegment] = useState<string>('Shrimp');
  let defaultResponse: any = {}
  if (fields) {
    let tempFields = Object.entries(fields);
    tempFields.forEach((entry: any) => {
      if ('default' in entry[1]) {
        let newObject: any = {}
        newObject[`${entry[0]}`] = entry[1].default
        defaultResponse = Object.assign({}, defaultResponse, newObject);
      }
    })
  }
  const [responseData, setResponseData] = useState<any>(defaultResponse);


  async function submitHandler() {
    await chatController?.addMessage({
      type: 'text',
      content: [
        {
          content: _.isEmpty(fields)
            ? JSON.stringify({
              input: input,
              type: typeInput
            })
            : `You selected: ${JSON.stringify(responseData)}`,
          type: 'text',
        }
      ],
      self: false,
      avatar: HELIKA_LOGO_URL
    })
  }

  function setResponseHandler(e: any, field: any) {
    setResponseData((prevState: any) => {
      let newData: any = {}
      newData[field[1].title] = e.target.value
      return Object.assign({}, prevState, newData)
    })
  }

  if (fields) return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'left',
        margin: 'auto',
        flex: 1,
        padding: '1em',
        minWidth: '60em'
      }}
    >

      <CircularPageHeader
        text="LIVE OPS CONFIGURATION MANAGER"
        icon={Config}
        sx={{ padding: 0, paddingLeft: '1em', marginBottom: '1em' }}
      />

      <Box
        sx={{
          borderRadius: '0.5em',
          display: 'flex',
          flexDirection: 'column',
          background: theme.palette.mode === 'dark' ? newColor.midnight : 'white',
          borderStyle: 'solid',
          borderColor: newColor.outerSpace,
          borderWidth: '1px',
        }}
      >
        <Grid
          container
          style={{ width: '100%', padding: '1em 2em 1em 2em', height: 'fit-content' }}
        >
          <Grid item xs={8} style={{ display: 'flex', flexDirection: 'row', textAlign: 'left', lineHeight: '1em', margin: 'auto 0 auto 0' }}>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center'
              }}
            >
              <StyledSvg
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Segment style={{ paddingRight: '0.2em' }} />
              </StyledSvg>
              <div
                style={{ paddingLeft: '0.2em', paddingTop: '0.15em' }}
              >
                SEGMENT
              </div>
            </div>
          </Grid>
          <Grid item xs={4} style={{ padding: '0 1em 0 0.5em', textAlign: 'center' }}>
            <Select
              id="segment-selector"
              value={segment}
              size="small"
              onChange={(event: any) =>
                setSegment(event.target.value as string)
              }
              style={{ height: '2em', width: '100%' }}
            >
              <MenuItem value={'Shrimp'}>Shrimp</MenuItem>
              <MenuItem value={'Dolphin'}>Dolphin</MenuItem>
              <MenuItem value={'Whale'}>Whale</MenuItem>
            </Select>
          </Grid>
        </Grid>
      </Box>

      <Box
        display={'flex'}
        flexDirection={'row'}
        flexWrap={'nowrap'}
        sx={{
          padding: '0.2em',
          marginTop: '1em'
        }}
        className={
          theme.palette.mode === 'dark'
            ? 'circularPageHeading dark'
            : 'circularPageHeading light'
        }
      >
        <Grid
          container
          style={{ width: '100%', height: 'fit-content', fontSize: '0.7em', padding: '0 2em 0 2em' }}
        >
          <Grid item xs={4} style={{ textAlign: 'center' }}>
          </Grid>
          <Grid item xs={4} style={{ textAlign: 'center' }}>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center'
              }}
            >
              <StyledSvg
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Gear style={{ paddingRight: '0.2em' }} />
              </StyledSvg>
              <div
                style={{ paddingLeft: '0.2em' }}
              >
                CURRENT CONFIG
              </div>
            </div>

          </Grid>
          <Grid item xs={4} style={{ textAlign: 'center' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center'
              }}
            >
              <StyledSvg
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Gear style={{ paddingRight: '0.2em' }} />
              </StyledSvg>
              <div
                style={{ paddingLeft: '0.2em' }}
              >
                CHANGES
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>


      <Box
        sx={{
          borderRadius: '0.5em',
          display: 'flex',
          flexDirection: 'column',
          background: theme.palette.mode === 'dark' ? newColor.midnight : 'white',
          borderStyle: 'solid',
          borderColor: newColor.outerSpace,
          borderWidth: '1px',
          marginTop: '1em',
          padding: '0.5em 1em 0.5em 1em'
        }}
      >

        {
          Object.entries(fields).filter(field => !_.isEmpty(field[0]) && !_.isEmpty(field[1])).map((field: any[]) => {
            return (

              <Grid
                key={`${field[1].name}_${field[0]}`}
                container
                style={{ width: '100%', margin: '0 auto 0 auto', height: 'fit-content', padding: '1em' }}
              >
                <Grid item xs={4} style={{ paddingRight: '0.5em', paddingBottom: '0.5em' }}>
                  <div
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      minWidth: '10em',
                      width: '100%',
                      maxWidth: '20em',
                      background: theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.lightGray,
                      borderRadius: '100vmax',
                      padding: '0 2em 0 2em'
                    }}
                  >
                    {field[1]?.title}
                  </div>
                </Grid>
                <Grid item xs={4} style={{ paddingRight: '0.5em', paddingBottom: '0.5em' }}>
                  {
                    field[1].type === 'text'
                      ?
                      <div
                        style={{
                          padding: '0 1em 0 1em'
                        }}
                      >
                        <input
                          type="text"
                          style={{
                            minWidth: '10em',
                            width: '100%',
                            maxWidth: '20em',
                            backgroundColor: theme.palette.background.default,
                            color: theme.palette.text.primary,
                            borderRadius: '100vmax',
                            display: 'flex',
                            padding: '0.5em 1em 0.5em 1em',
                            border: 'solid',
                            borderWidth: '1px',
                            borderColor: newColor.gunMetal,
                            outline: '1px white',
                            boxShadow: 'inset 0px'
                          }}
                          defaultValue={field[1]?.default ? field[1]?.default : null}
                          onChange={(e) => setResponseHandler(e, field)}
                        />
                      </div>
                      :
                      <div
                        style={{
                          padding: '0 1em 0 1em'
                        }}
                      >
                        <Select
                          size="small"
                          onChange={(e) => setResponseHandler(e, field)}
                          style={{ height: '2em', width: '100%' }}
                          value={responseData[field[1].title]}
                        >
                          {
                            field[1]?.options.map((option: string) => {
                              return <MenuItem
                                key={option}
                                value={option}
                              >{option}</MenuItem>
                            })
                          }
                        </Select>
                      </div>
                  }
                </Grid>
                <Grid item xs={4} style={{ paddingRight: '0.5em', paddingBottom: '0.5em' }}>

                  {
                    field[1].type === 'text'
                      ?
                      <div
                        style={{
                          padding: '0 1em 0 1em'
                        }}
                      >
                        <input
                          type="text"
                          style={{
                            minWidth: '10em',
                            width: '100%',
                            maxWidth: '20em',
                            backgroundColor: newColor.lightGray,
                            color: theme.palette.text.primary,
                            borderRadius: '100vmax',
                            display: 'flex',
                            padding: '0.5em 1em 0.5em 1em',
                            border: 'none',
                            background: theme.palette.mode === 'dark' ? newColor.gunMetal : newColor.lightGray,
                            outline: '1px white',
                            boxShadow: 'inset 0px',
                            cursor: 'not-allowed'
                          }}
                          value={field[1]?.previousValue}
                          disabled={true}
                        />
                      </div>
                      :
                      <div
                        style={{
                          padding: '0 1em 0 1em'
                        }}
                      >
                        <Select
                          size="small"
                          onChange={(e) => setResponseHandler(e, field)}
                          style={{ height: '2em', width: '100%' }}
                          value={field[1]?.previousValue}
                          disabled={true}
                        >
                          {
                            field[1]?.options.map((option: string) => {
                              return <MenuItem
                                key={option}
                                value={option}
                              >{option}</MenuItem>
                            })
                          }
                        </Select>
                      </div>
                  }
                </Grid>
              </Grid>
            )
          })
        }
      </Box>

      <Button
        style={{
          marginTop: '2em',
          width: '10em',
          margin: '1em auto 1em auto',
          color: 'black',
          borderRadius: '0.75em'
        }}
        onClick={submitHandler}
        className='llm-button-gradient'
      >
        Submit
      </Button>
    </div >
  )

  return <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'left',
      textAlign: 'left',
      margin: 'auto',
      minWidth: '30em'
    }}
  >
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
      }}
    >
      <label>
        Name:
      </label>
      <input type="text" name="name" value={input} onChange={(e) => { setInput(e.target.value) }} />
    </div>
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: '0.5em'
      }}
    >
      <label
        style={{
          marginRight: '2em'
        }}
      >
        Optimization Type:
      </label>
      <input type="text" name="name" value={typeInput} onChange={(e) => { setTypeInput(e.target.value) }} />
    </div>

    <button
      style={{
        marginTop: '2em'
      }}
      onClick={submitHandler}
      className='llm-button-gradient'
    >
      Submit
    </button>
  </div>
}