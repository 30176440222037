import React, { useState } from 'react';
import { Box, useTheme } from '@mui/material';
import { ChatController, MuiSelectInput, SelectActionRequest, TextActionRequest } from 'src/utils/chat/src';
import { MuiTextInput } from './ChatTextInput';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from 'src/state';
import _ from 'lodash';
import { ConversationList } from './ConversationList';
import { MessagesList } from './MessagesList';

export function ChatComponent({
  chatController,
  suggestions
}: React.PropsWithChildren<{
  chatController: ChatController,
  suggestions?: any;
}>): React.ReactElement {

  const theme = useTheme()
  const dispatch = useDispatch()
  const { setLlmState } = bindActionCreators(actionCreators, dispatch)
  const isLlmExpanded = useSelector((state: any) => state.llmChat.isExpanded)
  const isHistoryExpanded = useSelector((state: any) => state.llmChat.isHistoryExpanded)
  const [actReq, setActReq] = useState(chatController?.getActionRequest());
  const [sgs, setSgs] = useState(suggestions);

  function onClickHandlerMinMaxChatWindow() {
    if (isLlmExpanded) {
      setLlmState({
        type: 'SET_MINIMIZED',
      })
    } else {
      setLlmState({
        type: 'SET_EXPANDED',
      })
    }
  }

  function onClickHandlerShowConversationList() {
    if (isHistoryExpanded) {
      setLlmState({
        type: 'SET_HISTORY_MINIMIZED',
      })
    } else {
      setLlmState({
        type: 'SET_HISTORY_EXPANDED',
      })
    }
  }

  async function onClickHandlerSuggestion(suggestion: any) {
    await chatController.addMessage({
      type: 'text',
      content: [
        {
          content: suggestion.text,
          type: 'text',
        }
      ],
      self: true,
      avatar: undefined
    });
    setSgs([]);
  }

  return (
    <Box
      id='ChatComponent'
      className='llmChat'
      sx={{
        bgcolor: theme.palette.mode === 'dark' ? 'black' : 'white',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        })
      }}
    >
      {/* chat header */}
      <Box
        id='chat-header'
      >
        <div
          onClick={onClickHandlerMinMaxChatWindow}
          className='llm-button-gradient'
        >
          {isLlmExpanded ? 'MINIMIZE' : 'MAXIMIZE'}
        </div>
        {
          process.env.REACT_APP_ENVIRONMENT !== 'PROD' &&
          <div
            onClick={onClickHandlerShowConversationList}
            className='llm-button-gradient'
          >
            {isHistoryExpanded ? 'HIDE CONVERSATION LIST' : 'SEE CONVERSATION LIST'}
          </div>
        }
      </Box>
      {/* convo list */}
      <Box
        id='convo-list'
        sx={{
          '& > *': {
            maxWidth: '100%',
          },
          //todo: get new image from miriam
        }}
      >

        {
          process.env.REACT_APP_ENVIRONMENT !== 'PROD' &&
          <ConversationList />
        }
        {/* convo */}
        <MessagesList
          chatController={chatController}
          suggestions={suggestions}
          setActReq={setActReq}
          setSgs={setSgs}
        />
      </Box>
      {/* suggestions */}
      <Box id="suggestions-container">
        {
          !_.isEmpty(sgs) && <div style={{ margin: 'auto 0.5em auto 0', padding: '0.5em 0 0 0', color: 'gray', maxWidth: '5em' }}>Try:</div>
        }
        {
          !_.isEmpty(sgs) && sgs.map((suggestion: any, index: number) => {
            return (
              <div
                key={index}
                className='suggestions'
                style={{
                  whiteSpace: 'pre-wrap',
                }}
                onClick={() => onClickHandlerSuggestion(suggestion)}
              >
                {suggestion.text}
              </div>
            )
          })
        }
      </Box>
      {/* convo inputs */}
      <Box
        sx={{
          flex: '0 1 auto',
          display: 'flex',
          alignContent: 'flex-end',
          '& > *': {
            minWidth: 0,
          }
        }}
      >
        {actReq && actReq.type === 'select' && (
          <MuiSelectInput
            chatController={chatController}
            actionRequest={actReq as SelectActionRequest}
          />
        )}
        {actReq && actReq.type === 'text' && (
          <MuiTextInput
            chatController={chatController}
            actionRequest={actReq as TextActionRequest}
          />
        )}
      </Box>
    </Box>
  );
}