import React from 'react'
import { IconButton, Stack, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import styled from 'styled-components'
// assets
import { newColor } from 'src/consts/colors'
import { ReactComponent as TokenSvg } from 'src/assets/token_icon.svg'

const ChipContainer = styled.div`
  width: fit-content;
  background-color: ${newColor.tertiary};
  color: ${newColor.white};
  border-radius: 20px;
  padding: 6px 6px 6px 12px;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 8px;
`

const CloseButton = styled(IconButton)`
  && {
    padding: 4px;
    color: ${newColor.white};

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }
`

export const Chips = ({
  label,
  onClose,
}: {
  label: string
  onClose?: (label: string) => Promise<void>
}) => {
  return (
    <ChipContainer>
      <TokenSvg />
      <Typography fontSize={14} fontWeight={400}>
        {label.toUpperCase()}
      </Typography>
      {onClose ? (
        <CloseButton onClick={async () => await onClose(label)} size="small">
          <CloseIcon fontSize="small" />
        </CloseButton>
      ) : (
        <Stack></Stack>
      )}
    </ChipContainer>
  )
}

export default Chips
