import React from 'react'
import { useNavigate } from 'react-router-dom';

export const MOTDCampaignGridNameCellRenderer = (props: any) => {

  const navigate = useNavigate()

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        cursor: 'pointer',
        textAlign: 'left',
        border: 'none',
        width: '100%'
      }}
      onClick={() => {
        navigate(`/game_management/motd/campaigns/${props?.row?.id}`)
      }}
    >
      {
        props?.row.title
      }
    </div>
  )
}
