import { toast } from 'react-toastify'
import { build_url_with_params, get } from './frontend'
import {
  LINK_TOKEN_STATUSES,
  LINK_COLLECTION_STATUSES,
  LINK_USER_STATUSES,
} from '../../consts/backend'
import AWS from 'aws-sdk'
import _ from 'lodash'
import { EXTENSION_REGEX } from '../../consts/regex'
import { Environment } from 'src/types/apiKey'

AWS.config.update({
  region: 'us-east-2',
})
export async function addOrgToDBApi(
  postWithAccessToken: any,
  domain: string,
  sigmaTeamId: string,
  sigmaTeamName: string,
  email: string,
  name: string,
) {
  return await postWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/organizations/${email}`,
    {
      name: name,
      sigma_user_account_email: email,
      sigma_user_account_id: sigmaTeamId,
      sigma_team: sigmaTeamName,
      email_domain: domain,
      tabs_config: null,
    },
  )
}

export const authenticateExplore = async (getWithAccessToken: any) => {
  try {
    const data = await getWithAccessToken(
      process.env.REACT_APP_EXPLORE_DATA_URL,
    )
    return data
  } catch (err: any) {
    return null
  }
}

export const getSchemas = async (getWithAccessToken: any) => {
  try {
    const data = await getWithAccessToken(
      `${process.env.REACT_APP_EXPLORE_DATA_URL}/metadata`,
    )
    return data
  } catch (err: any) {
    return null
  }
}

export const getOrgList = async (getWithAccessToken: any, userData: string) => {
  try {
    const data = await getWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/organizations/?include_helika=${userData}`,
    )
    return data
  } catch (err: any) {
    return null
  }
}
export const getTeamsRef = async (tokens: any) => {
  const teamsRes = await fetch(`${process.env.REACT_APP_SIGMA_URL}/v2/teams`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${tokens.access_token}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
  })

  if (teamsRes.status !== 200) {
    throw new Error(`Status ${teamsRes.status}: ${teamsRes?.statusText}`)
  }

  return teamsRes
}
export const getSigmaAccessToken = async () => {
  const details = {
    grant_type: 'client_credentials',
    client_id: process.env.REACT_APP_SIGMA_CLIENT_ID!,
    client_secret: process.env.REACT_APP_SIGMA_SECRET!,
  }
  const formBody = []
  for (const [key, value] of Object.entries(details)) {
    const encodedKey = encodeURIComponent(key)
    const encodedValue = encodeURIComponent(value)
    formBody.push(encodedKey + '=' + encodedValue)
  }
  const form = formBody.join('&')

  const tokenRes = await fetch(
    `${process.env.REACT_APP_SIGMA_URL}/v2/auth/token`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      body: form,
    },
  )

  if (tokenRes.status !== 200) {
    throw new Error(`Status ${tokenRes.status}: ${tokenRes?.statusText}`)
  }

  const tokens = await tokenRes.json()

  return tokens
}

export async function linkERC20ToOrgApi(
  postWithAccessToken: any,
  token_address: string,
  organization_name: string,
  chain: string,
  is_internal: any,
) {
  if (token_address && organization_name && chain) {
    try {
      await postWithAccessToken(
        `${process.env.REACT_APP_HELIKA_API_URL}/v1/tokens/link-token/${chain}`,
        {
          token_address: token_address,
          organization_name: organization_name,
          is_internal: is_internal,
        },
      )
        .then(
          (data: {
            message: (typeof LINK_TOKEN_STATUSES)[keyof typeof LINK_TOKEN_STATUSES]
          }) => {
            if (data.message === LINK_TOKEN_STATUSES.TOKEN_LINKED) {
              toast.success(
                `${token_address} token in ${chain} chain was succefully linked to organization ${organization_name}`,
              )
            } else if (
              data.message ===
              LINK_TOKEN_STATUSES.TOKEN_ALREADY_LINKED
            ) {
              toast.info(
                `${LINK_TOKEN_STATUSES.TOKEN_ALREADY_LINKED}`,
              )
            } else if (
              data.message === LINK_TOKEN_STATUSES.NO_SUCH_ORGANIZATION
            ) {
              toast.warning(
                `Are you sure that ${token_address} in ${chain} chain is valid token address? ${LINK_TOKEN_STATUSES.NO_SUCH_ORGANIZATION}`,
              )
            } else {
              toast.error(data.message)
            }
          },
        )
        .catch((error: any) => {
          toast.error(error?.message)
        })
    } catch (error: any) {
      toast.error(error?.message)
    }
  } else {
    toast.warning(
      `Please enter token address and choose chain and organization`,
    )
  }
}

export async function linkCollectionToOrgApi(
  postWithAccessToken: any,
  contract_address: string,
  organization_name: string,
  organization_id: number,
  chain: string,
  is_internal: any,
) {
  if (contract_address && organization_name && chain) {
    try {
      await postWithAccessToken(
        `${process.env.REACT_APP_HELIKA_API_URL}/v1/collections/link-collection/${chain}`,
        {
          contract_address: contract_address,
          organization_name: organization_name,
          organization_id: organization_id,
          is_internal: is_internal,
        },
      )
        .then(
          (data: {
            message: (typeof LINK_COLLECTION_STATUSES)[keyof typeof LINK_COLLECTION_STATUSES]
          }) => {
            if (data.message === LINK_COLLECTION_STATUSES.COLLECTION_LINKED) {
              toast.success(
                `${contract_address} collection in ${chain} chain was succefully linked to organization ${organization_name}`,
              )
            } else if (
              data.message ===
              LINK_COLLECTION_STATUSES.COLLECTION_ALREADY_LINKED
            ) {
              toast.info(
                `${LINK_COLLECTION_STATUSES.COLLECTION_ALREADY_LINKED}`,
              )
            } else if (
              data.message === LINK_COLLECTION_STATUSES.NOT_TRACKED_COLLECTION
            ) {
              toast.info(`${LINK_COLLECTION_STATUSES.NOT_TRACKED_COLLECTION}`)
            } else if (
              data.message === LINK_COLLECTION_STATUSES.NO_SUCH_COLLECTION
            ) {
              toast.warning(
                `Are you sure that ${contract_address} in ${chain} chain is valid collection address? ${LINK_COLLECTION_STATUSES.NO_SUCH_COLLECTION}`,
              )
            } else {
              toast.error(data.message)
            }
          },
        )
        .catch((error: any) => {
          toast.error(error?.message)
        })
    } catch (error: any) {
      toast.error(error?.message)
    }
  } else {
    toast.warning(
      `Please enter collection address and choose chain and organization`,
    )
  }
}

export async function addCollectionAPI(
  patchWithAccessToken: any,
  address: string,
  chain: string,
) {
  try {
    await patchWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/collections/${chain}/${address}`,
    )
      .then((data: any) => {
        if (data.status === 'ok') {
          toast.success(
            `${address} collection in ${chain} chain was succefully added`,
          )
        } else {
          toast.error(data.message)
        }
      })
      .catch((error: any) => {
        toast.error(error?.message)
      })
  } catch (error: any) {
    toast.error(error?.message)
  }
}
export async function getExcelObject(s3Key: string) {
  //get object from s3
  const s3 = new AWS.S3()
  const params = {
    Bucket: process.env.REACT_APP_AMAZON_S3_BUCKET_NAME!,
    Key: s3Key,
  }
  let resp: any = await s3
    .makeUnauthenticatedRequest('getObject', params)
    .promise()

  //get gile name
  let filenameParts = s3Key.split('/')
  let fileName = filenameParts[filenameParts.length - 1]

  //get file extension
  let extension = fileName.match(EXTENSION_REGEX)

  //if wrong extension return nothing
  if (!extension || !['xlsx', 'xls', 'csv'].includes(extension[0])) return

  //convert Uint8Array to file
  let newFile = new File(
    [new Uint8Array(resp?.Body) as ArrayBufferView],
    fileName,
  )
  return newFile
}

export async function getUserReportLogs(getTokenIfNecessary: any, params: any) {
  const accessToken = await getTokenIfNecessary()

  let options: any = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }

  let url = build_url_with_params(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/game/game-event`,
    params,
  )

  let resp = await get(url, options).catch((error) => {
    console.log(error.message)
  })
  return resp?.results
}

export async function getUserData(getTokenIfNecessary: any, logout: any) {
  const accessToken = await getTokenIfNecessary()

  let options: any = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }

  let url = `${process.env.REACT_APP_HELIKA_API_URL}/v1/user/`

  let resp = await get(url, options).catch((error) => {
    console.log(error.message)
  })
  if (_.isEmpty(resp?.results)) {
    setTimeout(() => {
      toast.error('Unable to find user data. You are being logged out.', {
        autoClose: false,
        hideProgressBar: true,
      })
      setTimeout(() => {
        logout()
      }, 3000)
    }, 3000)
  }
  return resp?.results
}

export async function getOrgUsers(getTokenIfNecessary: any) {
  const accessToken = await getTokenIfNecessary()

  let options: any = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }

  let url = `${process.env.REACT_APP_HELIKA_API_URL}/v1/user/org-users`

  let resp = await get(url, options).catch((error) => {
    console.log(error.message)
  })
  return resp?.results
}

export async function patchUserAttributes(
  patchWithAccessToken: any,
  attributes: any,
) {
  let wasApplied = await patchWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/user/attributes`,
    attributes,
  )
  if (wasApplied?.results) {
    return true
  }
  return false
}

export async function applyConfig(patchWithAccessToken: any, config: any) {
  let wasApplied = await patchWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/config-history/apply/${config.id}`,
  )
  if (wasApplied?.results) {
    return true
  }
  localStorage.setItem('APPLY_CONFIG_ERROR', wasApplied?.details) //todo: change this, remove apply config error to throw
  return false
}

export async function editConfig(patchWithAccessToken: any, config: any) {
  let wasEdited = await patchWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/config-history/`,
    config,
  )
  if (wasEdited?.results) {
    return true
  }
  return false
}

export async function getConfigHistory(
  getTokenIfNecessary: any,
  parent_id: number,
  params?: any,
) {
  const accessToken = await getTokenIfNecessary()

  let options: any = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }

  let url = build_url_with_params(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/config-history/${parent_id}`,
    params,
  )

  let resp = await get(url, options).catch((error) => {
    console.log(error.message)
  })
  return resp?.results
}

export async function searchConfigHistory(
  getTokenIfNecessary: any,
  parent_id: number,
  params?: any,
) {
  const accessToken = await getTokenIfNecessary()

  let options: any = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }

  let url = build_url_with_params(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/config-history/search/${parent_id}`,
    params,
  )

  let resp = await get(url, options).catch((error) => {
    console.log(error.message)
  })
  if (
    !resp ||
    ('message' in resp && resp.message === 'Config history not found')
  ) {
    return []
  }
  return resp?.results
}

export async function getConfig(getTokenIfNecessary: any, id?: any) {
  const accessToken = await getTokenIfNecessary()

  let options: any = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }

  let url = `${process.env.REACT_APP_HELIKA_API_URL}/v1/client-configs/${id}`

  let resp = await get(url, options).catch((error) => {
    console.log(error.message)
  })
  return resp?.results
}

export async function searchConfigList(getTokenIfNecessary: any, params?: any) {
  const accessToken = await getTokenIfNecessary()

  let options: any = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }

  let url = build_url_with_params(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/client-configs/search`,
    params,
  )

  let resp = await get(url, options).catch((error) => {
    console.log(error.message)
  })
  if (
    !resp ||
    ('message' in resp && resp.message === 'Config history not found')
  ) {
    toast.error('No configs found within search parameters')
    return []
  }
  return resp?.results
}

export async function getDiff(getTokenIfNecessary: any, config?: any) {
  const accessToken = await getTokenIfNecessary()

  let options: any = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }

  let url = `${process.env.REACT_APP_HELIKA_API_URL}/v1/config-history/diff/${config.id}`

  let resp = await get(url, options).catch((error) => {
    console.log(error.message)
  })
  return resp
}

export async function getConfigSheetDiff(
  getTokenIfNecessary: any,
  config: any,
  sheet_name: any,
) {
  const accessToken = await getTokenIfNecessary()

  let options: any = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }

  let url = `${process.env.REACT_APP_HELIKA_API_URL}/v1/config-history/diff-per-sheet/${config.id}?sheet_name=${sheet_name}`

  return await get(url, options)
}

export async function getDiscrepancies(
  getTokenIfNecessary: any,
  parent_id: number,
) {
  const accessToken = await getTokenIfNecessary()

  let options: any = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }

  let url = `${process.env.REACT_APP_HELIKA_API_URL}/v1/config-history/detect-db-discrepancy/${parent_id}`

  let resp = await get(url, options).catch((error) => {
    console.log(error.message)
  })
  return resp
}

export async function completeUserReport(
  patchWithAccessToken: any,
  report_id: number,
) {
  let resp = await patchWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/support/complete/${report_id}`,
  )
  return resp?.results
}

export async function setSupportTicketStatus(
  patchWithAccessToken: any,
  newStatus: string,
  support_ticket_ids: any[],
) {
  let params = {
    support_ticket_ids: support_ticket_ids,
  }

  let resp = await patchWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/support/set-status/${newStatus}`,
    params,
  )
  return resp?.results
}

export async function postConfig(getTokenIfNecessary: any, params: any) {
  const accessToken = await getTokenIfNecessary()

  let options: any = {
    headers: {
      authorization: `Bearer ${accessToken}`,
      'Content-type': 'multipart/form-data',
      async: true,
    },
  }
  let headers = {
    Authorization: `Bearer ${accessToken}`,
    'Content-type': 'multipart/form-data',
    async: true,
  }

  options.body = JSON.stringify(params)
  let data = new FormData()
  if (!_.isEmpty(params.tags) && !_.isUndefined(params.tags)) {
    data.append('tags', params.tags)
  }
  data.append('parent_id', params.parent_id)
  data.append('file', params.file)

  let resp = await fetch(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/config-history/upload/${params.parent_id}`,
    {
      method: 'POST',
      ...headers,
      body: data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  )

  if (resp.status !== 200) {
    throw new Error(`Status ${resp.status}: ${resp?.statusText}`)
  }

  //if (!resp || resp.results) return undefined;
  //return resp.results;
  return resp
}

export async function getSheetsMapping(getTokenIfNecessary: any, org_id: any) {
  const accessToken = await getTokenIfNecessary()

  let resp = await get(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/sheets-mapping/from-org/${org_id}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  ).catch((error) => {
    console.log(error.message)
  })
  if (!resp) throw new Error('No response, sheets-mapping')
  return resp?.results
}

export async function getTabOrgs(getTokenIfNecessary: any, tab_id?: any) {
  const accessToken = await getTokenIfNecessary()

  let options: any = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }

  let url = build_url_with_params(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/tabs/orgs/${tab_id}`,
    null,
  )

  let resp = await get(url, options).catch((error) => {
    console.log(error.message)
  })
  return resp?.results
}

export async function getNotifications(
  user_id: any,
  getTokenIfNecessary: any,
  params?: any,
) {
  const accessToken = await getTokenIfNecessary()

  let options: any = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }

  let url = build_url_with_params(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/notifications/${user_id}`,
    params,
  )

  let resp = await get(url, options).catch((error) => {
    console.log(error.message)
  })
  return resp?.results
}

export async function getUserReports(getTokenIfNecessary: any, params?: any) {
  const accessToken = await getTokenIfNecessary()

  let options: any = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }

  let url = build_url_with_params(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/support/`,
    params,
  )

  let resp = await get(url, options).catch((error) => {
    console.log(error.message)
  })
  return resp?.results
}

export async function getUserReportsCount(
  getWithAccessToken: any,
  params: any,
) {
  try {
    return await getWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/support/count`,
      params,
    )
      .then((res: any) => {
        return res.results
      })
      .catch((error: any) => {
        toast.error(error)
        return null
      })
  } catch (err: any) {
    toast.error(err)
    return null
  }
}

export async function getUserReportsByID(
  getWithAccessToken: any,
  userReportID: any,
) {
  const data = await getWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/support/${userReportID}`,
  )
  return data?.results
}

export async function getModules(getTokenIfNecessary: any, params?: any) {
  const accessToken = await getTokenIfNecessary()

  let options: any = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }

  let url = build_url_with_params(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/modules/`,
    params,
  )

  let resp = await get(url, options).catch((error) => {
    console.log(error.message)
  })
  return resp?.results
}

export async function uploadAvatar(getTokenIfNecessary: any, file?: any) {
  const accessToken = await getTokenIfNecessary()
  const formData = new FormData()
  formData.append('file', file)
  let resp = await fetch(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/user/avatar`,
    {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  )
    .then((response) => response.text())
    .then((result) => {
      return JSON.parse(result).results
    })
    .catch((error) => console.error('Error uploading avatar:', error))

  if (resp.status !== 200) {
    throw new Error(`Status ${resp.status}: ${resp?.statusText}`)
  }

  return resp
}

export async function resetPassword(url = '', data = {}) {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  if (response.status !== 200) {
    throw new Error(`Status ${response.status}: ${response?.statusText}`)
  }

  return response.text()
}

export async function DeleteAvatar(url = '', accessToken = '') {
  // Default options are marked with *
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  if (response.status !== 200) {
    throw new Error(`Status ${response.status}: ${response?.statusText}`)
  }

  return response.text()
}

export async function getConnection(getWithAccessToken: any, id: any) {
  const data = await getWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/client-connections/${id}`,
  )
  return data?.results
}

export async function getConnections(getTokenIfNecessary: any) {
  const accessToken = await getTokenIfNecessary()
  let url = `${process.env.REACT_APP_HELIKA_API_URL}/v1/client-connections/`
  return await get(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }).catch((error) => {
    toast.error('Could not grab list of client-connections:', error)
  })
}

export async function getGameConfig(getWithAccessToken: any, params: any) {
  return await getWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/game/config`,
    params,
  )
}

export async function createConfig(postWithAccessToken: any, params: any) {
  return await postWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/game/config`,
    params,
  )
}

export async function createConnection(postWithAccessToken: any, params: any) {
  return await postWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/client-connections/`,
    params,
  )
}

export async function editConnection(
  patchWithAccessToken: any,
  connection_id: any,
  params: any,
) {
  return await patchWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/client-connections/?id=${connection_id}`,
    params,
  )
}

export async function createParentConfig(
  postWithAccessToken: any,
  params: any,
) {
  return await postWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/client-configs/`,
    params,
  )
}

export async function editParentConfig(
  patchWithAccessToken: any,
  connection_id: any,
  params: any,
) {
  return await patchWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/client-configs/?id=${connection_id}`,
    params,
  )
}

export async function updateAPIKeyStatus(
  patchWithAccessToken: any,
  patchData: any,
) {
  return await patchWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/api-credentials/`,
    patchData,
  )
}

export async function createKochvaAppId(
  patchWithAccessToken: any,
  patchData: any,
) {
  return await patchWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/api-credentials/create-kochava-app-id`,
    patchData,
  )
}

export async function updateUserModuleAccess(
  patchWithAccessToken: any,
  patchData: any,
) {
  return await patchWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/user/module-access`,
    patchData,
  )
}

export async function getOrgAPIKeys(
  getWithAccessToken: any,
  envs: Environment[],
  organization_id?: number
) {
  const url = new URL(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/api-credentials/`,
  )

  envs.forEach((env) => {
    url.searchParams.append('env', env)
  })

  if (organization_id) {
    url.searchParams.append('organization_id', organization_id.toString())
  }

  return await getWithAccessToken(url.toString())
}

export async function getAllApiKeysApi(getWithAccessToken: any) {
  return await getWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/api-credentials/all/`,
  )
}

export async function createNewOrgAPIKey(postWithAccessToken: any, data: any) {
  return await postWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/api-credentials/create-api-key`,
    data,
  )
}

export async function deleteAPIKey(
  deleteWithAccessToken: any,
  apiKeyId: number | null,
) {
  if (!apiKeyId) {
    return null
  }
  return await deleteWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/api-credentials/${apiKeyId}`,
  )
}

export async function getUserOrgs(
  getWithAccessToken: any,
  userData: any,
  isHelikaAdmin: any,
  isHelikaSuperAdmin: any,
) {
  const data = await getWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/organizations/?include_helika=${(
      isHelikaAdmin(userData) || isHelikaSuperAdmin(userData)
    ).toString()}`,
  )
  return data
}

export async function getCurrentUserOrg(
  getWithAccessToken: any,
  orgId: number | null,
) {
  if (orgId === null) return
  return await getWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/organizations/${orgId}`,
  )
}

export async function getDistinctTabs(getWithAccessToken: any) {
  try {
    const data = await getWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/tabs/distinct`,
    )
    return data
  } catch (error: any) {
    toast.error(error?.message)
  }
}

export async function getBeFreeToken(postWithAccessToken: any, params: any, url: any) {
  return await postWithAccessToken(
    url,
    params,
  )
}


export async function assignTabToOrg(postWithAccessToken: any, params: any) {
  return await postWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/tabs/assign`,
    params,
  )
}

export async function deleteTabFromOrgs(
  patchWithAccessToken: any,
  tabId: number,
  organizationIds: number[],
) {
  const data: {
    tab_id: number
    organization_ids?: number[]
  } = {
    tab_id: tabId,
  }

  if (organizationIds?.length) {
    data.organization_ids = organizationIds
  }

  return await patchWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/tabs/remove-multiple-orgs`,
    data,
  )
}

export async function deleteTabFromOrg(
  postWithAccessToken: any,
  tabId: number,
  orgId: number,
) {
  return await postWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/tabs/remove/${orgId}/${tabId}`,
  )
}

export async function deleteGlobalTab(
  deleteWithAccessToken: any,
  tabId: number,
) {
  return await deleteWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/tabs/${tabId}`,
  )
}

export async function linkUserToOrgApi(
  patchWithAccessToken: any,
  email: string,
  orgLabel: string,
  orgValue: number,
  role: string,
) {
  if (email && orgLabel && orgValue && role) {
    try {
      await patchWithAccessToken(
        `${process.env.REACT_APP_HELIKA_API_URL}/v1/user/${email}/${orgValue}/${role}`,
        {
          email: email,
          organization_id: orgValue,
          role: role,
        },
      )
        .then((data: any) => {
          if (data.status === 'ok') {
            toast.success(
              `${email} user was succefully associated to organization ${orgLabel} with role ${role}`,
            )
          } else {
            toast.error(
              `Are you sure that ${email} email is correct? ${LINK_USER_STATUSES.NO_SUCH_USER}`,
            )
          }
        })
        .catch((error: any) => {
          toast.error(error?.message)
        })
    } catch (error: any) {
      toast.error(error?.message)
    }
  } else {
    toast.warning(
      `Please enter collection address and choose chain and organization`,
    )
  }
}

export async function createAPIKeyApi(
  postWithAccessToken: any,
  apiKeyName: string,
  orgValue: number,
  secured: boolean,
) {
  if (apiKeyName && orgValue) {
    try {
      return await postWithAccessToken(
        `${process.env.REACT_APP_HELIKA_API_URL}/v1/api-credentials/create-api-key`,
        {
          organization_id: orgValue,
          name: apiKeyName,
          secured: secured,
        },
      )
        .then((res: any) => {
          return res
        })
        .catch((error: any) => {
          toast.error(error?.message)
        })
    } catch (error: any) {
      toast.error(error?.message)
    }
  } else {
    toast.warning(`Please enter API Key Name and select organization.`)
  }
}

export async function syncEmailMarketingDomains(
  postWithAccessToken: any,
  args: any
) {
  try {
    return await postWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/email-marketing/domains/sync`,
      args,
    )
      .then((res: any) => {
        return res
      })
      .catch((error: any) => {
        toast.error(error?.message)
      })
  } catch (error: any) {
    toast.error(error?.message)
  }
}

export async function createEmailMarketingSubAccount(
  postWithAccessToken: any,
  args: any
) {
  try {
    return await postWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/email-marketing/subaccounts/`,
      args,
    )
      .then((res: any) => {
        return res
      })
      .catch((error: any) => {
        toast.error(error?.message)
      })
  } catch (error: any) {
    toast.error(error?.message)
  }
}

export async function getParentTabsApi(getWithAccessToken: any) {
  try {
    const data = await getWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/tabs/parents`,
    )
    return data
  } catch (err: any) {
    return null
  }
}

export async function getSocialsApi(getWithAccessToken: any) {
  try {
    const data = await getWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/organization-access/socials/`,
    )
    return data
  } catch (err: any) {
    return null
  }
}

export async function getOrgUsersApi(
  getWithAccessToken: any,
  orgValue: Number,
) {
  try {
    const data = await getWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/user/org-users?organization_id=${orgValue}`,
    )
    return data
  } catch (err: any) {
    return null
  }
}

export async function getModulesApi(getWithAccessToken: any) {
  try {
    const data = await getWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/modules/`,
    )
    return data
  } catch (err: any) {
    return null
  }
}

export async function createNewTabApi(
  postWithAccessToken: any,
  name: string,
  description: string,
  icon: string,
  parent_tab_id: number,
  dashboards: any,
) {
  if (name && description && icon && parent_tab_id) {
    try {
      return await postWithAccessToken(
        `${process.env.REACT_APP_HELIKA_API_URL}/v1/tabs/`,
        {
          name: name,
          description: description,
          parent_tab_id: parent_tab_id,
          icon: icon,
          dashboards: dashboards,
        },
      )
        .then((res: any) => {
          toast.success(`Success create ${name} Tab.`)
          return res
        })
        .catch((error: any) => {
          toast.error(error?.message)
        })
    } catch (error: any) {
      toast.error(error?.message)
    }
  } else {
    toast.warning(`Please enter New Tab Name and other fields.`)
  }
}

export async function createParentTabApi(
  postWithAccessToken: any,
  name: string,
  module_id: number,
  icon: string,
) {
  if (name && module_id && icon) {
    try {
      return await postWithAccessToken(
        `${process.env.REACT_APP_HELIKA_API_URL}/v1/tabs/parent`,
        {
          name: name,
          module_id: module_id,
          icon: icon,
        },
      )
        .then((res: any) => {
          toast.success(`Success create Parent ${name} Tab.`)
          return res
        })
        .catch((error: any) => {
          toast.error(error?.message)
        })
    } catch (error: any) {
      toast.error(error?.message)
    }
  } else {
    toast.warning(`Please enter Parent Tab Name and other fields.`)
  }
}

export async function getTabInfoApi(getWithAccessToken: any, tab_id: number) {
  try {
    const data = await getWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/tabs/${tab_id}`,
    )
    return data
  } catch (err: any) {
    return null
  }
}

export async function editTabApi(patchWithAccessToken: any, params: any) {
  try {
    await patchWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/tabs/`,
      params,
    )
  } catch (err: any) {
    console.log(err)
    return null
  }
}

export async function applyMultiOrganizationApi(
  patchWithAccessToken: any,
  tabId: number,
  organizationIds: any,
  isSelectAll: boolean,
) {
  try {
    if (isSelectAll === true) {
      await patchWithAccessToken(
        `${process.env.REACT_APP_HELIKA_API_URL}/v1/tabs/apply-multiple-orgs`,
        {
          tab_id: tabId,
        },
      )
    } else {
      await patchWithAccessToken(
        `${process.env.REACT_APP_HELIKA_API_URL}/v1/tabs/apply-multiple-orgs`,
        {
          tab_id: tabId,
          organization_ids: organizationIds,
        },
      )
    }
  } catch (err: any) {
    return null
  }
}

export async function removeMultiOrganizationApi(
  patchWithAccessToken: any,
  tabId: number,
  organizationIds: any,
) {
  try {
    await patchWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/tabs/remove-multiple-orgs`,
      {
        tab_id: tabId,
        organization_ids: organizationIds,
      },
    )
  } catch (err: any) {
    return null
  }
}

export async function createOrganizationApi(
  postWithAccessToken: any,
  orgName: string,
) {
  if (orgName) {
    try {
      const data = await postWithAccessToken(
        `${process.env.REACT_APP_HELIKA_API_URL}/v1/organizations/`,
        {
          name: orgName,
        },
      )
      return data
    } catch (error: any) {
      toast.error(error?.message)
    }
  } else {
    toast.warning(`Please enter organization Name`)
  }
}

export async function addSocialConnectionApi(
  postWithAccessToken: any,
  socialId: string,
  orgValue: Number,
  platform: string,
) {
  try {
    const data = await postWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/organization-access/socials/add-conection-to-org`,
      {
        platform: platform,
        platform_id: socialId,
        organization_id: orgValue,
      },
    )
    return data
  } catch (error: any) {
    toast.error(error?.message)
  }
}

export async function addTabApi(
  postWithAccessToken: any,
  newTabName: string,
  icon: string,
  description: string,
  orgValue: Number,
  newIframeURL: string,
  parentTab: any,
  newDashboardName: string,
) {
  try {
    const data = await postWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/tabs/create-dashboard-and-orgtab`,
      {
        name: newTabName,
        icon: icon,
        description: description,
        organization_id: orgValue,
        iframe_link: newIframeURL,
        parent_tab_id: parentTab,
        priority: 10,
        dashboard_name: newDashboardName,
      },
    )
    return data
  } catch (error: any) {
    toast.error(error?.message)
  }
}

export async function setUserOrgRoleApi(
  patchWithAccessToken: any,
  orgValue: string | undefined,
  role: string,
) {
  try {
    const data = await patchWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/user/update-role`,
      {
        email: orgValue,
        org_role: role,
      },
    )
    return data
  } catch (error: any) {
    toast.error(error?.message)
  }
}

export async function bustCacheApi(
  postWithAccessToken: any,
  userToBustCache: string,
) {
  const data = await postWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/bust-user?email=${userToBustCache}`,
  )
  return data
}

export async function downgradeOrgApi(
  postWithAccessToken: any,
  org_id: any,
) {
  const data = await postWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/organizations/downgrade/${org_id}`,
  )
  return data
}

export async function bustAllUserCacheApi(postWithAccessToken: any) {
  try {
    const data = await postWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/bust-all-users`,
    )
    return data
  } catch (error: any) {
    toast.error(error?.message)
  }
}

export async function bustAllCacheApi(postWithAccessToken: any) {
  try {
    const data = await postWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/flush-cache`,
    )
    return data
  } catch (error: any) {
    toast.error(error?.message)
  }
}

export async function bustAllApiKeysCacheApi(postWithAccessToken: any) {
  try {
    const data = await postWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/bust-all-api-keys`,
    )
    return data
  } catch (error: any) {
    toast.error(error?.message)
  }
}

export async function bustApiKeyCacheApi(
  postWithAccessToken: any,
  apiKeyToBust: any,
) {
  const data = await postWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/bust-api-key`,
    {
      api_key: apiKeyToBust,
    },
  )
  return data
}

export async function bustOrgCacheApi(
  postWithAccessToken: any,
  orgValue: Number,
) {
  const data = await postWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/bust-org-users?org_id=${orgValue}`,
  )
  return data
}

export async function removeTabApi(
  postWithAccessToken: any,
  orgValue: Number,
  tabId: Number,
) {
  try {
    const data = await postWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/tabs/remove/${orgValue}/${tabId}`,
    )
    return data
  } catch (error: any) {
    toast.error(error?.message)
  }
}

export async function saveChangeNameApi(
  patchWithAccessToken: any,
  userName: string,
  email: string,
) {
  try {
    const data = await patchWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/user/`,
      {
        name: userName,
        email: email,
      },
    )
    return data
  } catch (error: any) {
    toast.error(error?.message)
  }
}

export async function assignUserApi(
  patchWithAccessToken: any,
  reportId: Number,
  params: any,
) {
  try {
    const data = await patchWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/support/assign?assignee_id=${reportId}`,
      params,
    )
    return data
  } catch (error: any) {
    toast.error(error?.message)
  }
}

export async function unAssignReportApi(
  patchWithAccessToken: any,
  reportId: Number,
) {
  const params = {
    support_ticket_ids: [reportId],
  }
  try {
    const data = await patchWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/support/assign`,
      params,
    )
    return data
  } catch (error: any) {
    toast.error(error?.message)
  }
}

export async function getSupportEmails(getWithAccessToken: any, params: any) {
  try {
    const data = await getWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/support/support-emails`,
      params,
    )
    return data
  } catch (error: any) {
    toast.error(error?.message)
  }
}

export async function getSupportInternalNotes(
  getWithAccessToken: any,
  params: any,
) {
  try {
    const data = await getWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/support/support-internal-notes`,
      params,
    )
    return data
  } catch (error: any) {
    toast.error(error?.message)
  }
}

export async function sendSupportEmail(postWithAccessToken: any, params: any) {
  try {
    await postWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/support/send-support-email`,
      params,
    )
      .then(() => {
        return true
      })
      .catch((err: any) => {
        toast.error(err?.message)
        return null
      })
  } catch (error: any) {
    toast.error(error?.message)
    return null
  }
}

export async function sendSupportResponseEmail(
  postWithAccessToken: any,
  params: any,
) {
  try {
    await postWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/support/send-support-email/response`,
      params,
    )
      .then(() => {
        return true
      })
      .catch((err: any) => {
        toast.error(err?.message)
        return null
      })
  } catch (error: any) {
    toast.error(error?.message)
    return null
  }
}

export async function sendSupportInternalNote(
  postWithAccessToken: any,
  params: any,
) {
  try {
    await postWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/support/support-internal-notes`,
      params,
    )
      .then(() => {
        return true
      })
      .catch((err: any) => {
        toast.error(err?.message)
        return null
      })
  } catch (error: any) {
    toast.error(error?.message)
    return null
  }
}

export async function setNotificationReadApi(
  postWithAccessToken: any,
  id: Number,
) {
  try {
    await postWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/notifications/mark-as-read/${id}`,
    )
  } catch (error: any) {
    toast.error(error?.message)
  }
}

export async function setAllNotificationsReadApi(postWithAccessToken: any) {
  try {
    await postWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/notifications/mark-all-as-read`,
    )
  } catch (error: any) {
    toast.error(error?.message)
  }
}

export async function loadMoreApi(
  getWithAccessToken: any,
  userDataId: Number,
  pageSize: Number,
  loadPage: Number,
) {
  try {
    const data = await getWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/notifications/${userDataId}`,
      {
        page_size: pageSize,
        page: loadPage,
      },
    )
    return data
  } catch (err: any) {
    return null
  }
}

export async function getDashBoardParentTabApi(
  getWithAccessToken: any,
  tab_id: number,
  org_id?: number | undefined,
) {
  try {
    let body: any = {
      org_id: org_id ? org_id : null,
    }
    const data = await getWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/dashboards/parent/${tab_id}`,
      body,
    )
    return data
  } catch (err: any) {
    return null
  }
}

export async function getAllDashBoardsApi(getWithAccessToken: any) {
  try {
    const data = await getWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/dashboards/`,
    )
    return data
  } catch (err: any) {
    return null
  }
}

export async function editOrganizationParamApi(
  postWithAccessToken: any,
  organization_tabID: number,
  params: any,
) {
  try {
    await postWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/organizations/${organization_tabID}/edit-default-dashboard-params`,
      params,
    )
      .then(() => {
        return true
      })
      .catch((err: any) => {
        toast.error(err?.message)
        return null
      })
  } catch (error: any) {
    toast.error(error?.message)
    return null
  }
}

export async function getActiveSystemMessages(getWithAccessToken: any) {
  try {
    return await getWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/messages/?active_only=true`,
    )
  } catch (err: any) {
    return null
  }
}

export async function createMessageApi(postWithAccessToken: any, params: any) {
  try {
    await postWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/messages/`,
      params,
    )
      .then(() => {
        return true
      })
      .catch((error: any) => {
        toast.error(error?.message)
        return null
      })
  } catch (err: any) {
    toast.error(err?.message)
    return null
  }
}

export async function editMessageApi(patchWithAccessToken: any, params: any) {
  try {
    await patchWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/messages/`,
      params,
    )
      .then(() => {
        return true
      })
      .catch((error: any) => {
        toast.error(error?.message)
        return null
      })
  } catch (err: any) {
    toast.error(err?.message)
    return null
  }
}

export async function getMessageApi(
  getWithAccessToken: any,
  page: number,
  pageSize: number,
) {
  try {
    const data = await getWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/messages/`,
      {
        page: page,
        page_size: pageSize,
      },
    )
    return data
  } catch (err: any) {
    return null
  }
}

export async function getInvitedUserApi(
  getWithAccessToken: any,
  organization_id: number,
) {
  try {
    return getWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/user/invited?organization_id=${organization_id}`,
    )
      .then((res: any) => {
        return res
      })
      .catch((err: any) => {
        console.log(err)
      })
  } catch (err: any) {
    return null
  }
}

export async function inviteUserApi(postWithAccessToken: any, params: any) {
  try {
    return await postWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/user/invite`,
      params,
    )
      .then((res: any) => {
        return res
      })
      .catch((error: any) => {
        toast.error(error?.message)
        return null
      })
  } catch (err: any) {
    toast.error(err?.message)
    return null
  }
}

export async function removeInviteUserApi(
  deleteWithAccessToken: any,
  user_id: number,
) {
  try {
    return await deleteWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/user/invite/${user_id}`,
    )
      .then((res: any) => {
        return res
      })
      .catch((error: any) => {
        toast.error(error?.message)
        return null
      })
  } catch (err: any) {
    toast.error(err?.message)
    return null
  }
}

export async function resendInviteUserApi(
  postWithAccessToken: any,
  user_id: number,
) {
  try {
    return await postWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/user/invite/${user_id}/resend`,
    )
      .then((res: any) => {
        return res
      })
      .catch((error: any) => {
        toast.error(error?.message)
        return null
      })
  } catch (err: any) {
    toast.error(err?.message)
    return null
  }
}

export async function setModuleAccessApi(
  patchWithAccessToken: any,
  email: string,
  params: any,
) {
  try {
    return await patchWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/user/module-access/${email}`,
      params,
    )
      .then((res: any) => {
        return res
      })
      .catch((error: any) => {
        toast.error(error?.message)
        return null
      })
  } catch (err: any) {
    toast.error(err?.message)
    return null
  }
}

export async function setOrgModuleAccessApi(
  patchWithAccessToken: any,
  organization_id: number | undefined,
  params: any,
) {
  try {
    return await patchWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/organizations/module-access/${organization_id}`,
      params,
    )
      .then((res: any) => {
        return res
      })
      .catch((error: any) => {
        toast.error(error?.message)
        return null
      })
  } catch (err: any) {
    toast.error(err?.message)
    return null
  }
}

export async function runQuery(
  postWithAccessToken: any,
  params: any,
  errorHandler: any,
  signal?: any,
) {
  return await postWithAccessToken(
    `${process.env.REACT_APP_EXPLORE_DATA_URL}/run_query`,
    params,
    errorHandler,
    signal,
  )
}

export async function convertToDataset(postWithAccessToken: any, params: any) {
  try {
    return await postWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/queries/convert-to-dataset/`,
      params,
    )
      .then((res: any) => {
        return res
      })
      .catch((error: any) => {
        toast.error(error?.message)
        return null
      })
  } catch (err: any) {
    toast.error(err?.message)
    return null
  }
}

export async function publishQuery(postWithAccessToken: any, params: any) {
  try {
    return await postWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/queries/${params.id}/publish`,
      {
        query: params.query,
        name: params.name,
      },
    )
      .then((res: any) => {
        return res
      })
      .catch((error: any) => {
        toast.error(error?.message)
        return null
      })
  } catch (err: any) {
    toast.error(err?.message)
    return null
  }
}

export async function saveUserQuery(postWithAccessToken: any, params: any) {
  try {
    return await postWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/queries/user`,
      {
        query: params.query,
        name: params.name,
      },
    )
      .then((res: any) => {
        return res
      })
      .catch((error: any) => {
        toast.error(error?.message)
        return null
      })
  } catch (err: any) {
    toast.error(err?.message)
    return null
  }
}

export async function saveOrgQuery(postWithAccessToken: any, params: any) {
  try {
    return await postWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/queries/org`,
      {
        query: params.query,
        name: params.name,
      },
    )
      .then((res: any) => {
        return res
      })
      .catch((error: any) => {
        toast.error(error?.message)
        return null
      })
  } catch (err: any) {
    toast.error(err?.message)
    return null
  }
}

export async function updateQuery(patchWithAccessToken: any, params: any) {
  try {
    return await patchWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/queries/${params.id}`,
      params,
    )
      .then((res: any) => {
        return res
      })
      .catch((error: any) => {
        toast.error(error?.message)
        return null
      })
  } catch (err: any) {
    toast.error(err?.message)
    return null
  }
}

export async function deleteQuery(deleteWithAccessToken: any, params: any) {
  return await deleteWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/queries/${params.id}`,
    params,
  )
}

export async function duplicateQuery(postWithAccessToken: any, params: any) {
  try {
    return await postWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/queries/${params.id}/duplicate`,
      params,
    )
      .then((res: any) => {
        return res
      })
      .catch((error: any) => {
        toast.error(error?.message)
        return null
      })
  } catch (err: any) {
    toast.error(err?.message)
    return null
  }
}

export async function getVisualizations(getWithAccessToken: any) {
  let res = await getWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/visualizations/`,
  )
  return res?.results
}

export async function saveUserVisualization(
  postWithAccessToken: any,
  params: any,
) {
  return await postWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/visualizations/`,
    params,
  )
}

export async function updateVisualization(
  patchWithAccessToken: any,
  params: any,
) {
  return await patchWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/visualizations/${params.id}`,
    params,
  )
}

export async function deleteVisualization(
  deleteWithAccessToken: any,
  params: any,
) {
  return await deleteWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/visualizations/${params.id}`,
    params,
  )
}

export async function getExploreDashboardData(
  getWithAccessToken: any,
  params: any,
) {
  let res = await getWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/explorer-dashboards/${params.helika_dashboard_id}/visualizations`,
  )
  return res?.results
}

export async function getExploreDashboards(getWithAccessToken: any) {
  let res = await getWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/explorer-dashboards/`,
  )
  return res?.results
}

export async function saveExploreDashboards(
  postWithAccessToken: any,
  params: any,
) {
  return await postWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/explorer-dashboards/`,
    params,
  )
}

export async function updateExploreDashboards(
  patchWithAccessToken: any,
  params: any,
) {
  return await patchWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/explorer-dashboards/${params.dashboard_id}`,
    params,
  )
}

export async function deleteExploreDashboards(
  deleteWithAccessToken: any,
  params: any,
) {
  return await deleteWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/explorer-dashboards/${params.id}`,
    params,
  )
}

export async function publishExploreDashboard(
  postWithAccessToken: any,
  params: any,
) {
  return await postWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/explorer-dashboards/${params.id}/publish`,
    params,
  )
}

export async function unpublishExploreDashboard(
  postWithAccessToken: any,
  params: any,
) {
  return await postWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/explorer-dashboards/${params.id}/unpublish`,
    params,
  )
}

export async function getUserQueries(getWithAccessToken: any) {
  try {
    return await getWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/queries/user`,
    )
      .then((res: any) => {
        return res.results
      })
      .catch((error: any) => {
        toast.error(error?.message)
        return null
      })
  } catch (err: any) {
    toast.error(err?.message)
    return null
  }
}

export async function getOrgQueries(getWithAccessToken: any) {
  try {
    return await getWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/queries/org`,
    )
      .then((res: any) => {
        return res.results
      })
      .catch((error: any) => {
        toast.error(error?.message)
        return null
      })
  } catch (err: any) {
    toast.error(err?.message)
    return null
  }
}

export async function getUserSchemas(getWithAccessToken: any, params: any) {
  try {
    return await getWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/queries/schemas/${params.org_id}/${params.user_id}`,
    )
      .then((res: any) => {
        return res
      })
      .catch((error: any) => {
        toast.error(error?.message)
        return null
      })
  } catch (err: any) {
    toast.error(err?.message)
    return null
  }
}

export async function getOrgSchemas(getWithAccessToken: any, params: any) {
  try {
    return await getWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/queries/schemas/${params.org_id}`,
    )
      .then((res: any) => {
        return res
      })
      .catch((error: any) => {
        toast.error(error?.message)
        return null
      })
  } catch (err: any) {
    toast.error(err?.message)
    return null
  }
}

export async function setSigmaRoleApi(patchWithAccessToken: any, params: any) {
  try {
    const data = await patchWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/user/update-role`,
      params,
    )
    return data
  } catch (error: any) {
    toast.error(error?.message)
  }
}

export async function getPoint(
  getWithAccessToken: any,
  campaign_id: number,
  user_id: number,
) {
  try {
    const data = await getWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/points/${campaign_id}/${user_id}`,
    )
    return data
  } catch (error: any) {
    toast.error(error?.message)
  }
}

export async function setPoint(postWithAccessToken: any, params: any) {
  try {
    const data = await postWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/points`,
      params,
    )
    return data
  } catch (error: any) {
    toast.error(error?.message)
  }
}

export async function patchPoint(patchWithAccessToken: any, params: any) {
  try {
    const data = await patchWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/points`,
      params,
    )
    return data
  } catch (error: any) {
    toast.error(error?.message)
  }
}

export async function deletePoint(
  deleteWithAccessToken: any,
  campaign_id: number,
  user_id: number,
) {
  try {
    const data = await deleteWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/points/${campaign_id}/${user_id}`,
    )
    return data
  } catch (error: any) {
    toast.error(error?.message)
  }
}

export async function getAllCampaigns(
  getWithAccessToken: any,
  page: number,
  pageSize: number,
) {
  try {
    const data = await getWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/rewards/campaigns`,
      {
        page: page,
        page_size: pageSize,
      },
    )
    return data
  } catch (error: any) {
    toast.error(error?.message)
  }
}

export async function getPointCampaignById(
  getWithAccessToken: any,
  campaign_id: number,
) {
  try {
    const data = await getWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/rewards/points-campaign/${campaign_id}`,
    )
    return data
  } catch (error: any) {
    toast.error(error?.message)
  }
}

export async function setPointCampaign(postWithAccessToken: any, params: any) {
  try {
    const data = await postWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/rewards/points-campaign`,
      params,
    )
    return data
  } catch (error: any) {
    toast.error(error?.message)
  }
}

export async function patchPointCampaign(
  patchWithAccessToken: any,
  params: any,
) {
  try {
    const data = await patchWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/rewards/points-campaign`,
      params,
    )
    return data
  } catch (error: any) {
    toast.error(error?.message)
  }
}

export async function deletePointCampaign(
  deleteWithAccessToken: any,
  campaign_id: number,
) {
  try {
    const data = await deleteWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/rewards/points-campaign/${campaign_id}`,
    )
    return data
  } catch (error: any) {
    toast.error(error?.message)
  }
}

export async function patchPointCampaignArchive(
  patchWithAccessToken: any,
  campaign_id: any,
) {
  try {
    const data = await patchWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/rewards/points-campaign/${campaign_id}/archive`,
    )
    return data
  } catch (error: any) {
    toast.error(error?.message)
  }
}

export async function getPointConfigSearch(
  getWithAccessToken: any,
  params: any,
) {
  try {
    const data = await getWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/rewards/points-config/search`,
      params,
    )
    return data
  } catch (error: any) {
    toast.error(error?.message)
  }
}

export async function getPointConfig(
  getWithAccessToken: any,
  config_id: number,
) {
  try {
    const data = await getWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/rewards/points-config/${config_id}`,
    )
    return data
  } catch (error: any) {
    toast.error(error?.message)
  }
}

export async function setPointConfig(postWithAccessToken: any, params: any) {
  try {
    const data = await postWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/rewards/points-config`,
      params,
    )
    return data
  } catch (error: any) {
    toast.error(error?.message)
  }
}

export async function patchPointConfig(patchWithAccessToken: any, params: any) {
  try {
    const data = await patchWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/rewards/points-config`,
      params,
    )
    return data
  } catch (error: any) {
    toast.error(error?.message)
  }
}

export async function patchPointConfigById(
  patchWithAccessToken: any,
  params: any,
  config_id: number,
) {
  try {
    const data = await patchWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/rewards/points-config/${config_id}`,
      params,
    )
    return data
  } catch (error: any) {
    toast.error(error?.message)
  }
}

export async function deletePointConfigById(
  deleteWithAccessToken: any,
  config_id: number,
) {
  try {
    const data = await deleteWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/rewards/points-config/${config_id}`,
    )
    return data
  } catch (error: any) {
    toast.error(error?.message)
  }
}

export async function postToAi(postWithAccessToken: any, params: any) {
  try {
    return await postWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/ai/chat`,
      {
        message: params.message,
        helika_api_key: params.helika_api_key,
      },
    )
  } catch (error: any) {
    toast.error(error?.message)
  }
}

export async function getOrganizationRewardsEvents(
  getWithAccessToken: any,
  game_id?: any,
) {
  try {
    const data = await getWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/rewards/events${game_id ? `?game_id=${game_id}` : ''}`,
    )
    return data
  } catch (error: any) {
    toast.error(error?.message)
  }
}

export async function getEventKeys(
  getWithAccessToken: any,
  game_id: any,
  event_type: any,
) {
  try {
    const data = await getWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/rewards/events/keys?game_id=${game_id}&event_type=${event_type}`,
    )
    return data
  } catch (error: any) {
    toast.error(error?.message)
  }
}

export async function getSocialMediaData(getWithAccessToken: any) {
  const data = await getWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/social-media-connections/connections`,
  )
  return data
}

export async function getSupportedPlatforms(getWithAccessToken: any) {
  const data = await getWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/social-media-connections/supported-platforms`,
  )
  return data
}

export async function deleteSocialSync(
  deleteWithAccessToken: any,
  connectionId: number | undefined,
) {
  return await deleteWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/social-media-connections/connections/${connectionId}`,
  )
}

export async function getPyhlloSdkToken(getWithAccessToken: any) {
  const data = await getWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/social-media-connections/sdk-token`,
  )
  return data
}

export async function postToPhyllo(postWithAccessToken: any, params: any) {
  const data = await postWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/social-media-connections/connections`,
    params,
  )
  return data
}

export async function getSupportConfig(getWithAccessToken: any) {
  const params = {}
  const data = await getWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/organizations/support-config`,
    params,
  )
  return data
}

export async function updateSupportConfig(
  patchWithAccessToken: any,
  params: any,
) {
  const data = await patchWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/organizations/support-config`,
    params,
  )
  return data
}

export async function getCustomQueryRuns(getWithAccessToken: any, params: any) {
  const data = await getWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/audiences/custom-query-runs`,
    params,
  )
  return data
}

export async function getCustomQueryCategories(
  getWithAccessToken: any,
  params: any,
) {
  const data = await getWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/audiences/categories`,
    params,
  )
  return data
}

export async function getPreSignedS3Url(
  getWithAccessToken: any,
  customQueryRunId: string,
) {
  const data = await getWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/audiences/presigned-url/${customQueryRunId}`,
  )
  return data
}

export async function getEmailMarketingCampaigns(
  getWithAccessToken: any,
  paramString: any
) {
  const data = await getWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/email-marketing/campaigns/${paramString}`,
  )
  return data
}

export async function getEmailMarketingCampaign(
  getWithAccessToken: any,
  id: any
) {
  const data = await getWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/email-marketing/campaigns/${id}`,
  )
  return data
}

export async function getEmailMarketingAction(
  getWithAccessToken: any,
  id: any
) {
  const data = await getWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/email-marketing/actions/${id}`,
  )
  return data
}

export async function createEmailMarketingCampaign(postWithAccessToken: any, params: any) {
  const data = await postWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/email-marketing/campaigns/`,
    params,
  )
  return data
}


export async function duplicateAction(postWithAccessToken: any, id: any) {
  const data = await postWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/email-marketing/actions/${id}/duplicate`
  )
  return data
}

export async function editEmailMarketingCampaign(postWithAccessToken: any, params: any) {
  const data = await postWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/email-marketing/campaigns/${params.id}/edit`,
    params,
  )
  return data
}

export async function archiveEmailMarketingCampaign(
  deleteWithAccessToken: any,
  id: any,
) {
  return await deleteWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/email-marketing/campaigns/${id}/archive`,
  )
}

export async function unarchiveEmailMarketingCampaign(
  deleteWithAccessToken: any,
  id: any,
) {
  return await deleteWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/email-marketing/campaigns/${id}/unarchive`,
  )
}
export async function deleteEmailMarketingCampaign(
  deleteWithAccessToken: any,
  id: any,
) {
  return await deleteWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/email-marketing/campaigns/${id}`,
  )
}

export async function getAudiencesList(
  getWithAccessToken: any,
) {
  const data = await getWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/audiences/manual-queries`,
  )
  return data
}

export async function getEmailDomainList(
  getWithAccessToken: any,
  args: any
) {
  const data = await getWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/email-marketing/domains/`,
    args
  )
  return data
}

export async function getActionsList(
  getWithAccessToken: any,
  parent_campaign_id: any,
  paramString: any
) {
  const data = await getWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/email-marketing/actions/?parent_campaign_id=${parent_campaign_id}${paramString}`,
  )
  return data
}

export async function getEmailTemplates(
  getWithAccessToken: any,
) {
  const data = await getWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/email-marketing/templates/`,
  )
  return data
}

export async function getEmailTemplate(
  getWithAccessToken: any,
  id: any
) {
  const data = await getWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/email-marketing/templates/${id}`,
  )
  return data
}

export async function createEmailTemplate(postWithAccessToken: any, params: any) {
  const data = await postWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/email-marketing/templates/`,
    params,
  )
  return data
}

export async function updateEmailTemplate(postWithAccessToken: any, params: any) {
  const data = await postWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/email-marketing/templates/${params.id}/edit`,
    params,
  )
  return data
}

export async function deleteEmailTemplate(
  deleteWithAccessToken: any,
  templateId: any,
) {
  return await deleteWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/email-marketing/templates/${templateId}`,
  )
}


export async function createEmailMarketingAction(
  postWithAccessToken: any,
  args: any,
) {
  return await postWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/email-marketing/actions/`,
    args,
  )
}

export async function createNewEmailCampaign(
  postWithAccessToken: any,
  args: any,
) {
  if (args.title && args.description) {
    try {
      return await postWithAccessToken(
        `${process.env.REACT_APP_HELIKA_API_URL}/v1/email-marketing/campaigns/`,
        args,
      )
        .then((res: any) => {
          toast.success(`Success creating "${args.title}" email campaign.`)
          return res
        })
        .catch((error: any) => {
          toast.error(error?.message)
        })
    } catch (error: any) {
      toast.error(error?.message)
    }
  } else {
    toast.warning(`Incomplete campaign information.`)
  }
}

export async function updateAction(postWithAccessToken: any, params: any) {
  const data = await postWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/email-marketing/actions/${params.id}/edit`,
    params,
  )
  return data
}

export async function deleteEmailMarketingAction(
  deleteWithAccessToken: any,
  actionId: any,
) {
  return await deleteWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/email-marketing/actions/${actionId}`,
  )
}
export async function postProjectBuilder(
  postWithAccessToken: any,
  params: any,
) {
  const data = await postWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/engage/internal/projects`,
    params,
  )
  return data
}

export async function getProjectList(
  getWithAccessToken: any,
  page: number,
  pageSize: number,
) {
  const data = await getWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/engage/internal/projects?page=${page}&page_size=${pageSize}`,
  )
  return data
}

export async function getProjectDetails(
  getWithAccessToken: any,
  projectId: number | undefined,
) {
  const data = await getWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/engage/internal/projects/${projectId}`,
  )
  return data
}

export async function postHooks(
  getTokenIfNecessary: any,
  projectId: number,
  params: any[],
) {
  const accessToken = await getTokenIfNecessary()

  const resp = await fetch(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/engage/internal/projects/${projectId}/hooks`,
    {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json', // Specify JSON content type
      },
    },
  )
  const result = await resp.json()
  return result
}

export async function getEventsList(getWithAccessToken: any) {
  const data = await getWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/engage/internal/events`,
  )
  return data
}

export async function getProjectHooks(
  getWithAccessToken: any,
  projectId: number | undefined,
) {
  const data = await getWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/engage/internal/projects/${projectId}/hooks`,
  )
  return data
}

export async function updateProjectHooks(
  patchWithAccessToken: any,
  params: any,
  project_id: number,
) {
  const data = await patchWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/engage/internal/projects/${project_id}`,
    params,
  )
  return data
}

export async function getBucketStates(
  getWithAccessToken: any,
  projectId: number | undefined,
) {
  const data = await getWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/engage/internal/projects/${projectId}/buckets`,
  )
  return data
}

export async function getMailgunSubAccounts(getWithAccessToken: any) {
  try {
    const data = await getWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/email-marketing/subaccounts/`
    )
    return data
  } catch (error: any) {
    toast.error(error?.message)
  }
}

export async function getEmailMarketingDomains(getWithAccessToken: any, subaccount_id: any) {
  try {
    const data = await getWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/email-marketing/subaccounts/${subaccount_id}/domains`
    )
    return data
  } catch (error: any) {
    toast.error(error?.message)
  }
}

export async function getMOTDCampaign(getWithAccessToken: any, id: any) {
  try {
    const data = await getWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/motd/campaigns/${id}/`
    )
    return data
  } catch (error: any) {
    toast.error(error?.message)
  }
}

export async function getCampaignMOTDMessages(getWithAccessToken: any, campaign_slug: any) {
  try {
    const data = await getWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/motd/messages/${campaign_slug}/`
    )
    return data
  } catch (error: any) {
    toast.error(error?.message)
  }
}

export async function getMOTDCampaigns(getWithAccessToken: any, orgId: any) {
  try {
    const data = await getWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/motd/campaigns/`
    )
    return data
  } catch (error: any) {
    toast.error(error?.message)
  }
}

export async function getOffers(getWithAccessToken: any, orgId: any) {
  try {
    const data = await getWithAccessToken(
      `${process.env.REACT_APP_HELIKA_API_URL}/v1/offers/${orgId}`
    )
    return data
  } catch (error: any) {
    toast.error(error?.message)
  }
}


export async function createMOTDCampaign(postWithAccessToken: any, params: any) {

  return await postWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/motd/campaigns/`,
    params,
  )

}

export async function createNewOffer(getTokenIfNecessary: any, params: any) {

  const accessToken = await getTokenIfNecessary()

  let options: any = {
    headers: {
      authorization: `Bearer ${accessToken}`,
      'Content-type': 'multipart/form-data',
      async: true,
    },
  }
  let headers = {
    Authorization: `Bearer ${accessToken}`,
    'Content-type': 'multipart/form-data',
    async: true,
  }

  options.body = JSON.stringify(params)
  let data = new FormData()
  data.append('title', params.offer.title)
  data.append('body', params.offer.body)
  data.append('cta_url', params.offer.cta_url)
  // data.append('start', params.offer.start)
  // data.append('end', params.offer.end)
  data.append('active', params.offer.active)
  data.append('tags', params.offer.tags)
  if (params.file) {
    data.append('file', params.file)
  }

  let resp = await fetch(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/offers/`,
    {
      method: 'POST',
      ...headers,
      body: data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  ).then(function (response) {
    // The response is a Response instance.
    // You parse the data into a useable format using `.json()`
    return response.json();
  }).then(function (data) {
    if (data.status !== 'ok') {
      throw new Error(`Status ${data.status}: ${data?.statusText}`)
    }
    return data
  }).catch((e) => {
    return null
  });

  return resp

}

export async function editMOTDCampaign(patchWithAccessToken: any, params: any) {

  return await patchWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/motd/campaigns/${params.id}`,
    params,
  )
}

export async function editOffer(getTokenIfNecessary: any, params: any) {
  const accessToken = await getTokenIfNecessary()

  let options: any = {
    headers: {
      authorization: `Bearer ${accessToken}`,
      'Content-type': 'multipart/form-data',
      async: true,
    },
  }
  let headers = {
    Authorization: `Bearer ${accessToken}`,
    'Content-type': 'multipart/form-data',
    async: true,
  }

  options.body = JSON.stringify(params)
  let data = new FormData()
  data.append('id', params.offer.id)
  data.append('title', params.offer.title)
  data.append('body', params.offer.body)
  data.append('cta_url', params.offer.cta_url)
  // data.append('start', params.offer.start)
  // data.append('end', params.offer.end)
  data.append('active', params.offer.active)
  data.append('tags', params.offer.tags)
  if (params.offer.image) {
    data.append('image', params.offer.image)
  }
  if (params.file) {
    data.append('file', params.file)
  }

  let resp = await fetch(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/offers/`,
    {
      method: 'PATCH',
      ...headers,
      body: data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  ).then(function (response) {
    // The response is a Response instance.
    // You parse the data into a useable format using `.json()`
    return response.json();
  }).then(function (data) {
    if (data.status !== 'ok') {
      throw new Error(`Status ${data.status}: ${data?.statusText}`)
    }
    return data
  }).catch((e) => {
    return null
  });

  return resp
}

export async function getCampaignsList(
  getWithAccessToken: any,
  page: number,
  pageSize: number,
  sortBy: string,
  direction: string,
  name?: string,
  filters: any[] = [],
) {
  const filterQuery = encodeURIComponent(JSON.stringify(filters))

  const nameParam = name ? `&name=${encodeURIComponent(name)}` : ''

  const url = `${process.env.REACT_APP_HELIKA_API_URL}/v1/redemption-codes/campaigns?page_size=${pageSize}&page=${page}&sort_by=${sortBy}&sort_direction=${direction}&filter_by=${filterQuery}${nameParam}`

  const data = await getWithAccessToken(url)
  return data
}

export async function getCampaignDetails(
  getWithAccessToken: any,
  page: number,
  pageSize: number,
  sortBy: string,
  direction: string,
  campaignId: number,
  name?: string,
  filters: any[] = [],
) {
  const filterBy = encodeURIComponent(JSON.stringify(filters))

  const nameParam = name ? `&name=${encodeURIComponent(name)}` : ''

  const data = await getWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/redemption-codes/main?page_size=${pageSize}&page=${page}&sort_by=${sortBy}&sort_direction=${direction}&campaign_id=${campaignId}&filter_by=${filterBy}${nameParam}`, // Add the conditional name parameter
  )
  return data
}

export async function postNewCampaign(postWithAccessToken: any, params: any) {
  const data = await postWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/redemption-codes/campaigns`,
    params,
  )
  return data
}


export async function sendTestEmail(postWithAccessToken: any, params: any) {
  return await postWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/email-marketing/send-email`,
    params,
  )
}

export async function updateCampaign(
  putWithAccessToken: any,
  campaignId: number,
  params: any,
) {
  const data = await putWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/redemption-codes/campaigns/${campaignId}`,
    params,
  )
  return data
}

export async function updateBulkCampaigns(
  patchWithAccessToken: any,
  params: any,
) {
  const data = await patchWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/redemption-codes/campaigns`,
    params,
  )
  return data
}

export async function postNewCode(postWithAccessToken: any, params: any) {
  const data = await postWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/redemption-codes/main`,
    params,
  )
  return data
}

export async function updateCode(
  putWithAccessToken: any,
  codeId: number,
  params: any,
) {
  const data = await putWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/redemption-codes/main/${codeId}`,
    params,
  )
  return data
}

export async function updateBulkCodes(patchWithAccessToken: any, params: any) {
  const data = await patchWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/redemption-codes/main`,
    params,
  )
  return data
}

export async function postLlmRequest(postWithAccessToken: any, params: any) {
  const data = await postWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/llm/process_workflow/`,
    params,
  )
  return data
}

export async function getCampaignInfo(
  getWithAccessToken: any,
  campaignId: number | undefined,
) {
  const data = await getWithAccessToken(
    `${process.env.REACT_APP_HELIKA_API_URL}/v1/redemption-codes/campaigns/${campaignId}`,
  )
  return data
}