import React, { useCallback, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import _ from 'lodash';
import { perLetterDelay } from 'src/utils/number';
import loadingSVG from 'src/assets/loading.svg';


export function MessageSection({
  messageIndex,
  content,
  color,
  type,
  isSelf,
  scroll,
  delay = 0,
  size = 'small',
  isLoading = [],
  setIsLoading
}: {
  messageIndex: number,
  content: any,
  color?: any,
  type: any,
  isSelf: boolean,
  scroll: any,
  delay?: number,
  size?: string,
  isLast?: boolean,
  isLoading?: boolean[],
  setIsLoading?: any
}): React.ReactElement {

  const [displayContent, setDisplayContent] = useState<any>('')
  const [index, setIndex] = useState<number>(-1)
  const [showJsx, setShowJsx] = useState<boolean>(false);

  const updateLoading = useCallback(async () => {
    setIsLoading((prevState: boolean[]) => {
      if (prevState.length < (messageIndex + 1)) return prevState;
      let newState = [...prevState]
      newState[messageIndex] = false;
      if (messageIndex < (prevState.length - 1)) {
        newState[messageIndex + 1] = true;
      }
      return newState
    })
  }, [messageIndex, setIsLoading])


  useEffect(() => {
    setTimeout(() => {
      updateLoading()
      setShowJsx(true)
    }, delay)
  }, [delay, updateLoading])

  useEffect(() => {
    if (isSelf || type !== 'text') {
      updateLoading()
      setDisplayContent(content)
      return;
    }
    setTimeout(() => {
      updateLoading()
      /*Create a new setInterval and store its id*/
      const animKey = setInterval(() => {
        setIndex((index) => {
          /*This setState function will set the index
          to index+1 if there is more content otherwise
          it will destory this animation*/

          if (index >= content.length - 1) {
            clearInterval(animKey);
            return index;
          }
          return index + 1;
        });
      }, perLetterDelay);
    }, delay)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    setDisplayContent((prevState: any) => {
      if (
        !_.isString(content) ||
        !_.isString(prevState)
        || prevState.localeCompare(content) === 0
      ) return content;
      if (index === -1) {
        return prevState;
      }
      return prevState + content[index]
    })
    if (scroll) scroll();
  }, [index, content, scroll])


  return (
    <Box
    >
      {
        type === 'space' &&
        <div
          style={{
            height: '1em',
            width: '1em'
          }}
        >
        </div>
      }
      {type === 'text' && (
        <Typography variant="body1" style={{ color: color ? color : undefined, whiteSpace: 'pre-wrap', maxWidth: '100%', fontSize: size === 'large' ? '2em' : '1em' }}>
          {displayContent}
        </Typography>
      )}
      {
        showJsx && ['jsx', 'chart', 'widget', 'button'].includes(type) && <div style={{ overflow: 'auto' }}>{content}</div>
      }
      {
        (messageIndex && (isLoading.length > messageIndex - 1) && isLoading[messageIndex])
          ? <img style={{ height: '1em', width: '1em' }} className='animate-spin' src={loadingSVG} alt='' />
          : null
      }
    </Box>
  );
}