import { lineOptions, simpleMovingAverage } from "src/utils/echarts";
import { EChart } from "../atoms/v2/eChart";
import { Widget } from "./StatWidget";
import { Grid } from "@mui/material";
import CircularPageHeader from "../atoms/v2/circularPageHeader";
import Experiment from 'src/assets/experiment.svg'
import { useGetARPU } from "src/utils/ai/useGetARPU";
import { useContext, useEffect, useState } from "react";
import { Auth0Context } from "src/contexts/Auth0Context";
import { usePollData } from "src/utils/ai/usePollData";
import { arrayRange } from "src/utils/string";
import _ from "lodash";
import LoadingComponent from "../LoadingComponent";
import { getGameConfig } from "src/utils/api/queries";
import moment from "moment";

export function Dashboard(props: any) {

  const { getTokenIfNecessary, isAuthenticated, isLoading, getWithAccessToken } = useContext(Auth0Context)
  const [token, setToken] = useState<any>(undefined);
  const [shrimpArpuMA, setShrimpArpuMA] = useState<any>(0);
  const [arpdauChartOptions, setArpdauChartOptions] = useState<any>({});
  const [adImpressionsPerMinAvgOptions, setAdImpressionsPerMinAvgOptions] = useState<any>({});
  const [minutesPassed, setMinutesPassed] = useState<number>(0);
  const [initialConfig, setInitialConfig] = useState<any>();

  const { recentArpu, loadingRecentArpu, errorRecentArpu } = useGetARPU(token, process.env.REACT_APP_EVENTS_API_KEY);
  const configDataSegment0 = usePollData(`v1/game/arpu/config/t0?segment_id=0&limit1=15&limit2=30`, token, `${process.env.REACT_APP_EVENTS_API_KEY}`, 3);
  const configDataSegment1 = usePollData(`v1/game/arpu/config/t0?segment_id=1&limit1=15&limit2=30`, token, `${process.env.REACT_APP_EVENTS_API_KEY}`, 3);
  const configDataSegment2 = usePollData(`v1/game/arpu/config/t0?segment_id=2&limit1=15&limit2=30`, token, `${process.env.REACT_APP_EVENTS_API_KEY}`, 3);

  //get initial game config
  useEffect(() => {
    async function getLatestConfig() {
      if (!getWithAccessToken) return
      let resp = await getGameConfig(getWithAccessToken, {
        helika_api_key: process.env.REACT_APP_EVENTS_API_KEY,
        segment_ids: ["0"]
      })
      if (!resp || !resp.res || _.isEmpty(resp.res)) return;
      setInitialConfig(resp.res[0])
    }

    getLatestConfig();
  }, [getWithAccessToken])

  //get token
  useEffect(() => {
    async function getToken() {
      let newToken = await getTokenIfNecessary();
      setToken(newToken)
    }
    if (!isAuthenticated || isLoading) return
    getToken()
  }, [getTokenIfNecessary, isAuthenticated, isLoading])

  //cycle minutes
  useEffect(() => {
    setInterval(() => {
      setMinutesPassed((prevState: any) => {
        return prevState + 1
      })
    }, 3000)
  }, [])

  //echart options
  useEffect(() => {
    if (
      configDataSegment0.loading ||
      configDataSegment0.error ||
      !configDataSegment0?.data?.results?.data ||
      (configDataSegment0?.data?.results?.data?.length < 2) ||

      configDataSegment1.loading ||
      configDataSegment1.error ||
      !configDataSegment1?.data?.results?.data ||
      (configDataSegment1?.data?.results?.data?.length < 2) ||

      configDataSegment2.loading ||
      configDataSegment2.error ||
      !configDataSegment2?.data?.results?.data ||
      (configDataSegment2?.data?.results?.data?.length < 2)

      || !initialConfig
    ) return;

    //calculate elapsed time
    let created_at = new Date(moment(initialConfig.created_at)?.format('YYYY-MM-DD hh:mm'));
    let currentDate = new Date(moment()?.utc()?.format('YYYY-MM-DD hh:mm'));
    let minutesElapsed = (Math.abs(created_at.getTime() - currentDate.getTime()) / 60000) - 1

    //echart options for ARPU Chart - Shrimp (Pre vs Post Config Change)
    setArpdauChartOptions({
      ...lineOptions,
      title: {
        text: 'ARPU Chart - Shrimp (Pre vs Post Config Change)',
        textStyle: {
          color: 'white'
        }
      },
      legend: {
        data: ['Shrimp Segment', 'MA - Shrimp'],
        y: 'bottom',
        textStyle: {
          color: 'white'
        },
        icon: 'circle',
      },
      xAxis: {
        type: 'category',
        boundaryGap: true,
        data: arrayRange(0, 15 + (minutesElapsed >= 15 ? 15 : minutesElapsed), 1).map((item: number) => {
          if (item < 15) {
            return Math.abs(15 - item);
          } else if (item === 15) {
            return 0
          } else {
            return item - 15
          }
        })
      },
      yAxis: [
        {
          type: 'value',
          name: 'ARPU',
          position: 'left',
          alignTicks: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: 'white'
            }
          },
          axisLabel: {
            formatter: '{value}'
          }
        }
      ],
      series: [
        {
          name: 'Shrimp Segment',
          type: 'line',
          yAxisIndex: 0,
          data:
            (
              configDataSegment0?.data?.results?.data[0].time_based.map((item: any) => item.arpu)
            )
              .concat(
                configDataSegment0?.data?.results?.data[1].time_based.map((item: any) => item.arpu).slice(0, minutesElapsed + 1)
              ),
          markLine: {
            name: 'CONFIG CHANGE',
            symbol: ['circle', 'circle'],
            lineStyle: {
              color: '#FFA500',
              opacity: 1,
              width: 3
            },
            data: [
              {
                xAxis: 15,
                label: {
                  show: false
                },
              }
            ]
          }
        },
        {
          name: 'MA - Shrimp',
          type: 'line',
          yAxisIndex: 0,
          data: simpleMovingAverage((
            configDataSegment0?.data?.results?.data[0].time_based.map((item: any) => item.arpu)
          )
            .concat(
              configDataSegment0?.data?.results?.data[1].time_based.map((item: any) => item.arpu).slice(0, minutesElapsed + 1)
            ), 5)
        },
      ],
      toolbox: {
        feature: {
          saveAsImage: {}
        }
      },
      tooltip: {
        trigger: 'axis'
      },
    })


    //echart options for Total Ad Impressions
    setAdImpressionsPerMinAvgOptions({
      title: {
        text: 'Total Ad Impressions / min - Shrimp (Pre vs Post Config Change)',
        textStyle: {
          color: 'white'
        }
      },
      ...lineOptions,
      legend: {
        data: ['Shrimp Segment', 'MA - Shrimp'],
        y: 'bottom',
        textStyle: {
          color: 'white'
        },
        icon: 'circle',
      },
      xAxis: [
        {
          type: 'category',
          data: arrayRange(0, 15 + (minutesElapsed >= 15 ? 15 : minutesElapsed), 1).map((item: number) => {
            if (item < 15) {
              return Math.abs(15 - item);
            } else if (item === 15) {
              return 0
            } else {
              return item - 15
            }
          }),
          axisPointer: {
            type: 'shadow'
          },
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: 'Ad Impressions',
          min: 0,
          // max: _.max(configDataSegment0?.data?.results.data[0].time_based?.map((value: any) => value.total_ad_impressions))
        }
      ]
      ,
      series: [
        {
          name: 'Shrimp Segment',
          type: 'line',
          yAxisIndex: 0,
          data:
            (
              configDataSegment0?.data?.results?.data[0].time_based.map((item: any) => item.total_ad_impressions)
            )
              .concat(
                configDataSegment0?.data?.results?.data[1].time_based.map((item: any) => item.total_ad_impressions).slice(0, minutesElapsed + 1)
              ),
          markLine: {
            name: 'CONFIG CHANGE',
            symbol: ['circle', 'circle'],
            lineStyle: {
              color: '#FFA500',
              opacity: 1,
              width: 3
            },
            data: [
              {
                xAxis: 15,
                label: {
                  show: false
                },
              }
            ]
          }
        },
        {
          name: 'MA - Shrimp',
          type: 'line',
          yAxisIndex: 0,
          data: simpleMovingAverage((
            configDataSegment0?.data?.results?.data[0].time_based.map((item: any) => item.total_ad_impressions)
          )
            .concat(
              configDataSegment0?.data?.results?.data[1].time_based.map((item: any) => item.total_ad_impressions).slice(0, minutesElapsed + 1)
            ), 5)
        },
      ],
      toolbox: {
        feature: {
          saveAsImage: {}
        }
      },
      tooltip: {
        trigger: 'axis'
      },
    })

    let newShrimpMa = simpleMovingAverage((
      configDataSegment0?.data?.results?.data[0].time_based.map((item: any) => item.arpu)
    )
      .concat(
        configDataSegment0?.data?.results?.data[1].time_based.map((item: any) => item.arpu).slice(0, minutesElapsed + 1)
      ), 5)?.slice(-1)
    setShrimpArpuMA(newShrimpMa[0])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    configDataSegment0.data, configDataSegment0.loading, configDataSegment0.error,
    configDataSegment1.data, configDataSegment1.loading, configDataSegment1.error,
    configDataSegment2.data, configDataSegment2.loading, configDataSegment2.error,
    minutesPassed, initialConfig
  ])



  // //get MA for shrimp APRU
  // useEffect(() => {
  //   if (!recentArpu || !recentArpu.results || !recentArpu.results.segments || !('0' in recentArpu.results.segments)) return;
  //   let maTimeBasedEntries = Object.entries(recentArpu.results.segments['0'].time_based)
  //   let newValuesMA = maTimeBasedEntries.map((entry: any) => entry[1]?.arpu)
  //   setShrimpArpuMA(_.mean(newValuesMA))
  // }, [recentArpu])

  if (isLoading) {
    return <LoadingComponent />
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        minHeight: 'fit-content',
        padding: '1em auto 1em auto',
        maxHeight: '100%',
        maxWidth: '100%',
        overflow: 'auto',
      }}
    >
      <div
        style={{
          margin: 'auto',
          maxWidth: '50vw',
          minWidth: '50em',
          height: 'fit-content',
          maxHeight: '100%',
        }}
      >

        <CircularPageHeader
          text="EXPERIMENT DASHBOARD"
          icon={Experiment}
          sx={{ padding: 0, paddingLeft: '1em', margin: '1em 1em 0 1em' }}
        />

        <Grid container style={{ width: '100%', margin: '0 auto 0 auto', height: 'fit-content', padding: '1em' }}>

          <Grid item xs={6} style={{ paddingRight: '0.5em', paddingBottom: '0.5em' }}>
            <Widget
              value={recentArpu?.results?.segments['0']?.total?.total_users}
              small={true}
              loading={loadingRecentArpu}
              header={'Total Shrimp'}
              stacked={true}
              error={errorRecentArpu}
            />
          </Grid>

          <Grid item xs={6} style={{ paddingLeft: '0.5em', paddingBottom: '0.5em' }}>
            <Widget
              value={
                configDataSegment0?.data?.results?.data[1].total.total_users
                  ? configDataSegment0?.data?.results?.data[1].total.total_users
                  : '0'
              }
              small={true}
              loading={configDataSegment0.loading}
              header={'Total Shrimp With Changes'}
              stacked={true}
              error={configDataSegment0.error}
            />
          </Grid>

          <Grid item xs={4} style={{ paddingRight: '0.5em', paddingTop: '0.5em' }}>
            <Widget
              value={recentArpu?.results?.segments['0']?.total?.arpu?.toFixed(4)}
              small={true}
              loading={loadingRecentArpu}
              header={'ARPU Before Changes'}
              stacked={true}
              error={errorRecentArpu}
            />
          </Grid>

          <Grid item xs={4} style={{ paddingRight: '0.5em', paddingLeft: '0.5em', paddingTop: '0.5em' }}>
            <Widget
              value={shrimpArpuMA?.toFixed(4)}
              small={true}
              loading={configDataSegment0.loading}
              header={'ARPU After Changes'}
              error={configDataSegment0.error}
            />
          </Grid>

          <Grid item xs={4} style={{ paddingLeft: '0.5em', paddingTop: '0.5em' }}>
            <Widget
              value={
                (shrimpArpuMA - (recentArpu?.results?.segments['0']?.total?.arpu))?.toFixed(4)?.toString()
              }
              small={true}
              loading={loadingRecentArpu || configDataSegment0.loading}
              header={'Difference'}
              error={errorRecentArpu || configDataSegment0.error}
            />
          </Grid>

        </Grid>

        {
          //arpu chart pre / post
        }
        <div
          style={{
            maxHeight: '50vh',
            minHeight: '20em',
            marginBottom: '1em'
          }}
        >
          <EChart
            loading={configDataSegment0.loading}
            options={arpdauChartOptions}
            error={configDataSegment0.error}
          />
        </div>

      </div>
    </div>
  );
};