import React, { FC } from 'react'
import { Stack } from '@mui/material'
// project import
import LoadingComponent from 'src/components/LoadingComponent'

const LoadingOverlay: FC<{ loading: boolean }> = ({ loading }) => {
  return loading ? (
    <Stack
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 10000,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <LoadingComponent />
    </Stack>
  ) : (
    <></>
  )
}

export default LoadingOverlay
