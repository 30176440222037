import { Grid } from '@mui/material';
import { EChartsOption } from 'echarts';
import ReactEcharts from 'echarts-for-react';

import styles from './index.module.scss';

import { ErrorAlert } from 'src/components/atoms/v2/ErrorAlert';
import { idleOption, loadingOption } from 'src/utils/echarts';

type IEChartProps = { options?: EChartsOption; loading: boolean; error?: Error };

export const EChart = ({ options, loading, error }: IEChartProps) => {
  return (
    <Grid
      id='echart-grid-content'
      item
      sx={{
        height: '100%',
        minHeight: 'fit-content',
        width: '100%',
        padding: '1em',
      }}
    >
      <ErrorAlert error={error} />
      <ReactEcharts
        className={styles.echartBorder}
        showLoading={!options}
        loadingOption={loading ? loadingOption : idleOption}
        option={!!options ? options : idleOption}
        style={{
          display: 'flex',
          flex: 1,
          minHeight: '20em',
          height: '100%',
          maxWidth: '100%',
          margin: 'auto'
        }}
        notMerge={true}
      />
    </Grid>
  );
};
