import React from 'react'
import {
  Stack,
  Typography,
  Snackbar,
  Slide,
  Button,
  useTheme,
} from '@mui/material'
import { styled } from '@mui/system'
import InboxIcon from '@mui/icons-material/Inbox'
import { newColor } from 'src/consts/colors'

// Common text styles to ensure consistency across the component with dynamic theme
const commonTextStyles = (themeMode: 'dark' | 'light') => ({
  fontFamily: 'Open Sans, sans-serif',
  fontSize: '14px',
  color: themeMode === 'dark' ? newColor.white : newColor.black,
})

// Wrapper for the Floating Action Bar component with dynamic background and border color
const FloatingActionBarWrapper = styled('div')<{ themeMode: 'dark' | 'light' }>(
  ({ themeMode }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 20px',
    position: 'fixed',
    bottom: '20px',
    width: '850px',
    height: '68px',
    backgroundColor:
      themeMode === 'dark' ? newColor.charcoal : newColor.whiteSmoke,
    border: `1px solid ${
      themeMode === 'dark' ? newColor.darkGunmetal : newColor.lightGray
    }`,
    boxShadow: '0px 20px 20px rgba(0, 0, 0, 0.3)',
    borderRadius: '8px',
    zIndex: 1000,
  }),
)

// Typography for displaying the number of selected items with dynamic color
const SelectedText = styled(Typography)<{ themeMode: 'dark' | 'light' }>(
  ({ themeMode }) => ({
    fontSize: '16px',
    lineHeight: '150%',
    color: themeMode === 'dark' ? newColor.white : newColor.black,
    flexGrow: 1,
    textAlign: 'left',
    fontFamily: 'Open Sans, sans-serif',
  }),
)

// Container for the action buttons
const ActionsWrapper = styled(Stack)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '12px',
})

// Styled button for the "Archive" action with dynamic background and border
const ArchiveButton = styled(Button)<{ themeMode: 'dark' | 'light' }>(
  ({ themeMode }) => ({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    color: themeMode === 'dark' ? newColor.white : newColor.black,
    border: `1px solid ${
      themeMode === 'dark' ? newColor.gunMetal : newColor.lightGray
    }`,
    borderRadius: '4px',
    padding: '8px 16px',
    fontSize: '14px',
    fontFamily: 'Open Sans, sans-serif',
    '&:hover': {
      backgroundColor:
        themeMode === 'dark' ? newColor.dimGray : newColor.gainsboro,
    },
  }),
)

// New button for "Update usage limit" with dynamic background color
const UpdateButton = styled(Button)<{ themeMode: 'dark' | 'light' }>(
  ({ themeMode }) => ({
    backgroundColor: themeMode === 'dark' ? newColor.jazzberryJam : '#EA335F',
    color: '#FFFFFF',
    borderRadius: '4px',
    padding: '8px 16px',
    fontSize: '14px',
    fontFamily: 'Open Sans, sans-serif',
    '&:hover': {
      backgroundColor: themeMode === 'dark' ? '#E91E63' : '#D81B60',
    },
  }),
)

// Floating Action Bar component with bulk actions
const FloatingActionBarComponent: React.FC<{
  selectedCodesLength: number
  handleBulkArchive: () => void
  handleUnselectAll: () => void
  open: boolean
  isLoading: boolean
  showUpdateButton?: boolean
  handleUpdateUsageLimit?: () => void
}> = ({
  selectedCodesLength,
  handleBulkArchive,
  handleUnselectAll,
  open,
  isLoading,
  showUpdateButton,
  handleUpdateUsageLimit,
}) => {
  const theme = useTheme()
  const themeMode = theme.palette.mode

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      TransitionComponent={(props) => <Slide {...props} direction="up" />}
    >
      <FloatingActionBarWrapper themeMode={themeMode}>
        {/* Display selected items count */}
        <SelectedText themeMode={themeMode}>
          {`${selectedCodesLength} items selected`}
        </SelectedText>
        <ActionsWrapper direction="row" gap={2}>
          {/* Unselect all action */}
          <Typography
            onClick={handleUnselectAll}
            sx={{
              cursor: 'pointer',
              ...commonTextStyles(themeMode),
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            Unselect all
          </Typography>
          {/* Archive button with loading state */}
          <ArchiveButton
            themeMode={themeMode}
            onClick={handleBulkArchive}
            disabled={isLoading}
          >
            <InboxIcon sx={{ marginRight: '8px' }} />
            {isLoading ? 'Archiving...' : 'Archive'}
          </ArchiveButton>
          {/* Show "Update usage limit" button conditionally */}
          {showUpdateButton && (
            <UpdateButton
              themeMode={themeMode}
              onClick={handleUpdateUsageLimit}
            >
              Update usage limit
            </UpdateButton>
          )}
        </ActionsWrapper>
      </FloatingActionBarWrapper>
    </Snackbar>
  )
}

export default FloatingActionBarComponent
