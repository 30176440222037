import useSWR from 'swr';

import { get } from 'src/utils/api/frontend';

export const useGetARPU = (accessToken: any, apiKey: any) => {

  const { data, error, isLoading } = useSWR<any>(
    {
      url: `${process.env.REACT_APP_HELIKA_API_URL}/v1/game/arpu/recent`,
      options: {
        headers: {
          authorization: `Bearer ${accessToken}`,
          'x-api-key': apiKey
        }
      },
    },
    ({ url, options }) => get(url, options),
    {
      refreshInterval: 3000
    }
  );

  if (!accessToken) return {
    recentArpu: [],
    loadingRecentArpu: false,
    errorRecentArpu: 'No access to retrieve Recent ARPU'
  }

  return data
    ? {
      loadingRecentArpu: isLoading,
      errorRecentArpu: error,
      recentArpu: data
    }
    : {
      loadingRecentArpu: isLoading,
      errorRecentArpu: error,
    };
};
