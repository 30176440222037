import React from 'react'
import { Stack, Typography, Button, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import AddIcon from '@mui/icons-material/Add'
import { newColor } from 'src/consts/colors'
import InboxEmptyIcon from 'src/assets/inbox_empty.svg'

// Styled Button for the "Create New" button with synced styling for the label
const StyledButton = styled(Button)(({ theme }) => {
  const themeMode = theme.palette.mode
  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px 16px 8px 8px',
    gap: '8px',
    width: '173px',
    height: '36px',
    backgroundColor:
      themeMode === 'dark' ? newColor.jazzberryJam : newColor.error,
    borderRadius: '4px',
    color: newColor.white,
    fontSize: '14px',
    lineHeight: '140%',
    textTransform: 'none',
    whiteSpace: 'nowrap',
    fontFamily: 'Open Sans, sans-serif',
    '&:hover': {
      backgroundColor:
        themeMode === 'dark' ? newColor.jazzberryJam : newColor.error,
    },
    '& .MuiButton-startIcon': {
      margin: 0,
    },
    '& .MuiTypography-root': {
      fontFamily: 'Open Sans',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '19.6px',
      textAlign: 'center',
      color: newColor.white,
    },
  }
})

// Updated NoDataAvailable component
interface NoDataAvailableProps {
  title: string
  description: string
  buttonText: string
  onCreateNew: () => void
}

const NoDataAvailable: React.FC<NoDataAvailableProps> = ({
  title,
  description,
  buttonText,
  onCreateNew,
}) => {
  const theme = useTheme()
  const themeMode = theme.palette.mode

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        width: '90vw',
        maxWidth: '1170px',
        height: '60vh',
        maxHeight: '475px',
        padding: '32px',
        gap: '24px',
        textAlign: 'center',
        backgroundColor: 'transparent',
        margin: '0 auto',
        marginTop: '5vh',
      }}
    >
      <img
        src={InboxEmptyIcon}
        alt="No Data Illustration"
        style={{ width: '100px', height: '100px', marginBottom: '16px' }}
      />
      <Typography
        variant="h6"
        sx={{
          fontWeight: 'bold',
          marginBottom: '8px',
          color: themeMode === 'dark' ? newColor.white : newColor.black,
          fontFamily: 'Open Sans, sans-serif',
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          marginBottom: '24px',
          color: themeMode === 'dark' ? newColor.white : newColor.black,
          fontFamily: 'Open Sans, sans-serif',
        }}
      >
        {description}
      </Typography>
      <StyledButton onClick={onCreateNew}>
        <AddIcon />
        <Typography>{buttonText}</Typography>
      </StyledButton>
    </Stack>
  )
}

export default NoDataAvailable
