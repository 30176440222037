import LeaderboardIcon from "src/assets/leaderboard_icon.svg";
import LeaderboardUserIcon from "src/assets/leaderboard_user_icon.svg";
import LeaderboardFirst from "src/assets/leaderboard_first.svg";
import LeaderboardSecond from "src/assets/leaderboard_second.svg";
import { Grid, useTheme } from "@mui/material";
import _ from "lodash";
import { HELIKA_LOGO_URL } from "src/utils/string";
import { useGetLeaderboard } from "src/utils/ai/useGetLeaderboard";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Auth0Context } from "src/contexts/Auth0Context";

export default function Leaderboard() {

  const theme: any = useTheme();

  const { getTokenIfNecessary, isAuthenticated } = useContext(Auth0Context)
  const [token, setToken] = useState<any>(undefined);

  const { leaderboards, loadingLeaderboards, errorLeaderboards } = useGetLeaderboard(token, process.env.REACT_APP_EVENTS_API_KEY);

  const [leaderboardTable, setLeaderboardTable] = useState<any[]>([])
  const [maximum, setMaxiumum] = useState<number>(0)
  const [minimum, setMinimum] = useState<number>(0)

  useEffect(() => {
    async function getToken() {
      let newToken = await getTokenIfNecessary();
      setToken(newToken)
    }
    if (!isAuthenticated) return
    getToken()
  }, [getTokenIfNecessary, isAuthenticated])

  useEffect(() => {
    if (loadingLeaderboards) return
    if (!isAuthenticated || !token) {
      return
    }
    if (errorLeaderboards) {
      toast.error('Error retrieving data')
      return
    }
    if (_.isEmpty(leaderboards?.results)) return
    leaderboards?.results.forEach((leader: any) => {
      setMaxiumum(prevState => leader.highest_score > prevState ? leader.highest_score : prevState)
      setMinimum(prevState => (prevState > leader.highest_score || prevState === 0) ? leader.highest_score : prevState)
    })
    setLeaderboardTable(leaderboards?.results)
  }, [errorLeaderboards, isAuthenticated, leaderboards?.results, loadingLeaderboards, token])


  function getLeaderboardIcon(place: number) {
    switch (place) {
      case 1:
        return LeaderboardFirst;
      case 2:
        return LeaderboardSecond;
      case 3:
        return LeaderboardSecond;
      default:
        return undefined;
    }
  }

  function displayLeaderboard() {
    return (
      leaderboardTable?.map((leader: any, index: number) => {
        let place = index + 1;
        let img = HELIKA_LOGO_URL

        return (
          <Grid container justifyContent="space-between"
            key={place}
            style={{
              color: theme.palette.text.primary,
              background:
                place === 1
                  ? 'linear-gradient(90deg, #612EA6 60%, #EA335F 100%)'
                  : place === 2
                    ? 'linear-gradient(90deg, #8BE1C9 60%, #9747FF 100%)'
                    : 'linear-gradient(90deg, #4D786C 60%, #532B87 100%)',
              width: '100%',
              minHeight: '3em',
              height: 'fit-content',
              textAlign: 'center'
            }}
          >
            <Grid item xs={1} style={{ display: 'flex', flexDirection: 'row', height: '100%', margin: 'auto' }}>
              <img src={getLeaderboardIcon(place)} alt='' style={{ margin: 'auto 0 auto auto', width: '1.3em' }} />
            </Grid>
            <Grid item xs={1} style={{ fontSize: '1.5em', margin: 'auto' }}>
              {place}
            </Grid>
            <Grid item xs={3} style={{ margin: 'auto 0 auto 0', paddingRight: '0.3em', display: 'flex', flexDirection: 'row' }}>
              <div style={{ marginRight: '0.3em', borderRadius: '100vmax', height: '2em', width: '2em' }}>
                <img src={img} alt='' style={{ borderRadius: '100vmax', aspectRatio: '1/1', border: 0, borderStyle: 'solid', borderColor: theme.palette.palette.text.primary }} />
              </div>
              <div
                style={{
                  margin: 'auto 0 auto 0',
                  padding: '0 0 0 0.5em',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}>
                {leader.name}
              </div>
            </Grid>
            <Grid item xs={4} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', width: '100%', borderRadius: '100vmax', margin: 'auto 0 auto 0', height: '0.5em', backgroundColor: 'white' }}>
              <div
                style={{
                  width: `${(((Number(leader.highest_score) - minimum + 1) / (maximum - minimum + 2)) * 100)}%`,
                  background:
                    place === 1
                      ? 'linear-gradient(90deg, #4D786C 60%, #532B87 100%)'
                      : place === 2
                        ? 'linear-gradient(90deg, #612EA6 60%, #EA335F 100%)'
                        : 'linear-gradient(90deg, #8BE1C9 60%, #9747FF 100%)',
                  borderRadius: '100vmax'
                }}
              />
            </Grid>
            <Grid item xs={3}
              style={{
                textAlign: 'center',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                margin: 'auto'
              }}>
              {leader.highest_score}
            </Grid>
          </Grid>
        );
      })
    )
  }

  function displayEmptyLeaderboard() {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          padding: '0.3em 0 0.3em 0',
          justifyContent: 'center'
        }}
      >
        <div
          style={{
            margin: 'auto',
            width: '100%',
            textAlign: 'center',
            padding: '0.3em',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          Leaders Currently Unavailable
        </div>
      </div>
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        minWidth: '30em',
        padding: '1em'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          color: 'white',
          backgroundColor: 'linear-gradient(90deg, #9747FF 60%, #EA335F 100%)',
          borderRadius: '1em',
          textAlign: 'center',
          padding: '0.3em 0 0.3em 0',
          justifyContent: 'center',
        }}
      >
        <img src={LeaderboardIcon} alt='' style={{ margin: 'auto 1em auto 0' }} />
        LEADERBOARD
      </div>
      <Grid container justifyContent="space-between">
        <Grid item md={5} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <img src={LeaderboardUserIcon} alt='' style={{ margin: 'auto 1em auto 0' }} />
          USER
        </Grid>
        <Grid item md={7} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', textAlign: 'center' }}>
          SCORE
        </Grid>
      </Grid>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
          borderRadius: '1em',
          overflow: 'clip'
        }}
      >
        {
          (_.isEmpty(leaderboardTable) || !isAuthenticated)
            ? displayEmptyLeaderboard()
            : displayLeaderboard()
        }
      </div>
    </div>
  );
}